@use 'sass:math';

$image-text-picker-text-color: inherit !default;
$image-text-item-title-line-key: $secondary-heading-line-key !default;
$image-text-picker-swiper-arrows: -3.2rem;

.image-text-picker-spot {
    padding: 2rem 0;
    position: relative;

    @include desktop {
        padding: 3rem 0;
    }

    &--single__content {
        display: flex !important;
        align-items: center;
        max-width: 50rem;
        margin: 0 auto;

        @include touch {
            padding-top: 0.5rem !important;
        }

        .image-text-item {
            &__content {
                @include touch {
                    margin-top: 0;
                }
            }
        }
    }

    &--multi__item {
        @include desktop {
            .image-text-item {
                display: flex;

                > *:first-child {
                    flex: 0 0 50%;
                }
            }

            img {
                display: block;
            }
        }
    }

    &--with-slides {
        @include is-full;

        overflow: hidden;

        // when inside a standard page nav
        .standard-page-nav & {
            @include desktop {
                // we need to compensate for the nav's width
                transform: translateX(-7vw); // magic number
            }

            .container {
                @include desktop {
                    max-width: 580px;
                }

                @include widescreen {
                    &:not(.is-max-desktop) {
                        &:not(.is-max-widescreen) {
                            max-width: 890px;
                            transform: translateX(7vw);
                        }
                    }
                }
            }
        }
    }

    &-swiper {
        overflow: visible;

        .swiper-wrapper {
            .swiper-slide {
                background-color: transparent;
            }
        }

        &__arrows {
            position: absolute;
            right: 0;
            left: 0;

            &--center {
                top: 0;
                padding-bottom: calc(var(--ratio-16-9) / 1.1);

                @include desktop {
                    padding-bottom: calc(var(--ratio-16-9) / calc(var(--slides-per-view) + 0.15));
                }
            }

            &--bottom {
                bottom: 2rem;
            }

            @include desktop {
                // when 1 slide per view use absolute positioning
                // instead of padding-hack to center arrows
                &--single-slide {
                    inset: 0;
                    padding: 0;
                }
            }
        }

        &__prev,
        &__next {
            pointer-events: auto;
        }

        &__prev {
            left: $gutter-small;

            @include desktop {
                left: $image-text-picker-swiper-arrows;
            }
        }

        &__next {
            right: $gutter-small;

            @include desktop {
                right: $image-text-picker-swiper-arrows;
            }
        }
    }
}

.image-text-item {
    &__content {
        &--single-slide {
            @include desktop {
                max-width: 60rem;
                margin: 0 auto;
                padding-left: 5rem;
                padding-right: 5rem;
            }
        }
    }

    &-title {
        @include secondary-heading;
        line-height: map-get($line-heights, $image-text-item-title-line-key);

        &--three-four {
            @include tertiary-heading;
        }

        @include until($desktop) {
            @include secondary-heading-mobile;
        }
    }

    &-text {
        .rte-content {
            color: $image-text-picker-text-color;
        }
    }
}
