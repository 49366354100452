@import 'overlay/overlay';

@import 'bulma/sass/base/minireset';

/****** FONTS *******/
@import 'fonts/fonts';

/****** VARIABLES *******/
@import '_variables/color-variables';
@import '_variables/variables';

/****** UTIL *******/
@import 'util/utilities';
