.service-booking {
    &__headline {
        color: var(--color-black);
        font-size: var(--font-size-large);
        font-family: var(--font-family-base-medium);
        letter-spacing: var(--letter-spacing-x-large);
        font-weight: var(--font-weight-medium);
        line-height: var(--line-height-small);
        margin: 1.2rem 0;

        &:first-child {
            margin-top: 0;
        }
    }

    &__sub-headline {
        font-size: var(--font-size-base);
        font-family: var(--font-family-base-medium);
        color: var(--color-black);
        font-weight: var(--font-weight-base-medium);
        line-height: var(--line-height-small);
        margin: 1em 0;

        &:first-child {
            margin-top: 0;
        }
    }

    &__body-text {
        font-size: var(--font-size-base);
        font-family: var(--font-family-base);
        font-weight: var(--font-weight-base);
        color: var(--color-black);
        margin-bottom: 2rem;
        line-height: var(--line-height-base);
    }

    &__text {
        font-size: var(--font-size-small);
        font-family: var(--font-family-base);
        color: var(--color-grey-darker);
        letter-spacing: var(--letter-spacing-x-large);
        line-height: 1.5;
    }
}
