.dealer-page {
    &__info {
        padding-top: 1.5rem;

        @include desktop {
            padding-top: 2.5rem;
        }
    }

    &__cta {
        padding-top: 1.5rem;

        @include desktop {
            padding-top: 2.5rem;
        }
    }
}
