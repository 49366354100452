$sidepanel-offset: 0 !default; // Value should match value in slideIn animation
$sidepanel-min-width: 42.5rem + $sidepanel-offset !default;
$sidepanel-padding-vertical: 4.8rem !default;
$sidepanel-padding-horizontal: 4.5rem !default;
$sidepanel-mobile-padding-vertical: 4rem !default;
$sidepanel-mobile-padding-horizontal: 2.5rem !default;
$sidepanel-close-color-hover: $button-primary-hover-bg !default;
$sidepanel-mobile-header-padding: 2rem !default;
$sidepanel-header-padding: 4.5rem !default;
$sidepanel-icon-cross-size-small: 3.5rem;

.side-panel {
    position: relative;
    display: block;
    height: 100%;
    width: 100vw;
    background-color: $white;

    @include tablet {
        width: 600px;
        min-width: $sidepanel-min-width;
    }

    // Used to hide background on slide in animation
    &::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 9rem;
        background-color: $white;
        top: 0;
        right: -9rem;
        bottom: 0;
    }

    &__content {
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        padding: $sidepanel-mobile-padding-vertical $sidepanel-mobile-padding-horizontal;

        @include tablet {
            padding: $sidepanel-padding-vertical $sidepanel-padding-horizontal;
        }

        &-template {
            header {
                padding-top: $sidepanel-mobile-header-padding;

                @include desktop {
                    padding-top: $sidepanel-header-padding;
                }
            }

            &-image {
                margin: 2rem 0;
            }

            &-text {
                p {
                    margin-bottom: 1.5rem;
                }
            }

            &-grid {
                // override styles inherited from .section
                .grid {
                    &__theme {
                        &--default {
                            background: none;
                        }
                    }
                }

                .section {
                    @include touch {
                        margin: negative($section-padding-mobile);
                    }

                    @include desktop {
                        margin: negative($section-padding-desktop);
                    }
                }
            }
        }
    }

    &__btn-my-profile {
        margin: 1rem 0;

        span.icon {
            margin-left: 2rem !important; // And this is why adhering to BEM is not such a bad idea.
        }
    }

    &__close {
        position: absolute;
        z-index: 900;
        top: 0;
        transform: translate3d(-50%, 4.25rem, 0); // y offset should match animation in slide.animations

        &:hover {
            color: $sidepanel-close-color-hover !important;
        }

        @include mobile {
            .close-circle-icon > svg {
                width: $sidepanel-icon-cross-size-small;
                height: $sidepanel-icon-cross-size-small;
            }
        }

        @include from($tablet) {
            left: 0;
        }

        @include mobile {
            top: $gutter-small;
            right: $gutter-small;
            transform: translate3d(0, 0, 0);
        }
    }
}
