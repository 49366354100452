$mega-sidemenu-label-size-key: sub !default;
$mega-sidemenu-label-family-key: medium !default;
$mega-sidemenu-is-uppercase: false !default;
$mega-sidemenu-margin-top: -0.8rem !default;
$mega-sidemenu-link-padding-top: 0.2rem !default;
$mega-sidemenu-link-padding-bottom: 0.2rem !default;
$mega-sidemenu-link-icon-position: 0.6rem !default;
$mega-sidemenu-link-text-position: 0.1rem !default;
$mega-sidemenu-link-size-key: sub !default;
$mega-sidemenu-link-color-hover: darken($primary, 20%) !default;
$mega-sidemenu-link-icon-color: $primary !default;
$mega-sidemenu-icons-visible: true !default;

.mega-sidemenu {
    $parent: &;
    margin-top: $mega-sidemenu-margin-top;
    padding-bottom: 4rem;
    padding-left: 6.4rem;

    &__label {
        font-size: map-get($font-sizes, $mega-sidemenu-label-size-key);
        font-family: map-get($font-families, $mega-sidemenu-label-family-key);
        line-height: 1;
        letter-spacing: map-get($letter-spacings, medium);
        color: $black;
        @include when-inside('.mega-menu__wrapper.is-open') {
            visibility: visible; // Fixes a glitch with menu items not being shown
        }

        @if ($mega-sidemenu-icons-visible) {
            margin-bottom: 0.9rem !important;
        } @else {
            margin-bottom: 1.8rem !important;
        }

        @if ($mega-sidemenu-is-uppercase) {
            text-transform: uppercase;
        } @else {
            text-transform: none;
        }
    }

    &__item {
        @include when-inside('.mega-menu__wrapper.is-open') {
            visibility: visible; // Fixes a glitch with menu items not being shown
        }

        > a {
            display: flex;
            align-items: center;
            font-size: map-get($font-sizes, $mega-sidemenu-link-size-key);
            font-family: map-get($font-families, base);
            line-height: 1.2;
            text-decoration: none;
            letter-spacing: map-get($letter-spacings, medium);
            color: $mega-sidemenu-link-color;
            padding: $mega-sidemenu-link-padding-top 0 $mega-sidemenu-link-padding-bottom 0;

            .shortcut {
                &__icon {
                    @if ($mega-sidemenu-icons-visible) {
                        display: flex;
                        transition: transform $duration-medium $smooth-in-out;
                        position: relative;
                        right: $mega-sidemenu-link-icon-position;
                        color: $mega-sidemenu-link-icon-color;
                    } @else {
                        display: none;
                    }
                }
            }

            &:hover {
                color: $mega-sidemenu-link-color-hover;

                .shortcut {
                    &__icon {
                        transform: translateX(0.7rem);
                    }
                }
            }
        }

        &--text {
            position: relative;
            left: -$mega-sidemenu-link-text-position;

            @include touch {
                left: 0;
            }

            #{$parent} & {
                @if not($mega-sidemenu-icons-visible) {
                    left: 0;
                    margin-bottom: 1.8rem;
                }
            }
        }
    }

    .mega-list {
        position: relative;
        left: 10px;
    }
}
