$product-last-viewed-swiper-arrows: -3.2rem !default;
$product-last-viewed-color: #707070 !default;

$product-last-viewed-price-bg: $primary !default;
$product-last-viewed-price-color: $white !default;
$product-last-viewed-price-size-desktop: large !default;
$product-last-viewed-leasing-color: $black !default;

.product-last-viewed {
    @include is-full;

    overflow: hidden;

    &-swiper {
        overflow: visible;

        .swiper-wrapper {
            .swiper-slide {
                height: auto; // equal height slides
                display: flex;
            }
        }

        &__arrows {
            position: absolute;
            right: 0;
            left: 0;
            top: 0;
            padding-bottom: calc(var(--ratio-16-9) / 1.1);

            @include desktop {
                padding-bottom: calc(var(--ratio-16-9) / calc(var(--slides-per-view) + 0.15));
            }
        }

        &__prev,
        &__next {
            pointer-events: auto;
        }

        &__prev {
            left: $gutter-small;

            @include desktop {
                left: $product-last-viewed-swiper-arrows;
            }
        }

        &__next {
            right: $gutter-small;

            @include desktop {
                right: $product-last-viewed-swiper-arrows;
            }
        }
    }

    &__link {
        display: flex;
        flex: 1;
        flex-direction: column;
        text-decoration: none;
    }

    &__image {
        position: relative;
        aspect-ratio: 16 / 9;
        overflow: hidden;

        img {
            width: 100%;
            max-width: none;
            height: 100%;
            max-height: none;
            object-fit: cover;
        }

        &-fallback {
            position: absolute;
            inset: 0;
            display: grid;
            place-items: center;
        }
    }

    &__body {
        flex: 1;
        padding: $gutter-small $gutter-small 2rem $gutter-small;
    }

    &__title {
        font-size: map-get($font-sizes, medium);
        line-height: 1.5;
    }

    &__subtitle {
        color: $product-last-viewed-color;
        font-family: map-get($font-families, base);
        font-size: map-get($font-sizes, sub);
        line-height: 1.2;
    }

    &__price {
        display: flex;
        flex-wrap: wrap;
        line-height: 1.2;

        &-primary,
        &-secondary {
            margin-left: auto;
        }

        &-primary {
            padding: 1.2rem;
            background-color: $product-last-viewed-price-bg;
            color: $product-last-viewed-price-color;
            font-size: map-get($font-sizes, medium);

            @include fullhd {
                font-size: map-get($font-sizes, $product-last-viewed-price-size-desktop);
                text-align: right;
            }
        }

        &-secondary {
            color: $product-last-viewed-leasing-color;
            font-size: map-get($font-sizes, sub);
        }

        &-vat {
            font-size: map-get($font-sizes, small);
        }
    }
}
