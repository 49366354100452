$table-color: $brownish-grey;
$table-background-color: $white;

// cells
$table-cell-border: 1px solid $border;
$table-cell-border-width: 1px 1px 1px 1px;
$table-cell-padding: 1.9rem 2rem;
$table-cell-heading-color: $primary !default;

$table-head-cell-border-width: 1px 1px 1px 1px;
$table-head-cell-color: $black;

$table-head-background-color: $light-peach !default;
$table-head-border-color: $white !default;
$table-body-background-color: $white;

$table-foot-cell-border-width: 1px 1px 1px 1px;
$table-foot-background-color: $white;

$table-row-active-background-color: $primary;

/****** ELEMENTS *******/
@import 'bulma/sass/elements/table';

.table-head {
    @include typeset(sub, medium);
    color: $table-head-cell-color;
}

.table {
    width: 100%;
    margin: 2rem 0;

    &__bg {
        &--light {
            background-color: $table-head-background-color;
        }
    }

    thead {
        th {
            @extend .table-head;
            text-align: left;

            &:not(:first-child) {
                border-left-color: $table-head-border-color;
            }

            &:not(:last-child) {
                border-right-color: $table-head-border-color;
            }
        }
    }

    tbody {
        tr {
            &:last-child {
                td {
                    border-bottom-width: 1px;
                }
            }

            th,
            td {
                text-align: left;
                letter-spacing: map-get($letter-spacings, large);
            }
        }
    }

    .no-wrap {
        @include desktop {
            white-space: nowrap;
        }
    }
}
