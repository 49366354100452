$legal-font-size-small: 0.9rem !default;
$legal-font-letter-key: small !default;
$legal-font-letter-desktop-key: medium !default;
$legal-font-size: 1.3rem !default;
$legal-line-height: inherit !default;
$legal-color: $brown-grey !default;

.legal {
    font-size: $legal-font-size;
    line-height: $legal-line-height;
    letter-spacing: map-get($letter-spacings, $legal-font-letter-key);
    font-family: map-get($font-families, secondary);
    color: $legal-color;

    @include desktop {
        font-size: $legal-font-size;
        letter-spacing: map-get($letter-spacings, $legal-font-letter-desktop-key);
    }
}
