/****** Hyundai Sans Head *******/
@font-face {
    font-display: swap;
    font-family: 'HyundaiSansHead-Bold';
    src: url('/assets/fonts/HyundaiSansHead-Bold.eot');
    src:
        url('/assets/fonts/HyundaiSansHead-Bold.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/HyundaiSansHead-Bold.woff2') format('woff2'),
        url('/assets/fonts/HyundaiSansHead-Bold.woff') format('woff'),
        url('/assets/fonts/HyundaiSansHead-Bold.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'HyundaiSansHead-Light';
    src: url('/assets/fonts/HyundaiSansHead-Light.eot');
    src:
        url('/assets/fonts/HyundaiSansHead-Light.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/HyundaiSansHead-Light.woff2') format('woff2'),
        url('/assets/fonts/HyundaiSansHead-Light.woff') format('woff'),
        url('/assets/fonts/HyundaiSansHead-Light.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'HyundaiSansHead-Medium';
    src: url('/assets/fonts/HyundaiSansHead-Medium.eot');
    src:
        url('/assets/fonts/HyundaiSansHead-Medium.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/HyundaiSansHead-Medium.woff2') format('woff2'),
        url('/assets/fonts/HyundaiSansHead-Medium.woff') format('woff'),
        url('/assets/fonts/HyundaiSansHead-Medium.ttf') format('truetype');
}
@font-face {
    font-display: swap;
    font-family: 'HyundaiSansHead-Regular';
    src: url('/assets/fonts/HyundaiSansHead-Regular.eot');
    src:
        url('/assets/fonts/HyundaiSansHead-Regular.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/HyundaiSansHead-Regular.woff2') format('woff2'),
        url('/assets/fonts/HyundaiSansHead-Regular.woff') format('woff'),
        url('/assets/fonts/HyundaiSansHead-Regular.ttf') format('truetype');
}

/****** Hyundai Sans Text *******/
@font-face {
    font-display: swap;
    font-family: 'HyundaiSansText-Bold';
    src: url('/assets/fonts/HyundaiSansText-Bold.eot');
    src:
        url('/assets/fonts/HyundaiSansText-Bold.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/HyundaiSansText-Bold.woff2') format('woff2'),
        url('/assets/fonts/HyundaiSansText-Bold.woff') format('woff'),
        url('/assets/fonts/HyundaiSansText-Bold.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'HyundaiSansText-BoldItalic';
    src: url('/assets/fonts/HyundaiSansText-BoldItalic.eot');
    src:
        url('/assets/fonts/HyundaiSansText-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/HyundaiSansText-BoldItalic.woff2') format('woff2'),
        url('/assets/fonts/HyundaiSansText-BoldItalic.woff') format('woff'),
        url('/assets/fonts/HyundaiSansText-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'HyundaiSansText-Italic';
    src: url('/assets/fonts/HyundaiSansText-Italic.eot');
    src:
        url('/assets/fonts/HyundaiSansText-Italic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/HyundaiSansText-Italic.woff2') format('woff2'),
        url('/assets/fonts/HyundaiSansText-Italic.woff') format('woff'),
        url('/assets/fonts/HyundaiSansText-Italic.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'HyundaiSansText-Medium';
    src: url('/assets/fonts/HyundaiSansText-Medium.eot');
    src:
        url('/assets/fonts/HyundaiSansText-Medium.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/HyundaiSansText-Medium.woff2') format('woff2'),
        url('/assets/fonts/HyundaiSansText-Medium.woff') format('woff'),
        url('/assets/fonts/HyundaiSansText-Medium.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'HyundaiSansText-Regular';
    src: url('/assets/fonts/HyundaiSansText-Regular.eot');
    src:
        url('/assets/fonts/HyundaiSansText-Regular.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/HyundaiSansText-Regular.woff2') format('woff2'),
        url('/assets/fonts/HyundaiSansText-Regular.woff') format('woff'),
        url('/assets/fonts/HyundaiSansText-Regular.ttf') format('truetype');
}
