$video-spot-arrows-width: calc((var(--swiper-navigation-size) * 2) + #{$gutter} + 0.5rem);

.video-spot {
    &-slider {
        .swiper-wrapper {
            .swiper-slide {
                background-color: transparent;
            }
        }
    }

    &-controls {
        @include mobile {
            padding-bottom: 0;
        }

        &__column {
            &.column {
                @include mobile {
                    padding-bottom: 0;
                }
            }
        }

        &__arrow {
            flex: none !important;
            width: 15.3rem;
            height: var(--swiper-navigation-size);

            @include mobile {
                width: 12rem;
            }
        }
    }

    &__arrows {
        position: absolute;

        @include touch {
            top: 0;
            left: 0;
            right: 0;
            z-index: $index-body-overlay;
            pointer-events: none;
        }

        @include desktop {
            right: 0;
            width: $video-spot-arrows-width;
            height: var(--swiper-navigation-size);
        }
    }

    &__arrows-container {
        @include touch {
            @include aspect-ratio($ratio-16-7, false, centercenter);
        }
    }

    &__prev,
    &__next {
        pointer-events: auto;
        transform: translateY(50%);

        @include desktop {
            transform: translateY(0);
            top: 0;
            margin: 0;
        }
    }

    &__pagination {
        margin-bottom: $gutter;

        @include desktop {
            display: flex;
            align-items: center;
            width: calc(100% - (#{$video-spot-arrows-width} + #{$gutter-small}));
            height: var(--swiper-navigation-size);

            > .columns {
                width: 100%;
            }
        }
    }
}

.video-item {
    &__title {
        @include secondary-heading;
    }

    .rte-content {
        color: $image-text-picker-text-color;
    }
}
