$accordion-title-default-color: $black !default;
$accordion-title-light-color: $black !default;
$accordion-title-dark-color: $white !default;
$accordion-padding-right: 0 !default;

.accordion-spot {
    &__item {
        display: block;
        border-left: 1px solid $grey-lighter;
        border-right: 1px solid $grey-lighter;
        border-top: 1px solid $grey-lighter;
        padding: 0 $accordion-padding-right 0 2.5rem;

        &:last-child {
            border-bottom: 1px solid $grey-lighter;
        }
    }

    &__title {
        letter-spacing: map-get($letter-spacings, x-small);
        padding: 1.5rem 0;

        @include typeset(base, medium);

        @include when-inside('.grid__theme--default') {
            color: $accordion-title-default-color;
        }

        @include when-inside('.grid__theme--light') {
            color: $accordion-title-light-color;
        }

        @include when-inside('.grid__theme--dark') {
            color: $accordion-title-dark-color;
        }
    }

    &__text {
        margin-bottom: 2rem;
    }
}
