//shared styling for "standard-page" and standard-page-nav"

.standard-page {
    &.content {
        padding: 0 $gutter-small;
    }
}

.standard-page-nav {
    .grid {
        &__theme {
            &--default:nth-child(1) {
                background-color: transparent;
            }
        }
    }

    &.content {
        padding-top: 2rem;
    }

    &.has-banner {
        padding-top: 0;
    }

    &__content {
        padding-top: 0;

        @include touch {
            padding-left: 0;
            padding-right: 0;
        }

        @include desktop {
            padding-left: 0;
        }
    }
}
