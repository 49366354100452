$carousel-spacing: 5rem;

.carousel-item {
    &__link {
        text-decoration: none;
    }

    &__header {
        white-space: pre-line;
        color: inherit;

        @include desktop {
            z-index: 1;
            left: 0;
            position: absolute;
            max-width: 32rem;
            margin-left: $carousel-spacing;

            &.is-top {
                top: $carousel-spacing;
            }

            &.is-bottom {
                bottom: $carousel-spacing;
            }

            .has-text-color-light {
                color: $white;
            }

            .has-text-color-dark {
                color: $black;
            }
        }

        p {
            line-height: 1.2;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__title {
        @include secondary-heading;
        padding-top: 1.6rem;
        color: $rte-default-theme-text;

        @include desktop {
            .has-text-color-light {
                color: $white;
            }

            .has-text-color-dark {
                color: $black;
            }
        }

        @include when-inside('.grid__theme--light') {
            color: $rte-light-theme-text;
        }

        @include when-inside('.grid__theme--dark') {
            color: $rte-dark-theme-text;
        }

        @include until($desktop) {
            @include secondary-heading-mobile;
        }
    }

    &__button {
        @include touch {
            > .button {
                @include outline-theme();
            }
        }

        @include desktop {
            > .is-outline-black {
                @include outline-button($black, lighten($black, 10%), transparent, rgba($black, 10%), $black, lighten($black, 10%));
            }

            > .is-outline-white {
                @include outline-button($white, darken($white, 10%), transparent, rgba($white, 10%), $white, darken($white, 10%));
            }
        }
    }
}
