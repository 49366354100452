$filter-sidepanel-min-width: 42.5rem;
$filter-sidepanel-overlay-height: 3.9rem !default;
$filter-sidepanel-bg: $white !default;

.filter-sidepanel {
    position: relative;
    display: block;
    height: 100%;
    padding: 0;
    width: 24vw;
    min-width: $filter-sidepanel-min-width;
    background-color: $filter-sidepanel-bg;

    @include mobile {
        width: 100vw;
        min-width: auto;
    }

    // Used to hide background on slide in animation
    &::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 9rem;
        background-color: $filter-sidepanel-bg;
        top: 0;
        right: -9rem;
        bottom: 0;
    }

    &__close {
        position: absolute;
        z-index: 900;
        top: 0;
        transform: translate3d(-50%, 2.25rem, 0);

        @include from($tablet) {
            left: 0;
        }

        @include mobile {
            top: 0.6rem;
            right: $gutter-small;
            transform: translate3d(0, 0, 0);
        }
    }

    &__content {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr auto;
        background-color: $filter-sidepanel-bg;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &__header {
        grid-area: 1 / 1 / 2 / 2;
        padding-top: 2rem;
        padding-bottom: 2rem;
        padding-left: $gutter-small;

        @include tablet {
            padding-top: 3.5rem;
            padding-bottom: 3.5rem;
            padding-left: $filter-padding-left;
        }
    }

    &__facets {
        grid-area: 2 / 1 / 4 / 2;
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: 9rem;
    }

    &__bottom {
        background: linear-gradient(180deg, transparent 20%, $filter-sidepanel-bg 90%);
        grid-area: 3 / 1 / 4 / 2;
        position: relative;
        z-index: $index-body-overlay;
        padding: 1rem $gutter-small;

        @include desktop {
            padding: 1rem $gutter 1rem $filter-padding-left;
        }
    }
}
