$model-nav-padding-horizontal: 1rem !default;
$model-nav-padding-vertical: 1.4rem !default;
$model-nav-padding-vertical-mobile: 1.7rem !default;
$model-nav-padding-vertical-desktop: 1.3rem !default;
$model-nav-padding-vertical-fullhd: 2.3rem !default;
$model-nav-active-color: $white !default;
$model-nav-hover-color: $blue-light !default;
$model-nav-item-size-key: sub !default;
$model-nav-active-box-width: -3px !default;
$model-page-has-border-top: false !default;
$model-nav-scroll-fade-z-index: 31 !default; // bulma's nav has z-index 30

ncg-model-page-nav {
    display: block;
    width: 100%;
}

.model-page {
    @if $model-page-has-border-top {
        border-top: 1rem solid $primary;
    }

    &__navbar {
        max-width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;

        @include touch {
            padding-left: 0;
            padding-right: 0;
            text-align: center;
            border-top: 1px solid $model-page-header-border-color;
            border-bottom: 1px solid $model-page-header-border-color;
        }

        .navbar-item {
            display: inline-block;

            @include touch {
                font-size: map-get($font-sizes, sub);
                padding: 0 $model-nav-padding-horizontal !important;

                &:first-child {
                    padding-left: 4rem;
                }

                &:last-child {
                    padding-right: 4rem;
                }

                &:hover {
                    color: $model-nav-hover-color !important;
                }
            }

            @include desktop {
                font-size: map-get($font-sizes, $model-nav-item-size-key) !important;
                padding: $model-nav-padding-vertical $model-nav-padding-horizontal !important;
            }

            span {
                box-shadow: inset 0 0 0 $model-nav-active-color;
                transition: box-shadow 300ms ease-in-out;
                display: inline-block;

                @include touch {
                    padding: $model-nav-padding-vertical-mobile 0;
                }

                @include desktop {
                    padding: $model-nav-padding-vertical-desktop 0;
                }

                @include from($desktop-large) {
                    padding: $model-nav-padding-vertical-fullhd 0;
                }
            }

            &.is-active {
                color: $model-nav-active-color !important;

                span {
                    box-shadow: inset 0 $model-nav-active-box-width 0 $model-nav-active-color;
                }
            }
        }

        &-scroll {
            position: relative;

            &--left {
                &::before {
                    @include scroll-fade($sub-nav-header-bg, left, $model-nav-scroll-fade-z-index);
                }
            }

            &--right {
                &::after {
                    @include scroll-fade($sub-nav-header-bg, right, $model-nav-scroll-fade-z-index);
                }
            }

            &--both {
                &::before {
                    @include scroll-fade($sub-nav-header-bg, left, $model-nav-scroll-fade-z-index);
                }

                &::after {
                    @include scroll-fade($sub-nav-header-bg, right, $model-nav-scroll-fade-z-index);
                }
            }
        }
    }
}
