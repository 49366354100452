$rte-content-headline-family-key: medium !default;
$rte-content-headline-padding-top: 1.6rem !default;
$rte-content-headline-margin-bottom: 0.2rem !default;
$rte-content-text-color-light: $text !default;

$rte-content-table-head-color: $table-head-cell-color !default;
$rte-content-table-head-background-color: $table-head-background-color !default;

/* stylelint-disable */
@mixin bag-table-header {
    // these styles matches the class in Umbraco -> Settings -> Stylesheets -> RTE.css
    background-color: #1a65c9;
    color: #fff;
}
/* stylelint-enable */

.rte-content {
    @include body-copy;
    overflow: auto hidden; // overflow-x: auto; is only used to support scroll on table
    padding-right: 1px; // Hack to prevent scrollbar from showing when not needed.

    @include when-inside('.grid__theme--default') {
        color: $text;
    }

    @include when-inside('.grid__theme--light') {
        color: $rte-content-text-color-light;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: $rte-content-headline-margin-bottom;
        padding-top: $rte-content-headline-padding-top;
        font-family: map-get($font-families, $rte-content-headline-family-key);

        @include when-inside('.grid__theme--default') {
            color: $rte-default-theme-text;
        }

        @include when-inside('.grid__theme--light') {
            color: $rte-light-theme-text;
        }

        @include when-inside('.grid__theme--dark') {
            color: $rte-dark-theme-text;
        }

        &:first-child:not(.image-text-item-title) {
            padding-top: 0;
        }
    }

    a {
        display: inline-block;
        color: $link-default-color;
        transition: color $duration-fast $button-easing;

        &:not(.button) {
            text-decoration: $link-default-decoration;
        }

        &:hover,
        :focus,
        :visited {
            color: $link-default-color-hover;

            &:not(.button) {
                text-decoration: $link-default-decoration;
            }
        }

        // Used to hide text-decoration underline from RTE buttton.
        // Float hack is only needed when RTE has generated markup where "button" span is inside a tag
        .button {
            float: left;
        }
    }

    .button {
        min-width: $button-min-width;
        margin-right: 1rem;
        margin-bottom: 1rem;

        a,
        a:hover,
        a:visited {
            // Some times Umbraco RTE will surround `a` with .button
            text-decoration: none;
        }
    }

    .button {
        &.is-outline,
        &.is-filled {
            display: inline-flex;
        }
    }

    .link-as-text {
        a {
            padding: 0;
            display: inline-block;
            border: 0;
            text-decoration: underline;
            height: auto;
            font-family: map-get($font-families, text);
            font-size: map-get($font-sizes, base);
            color: inherit;
            margin: 0;
            min-width: auto;

            &:hover {
                color: inherit;
                background-color: transparent;
                text-decoration: none;
            }
        }
    }

    ul,
    ol {
        margin-bottom: 1rem;
        list-style-position: outside;

        &[style*='text-align: center;'] {
            padding-left: 0;
            list-style-position: inside;
        }

        li {
            padding: 0.2rem 0;
        }
    }

    ul {
        padding-left: 2rem;
        list-style-type: disc;
    }

    ol {
        padding-left: 3.7rem; // Has to have more spacing, so the numbers does'nt get cut in safari
        list-style-type: decimal;
    }

    table {
        /* stylelint-disable */
        @extend .table;

        tr {
            &:first-child {
                @extend .table-head;
                color: $rte-content-table-head-color;
                background-color: $rte-content-table-head-background-color;

                &.bag-table-header {
                    @include bag-table-header;
                }

                td {
                    > * {
                        line-height: 1;
                        margin: 0;
                        padding: 0;
                    }
                }
            }
        }
        /* stylelint-enable */
    }

    .bag-table-header {
        @include bag-table-header;
    }
}
