.mobile-header-button {
    display: inline-flex;
    flex-flow: wrap column;
    align-items: center;
    justify-content: flex-end;
    color: $primary;
    text-decoration: none;

    &:hover {
        color: $primary;
    }

    span {
        font-family: map-get($font-families, base);
        font-size: map-get($font-sizes, mobileMenu);
        line-height: map-get($line-heights, small);
        padding-top: 0.2rem;
    }

    svg-icon-sprite {
        display: flex;
    }
}
