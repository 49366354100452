// NOTE: This is a custom implementation of bootstrap styling
// LINK: https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/_dropdowns.scss
.custom-dropdown {
    .dropdown {
        position: relative;

        &-toggle {
            :focus {
                outline: 0;
            }
        }

        &-menu {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: $index-body-overlay;
            background-color: $white;
            min-width: 24rem;
            box-shadow: 0 0 20px 0 rgba($black, 0.1);
            padding: 2rem 3rem 1rem;
        }
    }
}
