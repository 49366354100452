.configurator {
    width: 100%;
    height: 100%;
    background-color: $white;

    &__container {
        padding: 0 1.5rem;
    }

    &__content {
        overflow: hidden;
        padding: 3rem 0;

        @include desktop {
            padding: 5rem 0;
        }
    }

    &__cards {
        display: flex;
        flex-wrap: wrap;
        gap: 1.2rem;
        align-items: stretch;
        justify-content: center;
    }

    &__card {
        flex: 0 1 28.4rem;
        display: grid;
    }

    &__card-content {
        height: 100%;
        position: relative;
        padding: 2.2rem 3.2rem;
        display: flex;
        flex-direction: column;
    }

    &__headline {
        color: var(--color-black);
        font-size: var(--configurator-headline-size);
        font-family: var(--font-family-base-medium);
        letter-spacing: var(--letter-spacing-x-large);
        font-weight: var(--configurator-headline-weight);
        line-height: var(--line-height-small);
        margin: 1.2rem 0;

        &:first-child {
            margin-top: 0;
        }
    }

    &__sub-headline {
        font-size: var(--font-size-base);
        font-family: var(--font-family-base-medium);
        color: var(--color-grey-darker);
        font-weight: var(--configurator-headline-weight);
        line-height: var(--line-height-small);
        margin: 1em 0;

        &--no-margin,
        &:first-child {
            margin-top: 0;
        }
    }

    &__body-text {
        font-size: var(--font-size-base);
        font-family: var(--font-family-base);
        font-weight: var(--font-weight-base);
        color: var(--color-black);
        margin-bottom: 2rem;
        line-height: var(--line-height-base);
    }

    &__label {
        font-size: var(--configurator-label-font-size);
        font-family: var(--font-family-base-medium);
        letter-spacing: var(--letter-spacing-x-large);
        font-weight: var(--configurator-headline-weight);
        color: var(--color-black);
        line-height: var(--line-height-small);
        margin: 1em 0;

        &:first-child {
            margin-top: 0;
        }
    }

    &__price {
        font-size: var(--font-size-base);
        font-family: var(--font-family-base-bold);
        letter-spacing: var(--letter-spacing-x-large);
        color: var(--color-black);
        margin-top: auto;
    }

    &__leasing {
        font-size: var(--font-size-small);
        font-family: var(--font-family-base-bold);
        letter-spacing: var(--letter-spacing-x-large);
        color: var(--color-black);
    }

    &__text {
        font-size: var(--font-size-small);
        font-family: var(--font-family-base);
        color: var(--color-grey-darker);
        letter-spacing: var(--letter-spacing-x-large);

        &--bold {
            font-weight: var(--configurator-small-text-weight-bold);
            font-family: var(--font-family-base-bold);
        }
    }

    &__text-tiny {
        font-size: var(--font-size-tiny);
        font-family: var(--font-family-base);
        color: var(--color-grey-darker);
        letter-spacing: var(--letter-spacing-x-large);

        &--bold {
            font-weight: var(--configurator-small-text-weight-bold);
            font-family: var(--font-family-base-bold);
        }
    }

    // Not super cool but needed to difer weight on BYD and Hyundai
    &__font-weight {
        &--emphasis {
            font-weight: var(--configurator-headline-weight);
        }
    }

    &__list {
        font-size: var(--font-size-small);
        color: var(--color-grey-darker);
    }

    &__link-cover {
        @extend .link-cover;
        text-decoration: none;
    }

    &__legal {
        margin-top: 6rem;
    }

    &__narrow {
        width: 100%;
        max-width: min(65rem, 100%);
        margin: 0 auto;
    }

    &__word-break {
        word-break: break-word;
    }

    &__notification {
        font-size: var(--font-size-base);
        font-family: var(--font-family-base);
        font-weight: var(--font-weight-bold);
        color: var(--color-black);
        margin: 2rem 0;
        line-height: 1.5;
        text-align: center;
    }
}
