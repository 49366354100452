$product-gallery-carousel-button-size: 5rem;
$product-gallery-carousel-button-position: 3.5rem;

.product-gallery-carousel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $white;
    height: 100%;
    width: 100vw;

    .swiper-pagination {
        position: absolute;
        width: 5.6rem;
        height: 2.7rem;
        bottom: 5rem;
        left: 50%;
        transform: translateX(-50%);
        background-color: $grey-darker;
        z-index: 2;
        color: $white;
        border-radius: 25px;
        cursor: default;
        @include center-content;
        @include typeset(sub);
        @include mobile-landscape {
            display: block !important;
        }
    }

    &__pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-column-start: 2;
        grid-column-end: 3;
    }

    &__close {
        display: flex;
        justify-content: flex-end;
        grid-column-start: 3;
        grid-column-end: 4;
    }

    &__button {
        height: $product-gallery-carousel-button-size;
        width: $product-gallery-carousel-button-size;

        &--next {
            right: $product-gallery-carousel-button-position;
        }

        &--prev {
            left: $product-gallery-carousel-button-position;
        }

        &-container {
            @include mobile-landscape {
                display: none;
            }
        }
    }

    &__close-btn {
        display: flex;
        flex-direction: column;
        transform: translateY(-50%);
        position: absolute;
        right: 2.5rem;
        top: 4.5rem;
        z-index: 2;
        background-color: unset;
        padding: 0;

        svg {
            width: $product-gallery-carousel-button-size !important;
            height: $product-gallery-carousel-button-size !important;
        }

        &:hover {
            color: $primary;
        }
    }

    &__content {
        width: 100vw;
        height: 100%;
        margin: 0 auto;
        padding-left: 3rem;
        padding-right: 3rem;
        position: relative;

        @include mobile-landscape {
            padding: 0;
        }

        @include mobile {
            padding: 0;
        }
    }

    &__image {
        @include tablet {
            max-height: 80vh;
        }
        @include mobile-landscape {
            max-height: unset;
        }
    }

    &__container {
        height: 100%;
        padding: 0;

        @include tablet {
            padding: 3.5rem 0;
        }

        @include mobile-landscape {
            padding: 0;
        }

        .swiper {
            position: static;
            width: 100%;
            height: 100%;
        }
    }

    &__video {
        position: relative;
        width: 100%;
        height: 100%;
        display: block;

        &--iframe {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__footer {
        display: flex;
        align-items: flex-end;
        padding-bottom: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;

        @include mobile-landscape {
            display: none;
        }
    }
}
