$specification-input-color: $brownish-grey !default;

.specifications-spot {
    &__trims {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 3rem;

        &_title {
            @include typeset(base, medium);
            letter-spacing: map-get($letter-spacings, x-small);
            color: $black;
            width: 30%;

            @include mobile {
                padding-right: 1rem;
            }
        }

        &_select {
            width: 70%;
        }

        & &_input {
            @include body-copy;
            color: $specification-input-color;
            width: 100%;
            height: 100%;
            border-radius: 0;
        }
    }

    &__price {
        &_title {
            @include typeset(base, medium);
            color: $black;
        }

        &_table {
            overflow-x: auto;

            > table {
                width: 100%;
                margin: 1rem 0;

                > tr {
                    :not(:last-child) {
                        th {
                            border-bottom-color: $white;
                        }
                    }

                    > th {
                        line-height: 2;
                        width: 30%;

                        @include sub-heading;
                        color: $black;

                        &.energylabel {
                            border-bottom-color: $light-brown;
                        }
                    }

                    > td {
                        @include body-copy;
                        color: $brownish-grey;

                        &.discount-pr1ice {
                            padding-top: 7px;
                            padding-bottom: 7px;
                        }

                        span.line-through {
                            text-decoration: line-through;
                        }

                        span.bold {
                            font-weight: $font-weight-bold;
                        }
                    }
                }
            }

            .is-info {
                margin-bottom: 1rem;
            }
        }
    }

    &__items {
        margin-top: 4rem;

        .accordion-spot {
            &__title {
                @include tertiary-heading;
                font-size: map-get($font-sizes, base);
                color: $black;
            }
        }
    }

    &__items_table {
        &.columns:last-child {
            margin-top: -1rem;
            margin-bottom: -1rem;
        }
    }

    &__property-label {
        @include sub-heading;
        color: $black;
    }

    &__button {
        margin-top: 2rem;
        text-align: center;
    }
}
