$product-item-tags-size-key: x-small !default;
$product-item-tags-size-key--large: medium !default;
$product-item-tags-weight: normal !default;
$product-item-tags-letter-key: small !default;
$product-item-tag-primary-bg: $yellow-dark !default;
$product-item-tag-secondary-bg: $primary !default;

.product-item {
    $parent: &;

    &__tags {
        display: flex;
        flex-wrap: wrap;
        gap: $gutter-small;

        @include when-inside('.is-list-view') {
            gap: 0;
        }
    }

    &__tag {
        padding: 1.5vw;
        color: $white;
        font-family: map-get($font-families, bold);
        font-size: 2.5vw;
        font-weight: $product-item-tags-weight;
        line-height: 1;
        letter-spacing: map-get($letter-spacings, $product-item-tags-letter-key);
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;

        #{$parent}__tags--default & {
            @media (min-width: 425px) {
                font-size: map-get($font-sizes, $product-item-tags-size-key);
                padding: 1rem;
            }
        }
        #{$parent}__tags--large & {
            font-size: clamp(
                #{map-get($font-sizes, $product-item-tags-size-key)},
                1vw + 0.5rem,
                #{map-get($font-sizes, $product-item-tags-size-key--large)}
            );
            padding: 1.3rem;
        }

        &:last-child {
            margin-right: 0;
        }

        &--primary {
            background-color: $product-item-tag-primary-bg;
        }

        &--secondary {
            background-color: $product-item-tag-secondary-bg;
        }

        &--premium {
            @include when-inside('.is-list-view') {
                @include until($tablet) {
                    display: none;
                }
            }
        }
    }
}
