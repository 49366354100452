/****** COLORS *******/
$white: white;
$blue: #1a65c9;

$grey-darker: #767676;
$grey-dark: #757575;
$grey: #e1e1e1;
$grey-light: #e6e6e6;
$grey-lighter: #f4f4f4;
$blue-dark: #215397;

$black: black;
$black-bis: #262626;
$brownish-grey: $grey-dark;

/****** General colors *******/
$foreground: $white;
$background: $white;

$hover: #084eab;
$primary: $blue;
$success: #0bce53;
$text: $black-bis;
$text-light: $grey-dark;
