$location-title-color: $black !default;
$location-address-color: $brownish-grey !default;

.select-location {
    .select-location-item {
        display: flex;
        justify-content: center;
        flex-direction: column;
        @extend %field-text;
        line-height: normal;

        &__title {
            color: $location-title-color;
            font-size: map-get($font-sizes, base);
        }

        &__address {
            font-size: map-get($font-sizes, sub);
            color: $location-address-color;
        }
    }

    .select-location-brand {
        align-self: center;

        @include when-inside('.newsletter') {
            align-self: flex-start;
        }
    }
}
