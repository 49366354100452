$filter-page-bg: $grey-lighter !default;
$has-filter-page-border-top: false !default;

.filter-page {
    &--panel {
        background-color: $filter-page-bg;
    }

    &--full {
        background-color: transparent;

        @if $has-filter-page-border-top {
            border-top: 1px solid $border;
        }
    }

    &-breadcrumb {
        &--panel {
            @include until($desktop) {
                background-color: $header-bg-color;

                @if $has-filter-page-border-top == false {
                    border-bottom: 1px solid $border;
                }
            }

            background-color: $filter-page-bg;
        }

        &--full {
            padding-right: $container-padding-touch;
            padding-left: $container-padding-touch;

            @if $has-filter-page-border-top == false {
                border-bottom: 1px solid $border;
            }
        }
    }

    .grid {
        &__theme {
            background-color: $filter-page-bg;
        }
    }
}
