.byd-hero-spot {
    position: relative;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(20, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;
    aspect-ratio: 100 / 105;

    @include tablet {
        aspect-ratio: 16 / 9;
    }

    &__wrapper,
    &__bottom-links {
        z-index: 1;
        text-align: center;
        align-self: center;
    }

    &__top-links,
    &__bottom-links {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 0.5rem;

        @include tablet {
            gap: 3rem;
        }

        @include desktop {
            gap: 6rem;
        }
    }

    &__top-links {
        @include tablet {
            margin-top: 3rem;
        }

        @include widescreen {
            margin-top: 5rem;
        }
    }

    &__bottom-links {
        grid-area: -1 / -1 / -5 / 1;
    }

    &__legal {
        z-index: 2;
        grid-area: 1 / 1 / -1 / -1;
        align-self: end;
        padding: $gutter-small;
        text-align: center;

        @include tablet {
            padding-left: $gutter;
            padding-right: $gutter;
        }

        .legal {
            color: $white;

            b {
                font-size: inherit;
                font-weight: bold;
            }
        }

        &.legal-text-moved,
        &.legal-text-moved-desktop {
            grid-area: -1 / span 22;
            padding-bottom: 2em;
        }

        &-bg {
            z-index: 1;
            grid-area: -7 / 1 / -1 / -1;
            opacity: 0.8;
            mix-blend-mode: overlay;
            background-image: linear-gradient(to top, $black, rgba($black, 0));

            @include tablet {
                grid-area: -5 / 1 / -1 / -1;
            }
        }
    }

    &__media-container {
        grid-area: 1 / 1 / -1 / -1;

        video,
        img {
            width: 100%;
            max-width: none;
            height: 100%;
            max-height: none;
            object-fit: cover;
        }

        video {
            pointer-events: none;
        }

        &--media-filter {
            img,
            video {
                opacity: 0.8;
            }
        }
    }

    &__wrapper {
        grid-area: 3 / 2 / 10 / -2;

        @include tablet {
            grid-area: 1 / 3 / 10 / -3;
        }

        @include desktop {
            grid-area: 1 / 5 / 10 / -5;
        }
    }

    &__title {
        margin-bottom: 1rem;

        &.h1 {
            /* TODO refactor later to use the platform - BYD Typography Display 1 */
            font-size: 3.8rem;
            line-height: 1.21; // 46px / 38px

            @include tablet {
                font-size: 4.6rem;
                line-height: 1.08; // 50px / 46px
            }

            @include desktop {
                font-size: 5.6rem;
                line-height: 1.07; // 60px / 56px
            }
        }

        &.h2 {
            /* TODO refactor later to use the platform - BYD Typography Headline 1 */
            font-size: 2.6rem;
            line-height: 1.15; // 30px / 26px

            @include tablet {
                font-size: 3.4rem;
                line-height: 1.11; // 38px / 34x
            }

            @include desktop {
                font-size: 4rem;
                line-height: 1.2; // 48px / 40px
            }
        }
    }

    &__content {
        @include tablet {
            max-width: 80rem;
            margin: auto;
        }
    }

    .button {
        /* TODO refactor this override 🤷🏽‍♀️ */
        min-height: 4rem;
    }
}

.is-ratio-byd-hero {
    aspect-ratio: 100 / 105;

    @include tablet {
        aspect-ratio: 16 / 9;
    }
}
