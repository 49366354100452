$mega-model-item-padding-vertical: 2rem !default;
$mega-model-item-padding-horizontal: 2rem !default;
$mega-model-button-height: 6.4rem !default;
$mega-model-container-space-top: 2.5rem !default;

.mega-models {
    &__container {
        padding-top: $mega-model-container-space-top;
        padding-bottom: 3rem;
    }

    &__wrapper {
        padding-top: 5rem;
        will-change: height;
        transition: height $duration-fast $smooth-out;
    }

    .mega-models {
        &__nav {
            position: relative;
            z-index: 99;
            border-bottom: $border 1px solid;
        }

        &__filter.button {
            height: $mega-model-button-height;
            letter-spacing: map-get($letter-spacings, large);

            > span,
            > span.is-active {
                line-height: $mega-model-button-height + 0.1; // The plus 0.1 is to hide the grey background
            }
        }

        &__navbar {
            .navbar-end {
                padding-left: 1.5rem;
                align-items: center;
            }
        }

        &__item {
            padding: $mega-model-item-padding-vertical $mega-model-item-padding-horizontal;

            &.is-active-bottom {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    left: $mega-model-item-padding-horizontal;
                    right: $mega-model-item-padding-horizontal;
                    bottom: 0;
                    height: 0.3rem;
                    background-color: $primary;
                }
            }
        }

        &__link {
            display: flex;
            flex-flow: wrap column;
            margin-bottom: 4.2rem;
            letter-spacing: map-get($letter-spacings, large);
            color: $mega-menu-link-color;
            text-decoration: none;
            padding-left: 0.7rem !important;
            padding-right: 0.7rem !important;
            text-align: center;
            transition: color $duration-link ease-in-out;

            &:hover {
                color: $mega-menu-link-color-hover;

                .mega-models {
                    &__link {
                        &--price {
                            color: $mega-menu-link-color-hover;
                        }
                    }
                }
            }

            &--image {
                flex: 0 0 auto;
                display: block;
                width: 100%;
            }
        }

        &__header {
            position: relative;

            .models-list {
                &__header {
                    &--tag {
                        top: -2.3rem;
                    }
                }
            }

            &--tag {
                position: absolute;
                top: -2.6rem;
                left: 0;
                right: 0;
                text-align: center;
            }

            &--title {
                padding-top: 0.7rem;
                padding-bottom: 0.2rem;
                @include typeset(medium, medium, secondary);
                letter-spacing: map-get($letter-spacings, small);
            }

            &--price {
                @include typeset(sub);
                letter-spacing: map-get($letter-spacings, large);
                color: $brownish-grey;
                transition: color $duration-link ease-in-out;
            }
        }
    }
}
