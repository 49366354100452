$grid-banner-row-bg-mobile: 2.5rem !default;
$grid-banner-row-bg: 3.5rem !default;
$grid-banner-row-has-bg: true !default;
$grid-row-bg-clip-first: 5rem !default;
$grid-row-bg-clip-first-mobile: 2.5rem !default;
$grid-row-bg-clip: calc(5rem + #{$section-padding-vertical} + #{$spot-padding-top}) !default;
$grid-row-bg-clip-mobile: calc(5rem + #{$section-padding-mobile-vertical} + #{$spot-padding-top}) !default;
$grid-row-bg-clip-half: $grid-row-bg-clip-first !default;
$grid-first-row-padding-top: 0 !default;
$grid-first-row-padding-touch-top: 0 !default;

@import 'bulma/sass/grid/columns';

.columns {
    &:first-child,
    &:last-child {
        margin-top: 0;
        margin-bottom: 0;
    }

    // Can be used with bulma's is-variable, to add same padding to top and bottom
    &.is-custom-variable {
        .column {
            padding: var(--columnGap);
        }
    }
}

%row-theme {
    &::before {
        content: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: $grid-row-bg-clip;
        background-color: $background;
        z-index: -1;

        @include touch {
            height: $grid-row-bg-clip-mobile;
        }
    }
}

%row-theme-default {
    background-color: $default-theme-bg;
    color: $default-theme-text;
}

%row-theme-dark {
    background-color: $dark-theme-bg;
    color: $dark-theme-text;
}

%row-theme-light {
    background-color: $light-theme-bg;
    color: $light-theme-text;
}

.grid {
    &__theme {
        @extend %row-theme;

        &--full {
            &::before {
                content: none;
            }
        }

        &--up {
            &::before {
                content: '';
                top: auto;
                bottom: 0;
            }
        }

        &--middle {
            &::before {
                content: '';
                top: 0;
            }

            &::after {
                content: '';
                position: absolute;
                z-index: -1;
                left: 0;
                right: 0;
                bottom: 0;
                height: $grid-row-bg-clip;
                background-color: $background;

                @include touch {
                    height: $grid-row-bg-clip-mobile;
                }
            }
        }

        &--bottom-half {
            &::before {
                content: '';
                top: 0;
                bottom: auto;
                height: $grid-row-bg-clip-half;
            }
        }

        &--top-padding {
            padding-top: $grid-first-row-padding-top;

            /* stylelint-disable */
            &:not(&.grid__theme--bottom-half) {
                &::before {
                    height: $grid-row-bg-clip-first;

                    @include touch {
                        height: $grid-row-bg-clip-first-mobile;
                    }
                }

                &.grid__theme--up {
                    &::before {
                        height: $grid-row-bg-clip-first * 2;

                        @include touch {
                            height: $grid-row-bg-clip-first-mobile * 2;
                        }
                    }
                }
            }
            /* stylelint-enable */
        }

        &--no-v-padding {
            padding-top: 0;
            padding-bottom: 0;
        }

        &--default {
            @extend %row-theme-default;
        }

        &--dark {
            @extend %row-theme-dark;
        }

        &--light {
            @extend %row-theme-light;
        }

        @if $grid-banner-row-has-bg {
            &--banner {
                @extend %row-theme-dark;
                @extend %row-theme;

                @include mobile {
                    &::before,
                    &::after {
                        height: $grid-banner-row-bg-mobile;
                    }
                }

                @include tablet {
                    &::before,
                    &::after {
                        height: $grid-banner-row-bg;
                    }
                }
            }
        } @else {
            &--banner {
                padding-top: $grid-first-row-padding-top;

                @include touch {
                    padding-top: $grid-first-row-padding-touch-top;
                }
            }
        }

        &.is-same-prev {
            padding-top: 0;
        }
    }

    &__row {
        position: relative;
        z-index: 2;
    }
}

/**
Use bulma mixins for responsiveness:

@include from($devicePx) {}
@include until($devicePx) {}
@include mobile {}
@include tablet {}
@include tablet-only {}
@include touch {}
@include desktop {}
@include desktop-only {}
@include until-widescreen {}
@include widescreen {}
@include widescreen-only {}
@include until-fullhd {}
@include fullhd {}
*/
