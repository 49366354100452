$font-family-base: 'BMWTypeNextLatin-Light', sans-serif;
$font-family-base-medium: 'BMWTypeNextLatin-Light', sans-serif;
$font-family-base-bold: 'BMWTypeNextLatin-Bold', sans-serif;
$font-family-secondary: 'BMWTypeNextLatin-Light', sans-serif;
$font-family-secondary-medium: 'BMWTypeNextLatin-Light', sans-serif;

$font-size-mobile-menu: 1rem;
$font-size-tiny: 1rem;
$font-size-x-small: 1.1rem;
$font-size-small: 1.4rem;
$font-size-sub: 1.6rem;
$font-size-base: 1.8rem;
$font-size-navigation-small: 1.7rem;
$font-size-navigation: 2rem;
$font-size-medium: 2rem;
$font-size-large: 2.5rem;
$font-size-large-mobile: 2.4rem;
$font-size-larger: 3rem;
$font-size-largest-mobile: 3.5rem;
$font-size-largest: 5rem;
$font-size-huge: 6rem;

$line-height-footer: 1.4;
$line-height-base: 1.78;
$line-height-large: 2;

$letter-spacing-base: normal;
$letter-spacing-x-small: normal;
$letter-spacing-small: normal;
$letter-spacing-medium: normal;
$letter-spacing-large: normal;

$sub-heading-size-key: sub;
$sub-heading-family-key: bold;
