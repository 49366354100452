.motorsize-spot {
    > .container {
        padding: 0 !important;
        margin: 0 auto !important;
        max-width: 100% !important;
    }
}

.motorsize-item {
    background: $light-peach;
    padding: 5rem 2rem 3rem;

    &__title {
        @include secondary-heading;
        color: $black;
    }

    &__description {
        @include body-copy-small;
        margin: 1rem auto;
        padding-bottom: 0.1rem;
        color: $brownish-grey;
        line-height: 2.5rem;
        letter-spacing: map-get($letter-spacings, large);
        font-size: 1.7rem;
        @include tablet {
            max-width: 50%;
        }
    }

    .motorsize-item__data.column {
        @include until($mobile-small) {
            width: 50%;
        }
    }

    &__figure {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 92px;
        width: 92px;
        margin: 0 auto 1rem;
        border-radius: 50%;
        background-color: $white;
    }

    &__label {
        @include body-copy-small;
        color: $brownish-grey;
    }

    &__value {
        @include tertiary-heading;
        color: $black;
    }
}
