.interior-view {
    &__container {
        width: 100%;
    }

    &__controls {
        position: absolute;
        z-index: $index-body-overlay;
        bottom: 2rem;
        right: 2rem;
        background-color: $white;
    }

    &__control {
        cursor: pointer;
        line-height: 0;
        background-color: $white;
        transition: background-color $duration-medium $smooth-in-out;

        &:hover {
            background-color: darken($white, 10%);
        }

        > svg {
            width: 2rem;
            height: 2rem;

            @include desktop {
                width: 3rem;
                height: 3rem;
            }
        }
    }

    .pnlm {
        &-container {
            background: transparent;
        }

        &-load-box {
            display: none !important;
        }
    }

    .spinner {
        color: $white;
    }
}
