:root {
    // Colors
    --color-primary: #{$primary};
    --color-very-light-pink: #{$very-light-pink};
    --color-white: #{$white};
    --color-black: #{$black};
    --color-border: #{$border};
    --color-grey: #{$grey};
    --color-grey-dark: #{$grey-dark};
    --color-grey-darker: #{$grey-darker};
    --color-grey-light: #{$grey-light};
    --color-grey-lighter: #{$grey-lighter};
    --color-favorite: var(--color-primary);
    --color-danger: #{$danger};

    --button-primary-bg: #{$button-primary-bg};
    --button-primary-bg-hover: #{$button-primary-hover-bg};
    --button-primary-border-color: #{$button-primary-border-color};
    --button-primary-border-color-hover: #{$button-primary-border-hover-color};
    --button-primary-text-color: #{$button-primary-color};
    --button-primary-text-color-hover: #{$button-primary-text-hover-color};

    // Text colors
    --text: #{$text};
    --text-invert: #{$text-invert};
    --text-light: #{$text-light};
    --text-strong: #{$text-strong};
    --rte-default-theme-text: #{$rte-default-theme-text};
    --rte-dark-theme-text: #{$rte-dark-theme-text};
    --rte-light-theme-text: #{$rte-light-theme-text};

    // button outline in default row theme
    --button-outline-text-color: #{$button-outline-text-color};
    --button-outline-text-color-hover: #{$button-outline-text-hover-color};
    --button-outline-bg: #{$button-outline-bg};
    --button-outline-bg-hover: #{$button-outline-bg-hover};
    --button-outline-border-color: #{$button-outline-border-color};
    --button-outline-border-color-hover: #{$button-outline-border-hover-color};

    // Outline button in light row theme
    --button-outline-light-text-color: #{$button-outline-light-text-color};
    --button-outline-light-text-color-hover: #{$button-outline-light-text-hover-color};
    --button-outline-light-bg: #{$button-outline-light-bg};
    --button-outline-light-bg-hover: #{$button-outline-light-bg-hover};
    --button-outline-light-border-color: #{$button-outline-light-border-color};
    --button-outline-light-border-color-hover: #{$button-outline-light-border-hover-color};

    // Outline button in dark row theme
    --button-outline-dark-text-color: #{$button-outline-dark-text-color};
    --button-outline-dark-text-color-hover: #{$button-outline-dark-text-hover-color};
    --button-outline-dark-bg: #{$button-outline-dark-bg};
    --button-outline-dark-bg-hover: #{$button-outline-dark-bg-hover};
    --button-outline-dark-border-color: #{$button-outline-dark-border-color};
    --button-outline-dark-border-color-hover: #{$button-outline-dark-border-hover-color};

    // Fonts (_typopgraphy.scss)
    --font-size-mobile-menu: #{$font-size-mobile-menu};
    --font-size-icon-tag: #{$font-size-icon-tag};
    --font-size-tiny: #{$font-size-tiny};
    --font-size-x-small: #{$font-size-x-small};
    --font-size-small: #{$font-size-small};
    --font-size-sub: #{$font-size-sub};
    --font-size-base: #{$font-size-base};
    --font-size-navigation: #{$font-size-navigation};
    --font-size-navigation-small: #{$font-size-navigation-small};
    --font-size-search: #{$font-size-search};
    --font-size-medium: #{$font-size-medium};
    --font-size-large: #{$font-size-large};
    --font-size-large-mobile: #{$font-size-large-mobile};
    --font-size-larger: #{$font-size-larger};
    --font-size-largest-mobile: #{$font-size-largest-mobile};
    --font-size-largest: #{$font-size-largest};
    --font-size-huge: #{$font-size-huge};

    --font-weight-light: #{$font-weight-light};
    --font-weight-base: #{$font-weight-base};
    --font-weight-medium: #{$font-weight-medium};
    --font-weight-secondary: #{$font-weight-secondary};
    --font-weight-bold: #{$font-weight-bold};

    --heading-primary-font-family: #{map-get($font-families, $primary-heading-family-key)};
    --heading-primary-letter-spacing: #{map-get($letter-spacings, $primary-heading-letter-space-key)};
    --heading-primary-text-color: #{$primary-heading-color};
    --heading-primary-text-transform: #{$primary-heading-text-transform};

    --letter-spacing-negative-large: #{$letter-spacing-negative-large};
    --letter-spacing-base: #{$letter-spacing-base};
    --letter-spacing-x-small: #{$letter-spacing-x-small};
    --letter-spacing-small: #{$letter-spacing-small};
    --letter-spacing-medium: #{$letter-spacing-medium};
    --letter-spacing-large: #{$letter-spacing-large};
    --letter-spacing-x-large: #{$letter-spacing-x-large};
    --letter-spacing-huge: #{$letter-spacing-huge};

    --line-height-normal: #{$line-height-normal};
    --line-height-tiny: #{$line-height-tiny};
    --line-height-small: #{$line-height-small};
    --line-height-secondary: #{$line-height-secondary};
    --line-height-base: #{$line-height-base};

    --font-family-base: #{$font-family-base};
    --font-family-base-medium: #{$font-family-base-medium};
    --font-family-base-bold: #{$font-family-base-bold};
    --font-family-secondary: #{$font-family-secondary};
    --font-family-secondary-medium: #{$font-family-secondary-medium};

    --link-primary-color: #{$link-default-color};
    --link-primary-color-hover: #{$link-default-color-hover};

    --ratio-16-9: 56.25%;

    --theme-default-bg: #{$default-theme-bg};
    --theme-default-color: #{$default-theme-text};
    --theme-dark-bg: #{$dark-theme-bg};
    --theme-dark-color: #{$dark-theme-text};
    --theme-light-bg: #{$light-theme-bg};
    --theme-light-color: #{$light-theme-text};

    --theme-default-rte-heading-color: #{$rte-default-theme-text};
    --theme-dark-rte-heading-color: #{$rte-dark-theme-text};
    --theme-light-rte-heading-color: #{$rte-light-theme-text};

    --minimal-header-background-color: #{$minimal-header-background-color};
    --minimal-footer-background-color: #{$minimal-footer-background-color};

    /****** GUTTER *******/
    --gutter: #{$gutter};
    --gutter-small: #{$gutter-small};

    /****** NAVIGATION *******/
    --mega-menu-padding-top: #{$mega-menu-padding-top};
    --mega-menu-padding-bottom: #{$mega-menu-padding-bottom};
    --mega-menu-link-color: #{$mega-menu-link-color};
    --mega-menu-link-color-hover: #{$mega-menu-link-color-hover};
    --mega-menu-bg-color: #{$mega-menu-bg-color};
    --mega-menu-link-vertical-padding: #{$mega-menu-link-vertical-padding};
    --mega-menu-link-vertical-padding-large: #{$mega-menu-link-vertical-padding-large};
    --mega-menu-link-horizontal-padding: #{$mega-menu-link-horizontal-padding};
    --mega-menu-link-horizontal-padding-large: #{$mega-menu-link-horizontal-padding-large};

    --top-menu-background-color: #{$top-menu-background-color};
    --top-menu-text-color: #{$top-menu-text-color};
    --top-menu-border-color: #{$top-menu-border-color};

    /****** CONFIGURATOR *******/
    --configurator-tab-color: #{$configurator-tab-color};
    --configurator-tab-color-active: #{$configurator-tab-color-active};
    --configurator-accent-color: #{$configurator-accent-color};
    --configurator-headline-size: #{$configurator-headline-size};
    --configurator-headline-weight: #{$configurator-headline-weight};
    --configurator-small-text-weight-bold: #{$configurator-small-text-weight-bold};
    --configurator-label-font-size: #{$configurator-label-font-size};
    --configurator-header-height: #{$configurator-header-height};
    --configurator-header-height-mobile: #{$configurator-header-height-mobile};

    /****** SERVICE BOOKING *******/
    --service-booking-header-height: #{$service-booking-header-height};
    --service-booking-header-height-mobile: #{$service-booking-header-height-mobile};
    --service-booking-max-width: #{$service-booking-max-width};

    /****** CARD *******/
    --card-active-color: #{$card-active-color};
    --card-border-color: #{$card-border-color};

    /****** EASING *******/
    --smooth-out: #{$smooth-out};
    --smooth-in: #{$smooth-in};
    --smooth-in-out: #{$smooth-in-out};

    /****** DURATIONS *******/
    --duration-fast: #{$duration-fast};
    --duration-medium: #{$duration-medium};
    --duration-slow: #{$duration-slow};
    --duration-link: #{$duration-link};
    --duration-button: #{$duration-button};
    --duration-icons: #{$duration-icons};

    /****** Z-INDEXES *******/
    --index-header-header: #{$index-header};
    --index-header-body-overlay: #{$index-body-overlay};
    --index-header-page-transition: #{$index-page-transition};
}
