@mixin border($direction: 'all', $size: 1px, $color: $border, $style: solid) {
    @if $direction == 'all' {
        border: $size $color $style;
    } @else {
        border-#{$direction}: $size $color $style;
    }
}

.border {
    &-all {
        @include border;
    }

    &-top {
        @include border('top');
    }

    &-right {
        @include border('right');
    }

    &-bottom {
        @include border('bottom');
    }

    &-left {
        @include border('left');
    }
}
