$model-preview-border-color: $grey-light;
$model-preview-text-color-alt: $grey-dark;

.model-preview {
    border: 1px solid $model-preview-border-color;
    padding: 1.5rem 1.7rem;
    display: flex;

    &__figure {
        width: 20%;
        justify-content: center;

        > img {
            width: 100%;
            vertical-align: middle;
        }
    }

    &__header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 2.5rem;

        &--subtitle {
            color: $model-preview-text-color-alt;
        }
    }

    &--with-margin {
        margin-bottom: $gutter;
    }
}
