ncg-subnavigation-item {
    display: block;
    width: 50%;
    flex: 0 0 auto;
    padding: 1.5rem 1.5rem 3.5rem 1.5rem;

    @include touch {
        width: 100%;
        padding-left: 0;
        padding-right: 0;

        &:last-child {
            padding-bottom: 1.5rem;
        }
    }

    @include desktop {
        &:last-child,
        &:nth-last-child(2) {
            padding-bottom: 1.5rem;
        }
    }
}

.subnavigation {
    &__header {
        text-align: center;
        padding-top: 2rem;
    }

    &__grid {
        display: flex;
        flex-flow: row wrap;
        padding-top: 2rem;
    }

    &__item {
        a {
            text-decoration: none;
        }

        &--header {
            padding-top: 1.5rem;

            + .button {
                margin-top: 1rem;
            }
        }
    }
}
