$side-menu-label-color: $black !default;
$side-menu-label-size: 2.5rem !default;
$side-menu-link-active: $primary !default;
$side-menu-label-hover: $primary !default;
$side-menu-label-letter-key: huge !default;
$side-menu-family-key: medium !default;
$side-menu-has-border-top: false !default;

// sticky and load-wrap ensure that the side-menu loads in as expected (its parent div is too long to trigger the animation)
.sticky-wrap {
    height: 100%;
}

.load-wrap {
    height: 200px;
}

.side-menu {
    position: relative;
    max-width: 25.5rem;
    box-shadow: 0 0 6rem 0 rgba($black, 0.19);
    background-color: $white;
    z-index: 9;

    @include when-inside('.has-banner') {
        transform: translateY(-10.5rem);
    }

    .menu-label {
        font-size: $side-menu-label-size;
        font-family: map-get($font-families, $side-menu-family-key);
        color: $side-menu-label-color;
        letter-spacing: map-get($letter-spacings, $side-menu-label-letter-key);
        text-transform: none;
        margin-bottom: 1rem;

        a {
            padding: 3rem 2.5rem 1rem 3.6rem;
            display: block;
            text-decoration: none;
            color: $side-menu-label-color;
            border-left: 4px solid transparent;

            &:hover {
                color: $side-menu-label-hover;
            }

            &.is-active {
                border-left: 4px solid $primary;
            }
        }
    }

    .menu-list {
        padding-right: 2.5rem;

        li {
            border-bottom: 1px solid $border;

            @if $side-menu-has-border-top {
                &:first-child {
                    border-top: 1px solid $border;
                }
            }

            &:last-child {
                border-bottom: 0;
                padding-bottom: 2.5rem;
            }

            a {
                @include typeset(navigation, base, normal);
                text-decoration: none;
                padding: 1.8rem 1rem 1.8rem 3.4rem;
                border-left: 4px solid transparent;
                transition: color $duration-link ease-in-out;
                letter-spacing: map-get($letter-spacings, large);

                &:hover {
                    color: $side-menu-link-active;
                }

                &.is-active {
                    background-color: transparent;
                    border-left: 4px solid $primary;
                    color: $side-menu-link-active;
                }
            }
        }
    }
}
