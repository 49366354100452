.exterior-view {
    &__container {
        position: relative;
        width: 100%;
        margin: 0 auto;
    }

    &__overlay {
        position: absolute;
        pointer-events: none;
        bottom: 6%;
        left: 0;
        right: 0;
        z-index: 1;
        text-align: center;

        > img {
            width: 84%;
            margin: 0 auto;
        }
    }

    &__text {
        position: absolute;
        bottom: 6%;
        left: 0;
        right: 0;
        text-align: center;
        font-size: map-get($font-sizes, larger);
        font-family: map-get($font-families, medium);
        color: #cbc6c5;
        line-height: 1;

        @include touch {
            font-size: map-get($font-sizes, base);
            bottom: 9%;
        }
    }

    &__item {
        position: relative;
        z-index: 2;
        max-width: 100%;
        bottom: 0;
        right: 0;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: col-resize;
    }

    .spinner {
        color: $white;
        color: $black;
    }
}
