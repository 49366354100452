.featured-cars,
.product-related {
    padding-top: 2rem;

    @include touch {
        padding-top: 0;
    }

    .product-list {
        min-height: auto;
    }
}
