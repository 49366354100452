$mobile-nav-bg: $white !default;
$mobile-header-height: 6.6rem !default;
$mobile-header-spacing-top: 1.9rem !default;
$mobile-header-spacing-bottom: 0.8rem !default;
$mobile-nav-item-padding-left: $gutter !default;
$mobile-nav-item-padding-right: $gutter !default;
$mobile-nav-item-padding-top: 1.6rem !default;
$mobile-nav-item-padding-bottom: 1.6rem !default;
$mobile-nav-item-bg: $white !default;
$mobile-nav-item-bg-hover: $primary !default;
$mobile-nav-item-color-hover: $white !default;
$mobile-nav-item-size-key: navigation !default;
$mobile-nav-item-weight-key: base !default;
$mobile-nav-item-text-transform: none !default;
$mobile-nav-item-space-key: base none !default;
$mobile-menu-item-back-padding: $gutter !default;
$mobile-nav-item-min-height: auto !default;
$mobile-menu-text-color: $primary !default;
$mobile-menu-link-color: $text !default;
$mobile-menu-goto-color: $text !default;
$mobile-menu-goto-size-key: navigation !default;
$mobile-menu-goto-family-key: medium !default;
$mobile-menu-goto-padding-bottom: $mobile-nav-item-padding-bottom !default;
$mobile-menu-is-goto-centered: false !default;
$mobile-menu-title-size-key: large !default;
$mobile-menu-title-family-key: medium !default;
$mobile-menu-title-line-height-key: normal !default;
$mobile-menu-title-font-weight-key: base !default;
$mobile-menu-title-letter-spacing-key: huge !default;
$mobile-menu-title-color: $primary !default;
$mobile-menu-title-transform: none !default;
$mobile-menu-title-display: block !default;
$mobile-holder-horizontal-padding: 0 !default;
$mobile-chevron-x-cord: 0 !default;
$mobile-chevron-y-cord: -1px !default;
$mobile-breadcrumb-margin-left: 0 !default;
$mobile-breadcrumb-padding-top: 0.6rem !default;
$mobile-nav-back-bg: $very-light-pink !default;
$mobile-nav-back-border-top: $grey-lighter !default;
$mobile-nav-back-border-bottom: $grey-lighter !default;
$mobile-nav-back-size-key: navigation !default;
$mobile-nav-back-family-key: base !default;
$mobile-nav-back-color: $text !default;
$mobile-nav-back-bottom: $mobile-nav-item-padding-bottom !default;
$mobile-links-color: $grey !default;
$mobile-links-horizontal-padding: 3rem !default;
$mobile-links-vertical-padding: 2rem !default;
$mobile-links-icon-padding: 2rem !default;
$mobile-links-item-padding-bottom: 0 !default;
$mobile-links-item-weight-key: base !default;
$mobile-links-item-size-key: sub !default;
$mobile-menu-weight-key: normal !default;
$mobile-close-icon-color: $primary !default;
$mobile-chevron-icon-color: $primary !default;
$mobile-chevron-back-icon-color: $grey !default;
$mobile-search-background: $input-background-color !default;
$mobile-search-border: $primary !default;
$mobile-search-color: $input-placeholder-color !default;
$mobile-search-icon-color: $black !default;

$mobile-model-text-size-key: sub !default;
$mobile-model-price-color: $grey !default;
$mobile-model-space: 0.4rem !default;
$mobile-model-weight: normal !default;
$mobile-model-size-key: large !default;
$mobile-model-family-key: medium !default;
$mobile-model-line-key: secondary !default;

.mobile-menu {
    position: relative;
    display: block;
    height: 100%;
    padding: 0;
    width: 100vw;
    max-width: 100%;

    &__header {
        &.columns {
            padding-left: 3rem;
            padding-right: 3rem;
            justify-content: space-between;

            @include until($desktop) {
                padding-top: $mobile-header-spacing-top;
                padding-bottom: $mobile-header-spacing-bottom;
                align-items: flex-end;
            }

            @include until($mobile-xs) {
                padding: 1rem;
            }
        }

        &--title {
            display: $mobile-menu-title-display;
            font-size: map-get($font-sizes, $mobile-menu-title-size-key);
            font-family: map-get($font-families, $mobile-menu-title-family-key);
            line-height: map-get($line-heights, $mobile-menu-title-line-height-key);
            font-weight: map-get($font-weights, $mobile-menu-title-font-weight-key);
            letter-spacing: map-get($letter-spacings, $mobile-menu-title-letter-spacing-key);
            color: $mobile-menu-title-color;
            text-transform: $mobile-menu-title-transform;
            padding-top: 3.2rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid $border;
        }

        &--level-back {
            padding: 0 $mobile-menu-item-back-padding;

            &.mobile-menu {
                &__item.back:first-child {
                    border-top: 0;
                }
            }
        }
    }

    &__close {
        position: relative;
        cursor: pointer;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        background: transparent;
        border: 0;
        padding: 0;

        &--icon {
            position: relative;
            width: 2.5rem !important;
            height: 2.5rem !important;

            &::before,
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                display: flex;
                align-items: center;
                width: 2.5rem;
                height: 0.2rem;
                transition: transform 400ms $smooth-in;
                background-color: $mobile-close-icon-color;
                transform-origin: center center;
            }

            &::before {
                transform: rotate(45deg);
            }

            &::after {
                transform: rotate(-45deg);
            }
        }

        &--text {
            font-size: map-get($font-sizes, mobileMenu);
            font-family: map-get($font-families, base);
            line-height: map-get($line-heights, small);
            color: $mobile-menu-text-color;
        }
    }

    &__title {
        @include typeset(mobileMenu, base, base, $mobile-menu-weight-key);
    }

    nav {
        position: relative;
        height: 100%;
        background: $mobile-nav-bg;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        .mobile-menu {
            &__holder {
                width: 100%;
                position: absolute;
                padding: 0 $mobile-holder-horizontal-padding;
            }

            &__title {
                width: 100%;
            }

            &__goto,
            &__item {
                display: block;
                width: 100%;
                cursor: pointer;
                transition: 0.4s padding;
                text-align: left;
                text-decoration: none;
                text-transform: $mobile-nav-item-text-transform;
                letter-spacing: map-get($letter-spacings, $mobile-nav-item-space-key);
                padding: $mobile-nav-item-padding-top $mobile-nav-item-padding-right $mobile-nav-item-padding-bottom $mobile-nav-item-padding-left;
                border-bottom: 1px solid $border;
                @include typeset($mobile-nav-item-size-key, base, base, $mobile-nav-item-weight-key);
                background-color: $mobile-nav-item-bg;
                color: $mobile-menu-link-color;
                min-height: $mobile-nav-item-min-height;
            }

            &__goto {
                font-family: map-get($font-families, $mobile-menu-goto-family-key);
                font-size: map-get($font-sizes, $mobile-menu-goto-size-key);
                color: $mobile-menu-goto-color;
                padding-bottom: $mobile-menu-goto-padding-bottom;
                white-space: pre-wrap;

                @if $mobile-menu-is-goto-centered {
                    display: flex;
                    align-items: center;
                }
            }

            &__item {
                .chevron {
                    vertical-align: middle;
                    transform: translate3d($mobile-chevron-x-cord, $mobile-chevron-y-cord, 0);
                    line-height: 0;
                    color: $mobile-chevron-icon-color;
                }

                &.back {
                    display: flex;
                    align-items: center;
                    font-size: map-get($font-sizes, $mobile-nav-back-size-key);
                    font-family: map-get($font-families, $mobile-nav-back-family-key);
                    background-color: $mobile-nav-back-bg;
                    text-align: left;
                    border-bottom: 1px solid $mobile-nav-back-border-bottom;
                    color: $mobile-nav-back-color;
                    padding-bottom: $mobile-nav-back-bottom;

                    &:hover {
                        background-color: $mobile-nav-back-bg;
                    }

                    &:first-child {
                        border-top: 1px solid $mobile-nav-back-border-top;
                    }
                }
            }

            &__back-icon {
                margin-right: 0.4rem;
                transform: translate3d(0, 0, 0);
                color: $mobile-chevron-back-icon-color;
            }

            &__search {
                @include typeset(sub, base, base, $mobile-nav-item-weight-key);
                color: $mobile-search-color;
                background: $mobile-search-background;
                width: 100%;
                border: 2px solid $mobile-search-border;
                padding-right: 1rem;

                &-icon {
                    color: $mobile-search-icon-color;
                }
            }

            &__search-wrapper {
                border-bottom: 0;
                margin: 2rem 0;
            }

            &__breadcrumb {
                margin-left: -1.2rem;
                padding-top: $mobile-breadcrumb-padding-top;
            }
        }
    }

    &__icon {
        color: $mobile-chevron-icon-color;
    }
}

.mobile-model {
    &__title {
        @include typeset($mobile-model-size-key, $mobile-model-family-key, $mobile-model-line-key, $mobile-model-weight);
        color: $black;
        line-height: 1.1;
        margin-bottom: $mobile-model-space;
    }

    &__text {
        color: $mobile-model-price-color;
        font-size: map-get($font-sizes, $mobile-model-text-size-key);
        line-height: 1;
        margin-bottom: 0;
    }

    &__image {
        display: flex;
        align-self: center;
        width: 9rem;
        margin-left: -1rem;
        margin-right: 1rem;
    }
}

.mobile-links {
    padding: $mobile-links-vertical-padding $mobile-links-horizontal-padding;

    &__item {
        padding-bottom: $mobile-links-item-padding-bottom;

        a {
            display: flex;
            align-items: center;
            padding: 0;
            font-family: map-get($font-families, base);
            font-size: map-get($font-sizes, $mobile-links-item-size-key);
            font-weight: map-get($font-weights, $mobile-links-item-weight-key);
            line-height: 2.4;
            letter-spacing: map-get($letter-spacings, large);
            color: $mobile-links-color;
            text-decoration: none;

            :hover {
                color: $mobile-links-color;
            }

            .chevron {
                padding-right: $mobile-links-icon-padding;
            }
        }
    }
}

.shortcut {
    &__icon {
        display: flex;
        padding-right: $mobile-links-icon-padding;
    }
}

.mobile-icon {
    line-height: 1;
}
