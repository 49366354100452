$tabs-toggle-link-radius: 0;
$size-normal: 1.5rem;
$tabs-link-hover-color: $text !default;
$tabs-toggle-link-border-color: $grey !default;
$tabs-toggle-link-active-background-color: $white !default;
$tabs-toggle-link-active-border-color: $white !default;
$tabs-toggle-link-active-color: $text !default;
$filter-items-bg: $white !default;

// CUSTOM VARIABLE
$filter-items-tabs-bg: $grey-lighter !default;

@import 'bulma/sass/components/tabs';

.tabs {
    &.is-toggle {
        li + li {
            margin-left: 3px;
        }

        a {
            background-color: $grey;
            text-decoration: none;

            &:focus,
            &:hover {
                background-color: $white;
                border-color: $light-theme-bg;
                border-bottom-color: $white;
            }
        }

        li {
            &.is-active {
                > a {
                    border-color: $light-theme-bg;
                    border-bottom-color: $white;
                }
            }
        }
    }

    // CUSTOM MODIFIER
    &.is-filter {
        > ul {
            background-color: transparent;
        }

        a {
            @extend %link-bg-hover;
            min-width: 16rem;
            min-height: 5.8rem;

            @include touch {
                min-width: 10rem;
            }
        }
    }

    &.is-filter-mobile {
        padding-bottom: 2rem;

        @include touch {
            li {
                position: relative;
                cursor: pointer;
                flex: 1;

                + li {
                    margin-left: 1px;
                }

                &.is-active {
                    a {
                        box-shadow: inset 0 -0.4rem 0 0 $primary;
                    }
                }

                a {
                    transition: box-shadow $duration-fast $smooth-in-out;
                    box-shadow: inset 0 0 0 0 transparent;
                    background-color: $white;
                    text-decoration: none;
                    border-top: 0;
                    min-height: 5.8rem;
                    border-width: 0;
                    padding: 0;

                    &:focus,
                    &:hover {
                        background-color: $white;
                        box-shadow: inset 0 -0.4rem 0 0 $primary;
                    }

                    img {
                        max-height: 3rem;
                    }
                }
            }
        }
    }

    // CUSTOM BLOCKS
    &__amount {
        position: absolute;
        top: 0.8rem;
        right: 0.7rem;
        font-size: 1.1rem;
        line-height: 1;
        color: $grey-dark;
        font-family: map-get($font-families, medium);
    }

    &__content {
        background-color: $filter-items-bg;
        box-shadow: 0 0 0 1px $light-theme-bg;
        min-height: 21rem;
        padding: $gutter $gutter 1rem $gutter;

        @include desktop {
            padding: 2.6rem $gutter $gutter $gutter;
        }
    }
}

// BOOTSTRAP CLASSES
.tab-pane {
    display: none;

    &.active {
        display: block;
    }
}
