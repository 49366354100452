$product-search-spacing: auto auto $gutter auto !default;
$product-search-input-bg: $input-background-color !default;
$product-search-spacing-desktop: calc($gutter * 0.5) auto !default;
$product-search-input-bg-desktop: $input-background-color !default;

.product-search {
    .field {
        margin: $product-search-spacing;

        @include desktop {
            margin: $product-search-spacing-desktop;
        }

        .input {
            background-color: $product-search-input-bg;

            @include desktop {
                background-color: $product-search-input-bg-desktop;
            }

            + button {
                background-color: transparent;
            }
        }
    }
}
