$carousel-slide-bg: $white !default;
$carousel-button-bg: $white !default;
$carousel-button-color-primary: $black !default;
$carousel-button-color-secondary: $primary !default;
$carousel-button-color-tertiary: $black !default;
$carousel-button-bg-tertiary: #f6f6f6 !default;
$carousel-button-color-tertiary-border: #f2f2f2 !default;
$carousel-button-size-base: 5.8rem !default;
$carousel-button-size-small: 4.4rem !default;
$carousel-bullet-bg: $white !default;
$carousel-more-color: $white !default;
$carousel-more-bg: $black !default;
$carousel-more-size-key: medium !default;

$swiper-button-tight-space: 2rem !default;
$swiper-button-tight-prev: 1.6% !default;
$swiper-button-tight-next: 2rem !default;

$container-offset: (2 * $gap) !default;
$container-max-width: $fullhd !default;

:root {
    --swiper-theme-color: #{$primary};
    --swiper-navigation-color: #{$carousel-button-color-primary};
    --swiper-navigation-size: #{$carousel-button-size-base};
}

.hero-slider-spot {
    @include is-full;

    user-select: none;
    overflow: hidden;

    &-spot {
        &__container {
            @include until($desktop) {
                padding-left: 1.5rem;
                padding-right: 1.5rem;
            }
        }
    }

    &__arrows-container {
        position: relative;
        z-index: $index-body-overlay + 1;
    }

    &__arrows {
        @include until($desktop) {
            aspect-ratio: 16 / 9;
            position: absolute;
            width: calc(95% - 3rem);
            pointer-events: none;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__overlay {
        overflow: hidden;
        height: 100%;
    }

    &__swiper {
        overflow: visible;
    }

    &__swiper-wrapper {
        .swiper-slide {
            background-color: transparent;
            width: 50%;

            @include desktop {
                width: 100%;
                max-width: 100%;
            }

            @include widescreen {
                max-width: min($widescreen, $container-max-width) - $container-offset;
            }

            @include fullhd {
                max-width: min($fullhd, $container-max-width) - $container-offset;
            }
        }
    }

    &__swiper-button {
        position: absolute;
        z-index: 10;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &-prev {
            left: 1rem;
        }

        &-next {
            right: 1rem;
        }

        @media (max-width: 768px) {
            top: 50%;

            &-prev,
            &-next {
                --swiper-navigation-size: #{$carousel-button-size-small};
            }

            &-prev {
                left: 0.5rem;
            }

            &-next {
                right: 0.5rem;
            }
        }
    }
    // this overrides the default swiper-button styling
    .swiper-button-prev,
    .swiper-button-next {
        @media (max-width: 920px) {
            --swiper-navigation-size: #{$carousel-button-size-small};
            top: unset;
            bottom: 1rem;
        }
    }

    &__carousel-nav {
        &.legal-text-moved {
            @media (max-width: 768px) {
                position: relative;
                bottom: 150px;
            }
        }

        &.legal-text-moved-desktop {
            @include tablet {
                position: relative;
                bottom: 60px;
            }
            @include desktop {
                bottom: 150px;
            }
        }

        @include desktop {
            position: absolute;
            display: flex;
            left: 0;
            right: 0;
            bottom: 2rem;
            height: var(--swiper-navigation-size);
            margin: 0 auto;
            justify-content: center;
            align-items: center;
            z-index: 10; // Ensure navigation is on top

            > * {
                position: relative;
                top: 0;
                left: 0;
                right: 0;
                margin-top: 0;
            }
        }

        .swiper-button-disabled {
            display: flex;
            opacity: 0;
        }
    }

    &__pagination {
        display: block;
        position: absolute;
        z-index: 99;
        list-style: none;

        @include desktop {
            position: relative;
            min-width: 100px;
        }

        .swiper-pagination-bullet {
            width: 15px;
            height: 15px;
            background-color: #{$carousel-bullet-bg};
            opacity: 0.5;
            border-radius: 50%;
            margin: 0 10px;
            cursor: pointer;
            transition:
                background-color 0.3s,
                opacity 0.3s;
            padding: 0;
        }

        .swiper-pagination-bullet-active {
            background-color: #{$carousel-bullet-bg};
            opacity: 1;
        }

        .swiper-pagination-bullet::before {
            display: none;
        }
    }
}
