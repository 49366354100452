$timeline-thumb-bar-bg-default: $primary !default;
$timeline-thumb-bar-bg-after-default: $grey-dark !default;
$timeline-thumb-bar-bg-dark: $white !default;
$timeline-thumb-active-color: $primary !default;
$timeline-thumb-label-color-default: #9f9f9f !default;
$timeline-thumb-label-active-color-default: $primary !default;
$timeline-thumb-label-color-dark: $white !default;
$timeline-thumb-label-active-color-dark: $white !default;
$timeline-item-label-color-default: $white !default;
$timeline-item-label-color-dark: $primary !default;
$timeline-item-bg-default: $primary !default;
$timeline-item-bg-dark: $white !default;
$timeline-item-color-default: $white !default;
$timeline-item-color-dark: $primary !default;
$timeline-item-color: $white !default;
$timeline-item-content-height-desktop: 41.4rem !default;
$timeline-item-content-height-mobile: 20rem !default;
$timeline-item-padding-vertical: 5rem !default;

.timeline {
    --timeline-bg: #{$timeline-item-bg-default};
    --timeline-color: #{$timeline-item-color-default};
    --timeline-item-height: 160px;
    --timeline-item-label-color: #{$timeline-item-label-color-default};
    --timeline-thumb-label-color: #{$timeline-thumb-label-color-default};
    --timeline-thumb-label-active-color: #{$timeline-thumb-label-active-color-default};
    --timeline-thumb-bar-bg: #{$timeline-thumb-bar-bg-default};

    padding: 3rem 0;

    @include desktop {
        --timeline-item-height: 264px;
    }

    @include when-inside('.grid__theme--dark') {
        --timeline-bg: #{$timeline-item-bg-dark};
        --timeline-color: #{$timeline-item-color-dark};
        --timeline-item-label-color: #{$timeline-item-label-color-dark};
        --timeline-thumb-label-color: #{$timeline-thumb-label-color-dark};
        --timeline-thumb-label-active-color: #{$timeline-thumb-label-active-color-dark};
        --timeline-thumb-bar-bg: #{$timeline-thumb-bar-bg-dark};
    }

    .swiper {
        @media (hover: hover) and (pointer: fine) {
            overflow: visible !important;
        }

        .swiper-wrapper {
            .swiper-slide {
                background-color: transparent;
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            display: none;

            @include desktop {
                display: block;
                top: 18.3rem;
            }
        }

        .swiper-button-prev {
            left: 0;
            transform: translateX(-50%);
        }

        .swiper-button-next {
            right: 0;
            transform: translateX(50%);
        }
    }
}

.timeline-thumbs {
    margin-left: -1.5rem;
    margin-right: -1.5rem;

    @media (hover: hover) and (pointer: fine) {
        margin-left: auto;
        margin-right: auto;
        width: 0.1rem;
    }

    padding-bottom: 3rem;

    &__item {
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0.4rem 1.1rem 0;
    }

    &__label {
        font-size: map-get($font-sizes, small);
        font-weight: map-get($font-weights, bold);
        transition:
            color $duration-fast $smooth-out,
            transform $duration-medium $smooth-out;
        transform: translate3d(0, 0, 0);
        transform-origin: bottom;
        color: var(--timeline-thumb-label-color);
    }

    &__bar {
        display: flex;
        height: 2.1rem;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;

        &::before {
            content: '';
            display: block;
            background-color: var(--timeline-thumb-bar-bg);
            width: 1px;
            height: 2.1rem;
            transition: transform $duration-medium $smooth-in-out;
            transform: scale3d(1, 0.6, 1);
            transform-origin: bottom;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 1px;
            background-color: var(--timeline-thumb-bar-bg);
        }
    }

    .timeline-thumbs-swiper {
        @media (hover: hover) and (pointer: fine) {
            width: 0.1rem;
        }

        .swiper-slide {
            width: auto;

            &:last-child,
            &:first-child {
                .timeline-thumbs {
                    &__bar {
                        &::after {
                            width: 50%;
                        }
                    }
                }
            }

            &:last-child {
                .timeline-thumbs {
                    &__bar {
                        &::after {
                            width: 50%;
                            left: 0;
                        }
                    }
                }
            }
        }

        .swiper-slide-thumb-active {
            .timeline-thumbs {
                &__label {
                    transform: translate3d(0, -5px, 0);
                    color: var(--timeline-thumb-label-active-color);
                }

                &__bar {
                    &::before {
                        transform: scale3d(1, 1, 1);
                        background-color: var(--timeline-thumb-label-active-color);
                    }
                }
            }
        }
    }
}

.timeline-item {
    display: grid;

    @include desktop {
        grid-template-columns: 49rem 1fr;
    }

    &__image {
        > picture {
            background-color: var(--timeline-bg);
        }
    }

    &__label {
        font-weight: map-get($font-weights, bold);
        font-size: map-get($font-sizes, small);
        color: var(--timeline-item-label-color);
    }

    &__content {
        background-color: var(--timeline-bg);
        color: var(--timeline-color);
        min-height: $timeline-item-content-height-mobile;
        padding: $timeline-item-padding-vertical $gutter;

        @include desktop {
            min-height: $timeline-item-content-height-desktop;
            padding: $timeline-item-padding-vertical 7rem;
        }

        .rte-content {
            color: var(--timeline-color);

            a {
                color: var(--timeline-color);
            }
        }

        .button.is-link {
            color: var(--timeline-color);
        }
    }

    &__inner {
        overflow: hidden;
    }
}
