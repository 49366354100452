$info-default-color: $orange !default;
$info-light-color: $orange !default;
$info-dark-color: $white !default;
$info-campaign-color: $moderate-red !default;
$info-label-size-key: tiny !default;
$info-size-key: small !default;
$info-family-key: base !default;
$info-letter-space-key: medium !default;
$info-text-transform: none !default;

.is-info {
    @include typeset($info-size-key, $info-family-key);
    letter-spacing: map-get($letter-spacings, $info-letter-space-key);
    display: flex;
    align-items: center;
    color: $info-default-color;

    @include when-inside('.grid__theme--default') {
        color: $info-default-color;
    }

    @include when-inside('.grid__theme--light') {
        color: $info-light-color;
    }

    @include when-inside('.grid__theme--dark') {
        color: $info-dark-color;

        &::before {
            background: url('/assets/icons/info-white.svg') no-repeat 50% 50%;
        }
    }

    // Used to support text-alignment from RTE
    &[style*='text-align: center;'] {
        display: flex;
        justify-content: center;
    }

    &::before {
        content: '';
        flex: 0 0 auto;
        align-self: flex-start;
        margin-right: 0.6rem;
        margin-top: 0.2rem;
        width: 1.4rem;
        height: 1.4rem;
        background: url('/assets/icons/info.svg') no-repeat 50% 50%;
    }
}

.info-label {
    @include typeset($info-label-size-key, medium, small);
    display: inline-block;
    padding: 0.4rem;
    background-color: $primary;
    color: $white;
    letter-spacing: 0;
    text-transform: $info-text-transform;
    border-radius: 0.2rem;

    @include when-inside('.grid__theme--light') {
        background-color: $primary;
        color: $white;
    }

    @include when-inside('.grid__theme--dark') {
        background-color: $white;
        color: $primary;
    }

    &-campaign {
        background-color: $info-campaign-color;
    }

    &:not(:last-of-type) {
        margin: 0 1rem 1rem 0;
    }
}
