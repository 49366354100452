/****** BMWTypeWeb Light *******/
@font-face {
    font-display: swap;
    font-family: 'BMWTypeNextLatin-Light';
    unicode-range: U+000-5FF;
    src:
        local('BMWTypeNextLatin-Light'),
        url('/assets/fonts/BMWTypeNextLatin-Light.woff2') format('woff2'),
        url('/assets/fonts/BMWTypeNextLatinTT-Light.woff') format('woff');
}

/****** BMWTypeWeb Bold *******/
@font-face {
    font-display: swap;
    font-family: 'BMWTypeNextLatin-Bold';
    unicode-range: U+000-5FF;
    src:
        local('BMWTypeNextLatin-Bold'),
        url('/assets/fonts/BMWTypeNextLatin-Bold.woff2') format('woff2'),
        url('/assets/fonts/BMWTypeNextLatinTT-Bold.woff') format('woff');
}
