// BULMA
$menu-item-color: $text !default;
$menu-item-radius: 0;
$menu-item-hover-color: $text-strong !default;
$menu-item-hover-background-color: transparent;
$menu-item-active-color: $link-invert;
$menu-item-active-background-color: $link;
$menu-list-border-left: none;
$menu-list-text-transform: none !default;

@import 'bulma/sass/components/menu';

.menu-list {
    a {
        transition-duration: $duration-link;
        transition-property: color;
        transition-timing-function: $smooth-in-out;
        text-transform: $menu-list-text-transform;
    }
}
