$custom-select-dropdown-height-touch: 32rem;
$custom-select-dropdown-height-desktop: 40rem;
$custom-select-item-bg-hover: lighten($grey-lighter, 3%) !default;
$custom-select-border-color: $primary !default;

.custom-select {
    .input {
        padding: $input-padding-vertical $input-padding-horizontal;
    }

    input.open {
        border: $control-border-width solid $custom-select-border-color;
    }

    .dropdown-menu {
        left: 0;
        right: 0;
        z-index: 9;
        border: $control-border-width solid $custom-select-border-color;
        overflow-x: hidden;
        overflow-y: scroll;
        background-color: $white;
        max-height: $custom-select-dropdown-height-touch;

        @include desktop {
            max-height: $custom-select-dropdown-height-desktop;
        }

        .dropdown-item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 1rem 2rem;
            background-color: $white;
            text-align: left;
            border-bottom: 1px solid $border;
            cursor: pointer;
            color: $text;

            @include touch {
                flex-direction: column;
            }

            @include when-inside('.newsletter') {
                flex-direction: column;
            }

            &:last-child {
                border-bottom: 0;
            }

            &.active {
                background-color: $custom-select-item-bg-hover;
            }
        }
    }

    .ngb-highlight {
        color: $primary;
        font-weight: normal;
    }
}

/* stylelint-disable */
#ngb-live {
    display: none;
}
/* stylelint-enable */
