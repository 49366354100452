.filter-facet {
    background-color: transparent;
    margin-top: 1.5rem;
    margin-bottom: 3rem;

    .columns.is-variable {
        .column {
            padding-bottom: var(--columnGap);
            padding-top: var(--columnGap);
        }
    }

    .filter-type-icons {
        &__text {
            transform: translateY(-7px);
            line-height: 1.2;
        }
    }

    &__image {
        transition: filter $duration-fast $smooth-in;

        &--normal {
            filter: grayscale(0);
        }

        &--grey {
            filter: grayscale(1);
        }
    }

    &__show-all {
        &.button {
            min-height: auto; // remove min-height inherited from button styles
            padding: $button-border-padding;
            margin: negative($button-border-padding);
            margin-top: 0;
            margin-right: 0;
            margin-bottom: 0;
        }
    }

    .button {
        font-size: 1.4rem;
        line-height: 1.2;
    }
}
