$icon-tag-is-circle: false !default;
$icon-tag-is-left: false !default;
$icon-tag-weight: normal !default;
$icon-tag-family-key: bold !default;
$icon-tag-bg-color: $primary !default;
$icon-tag-text-color: $white !default;
$icon-tag-right: -1.8rem !default;
$icon-tag-top: 0 !default;
$icon-tag-border-radius-touch: 0.35rem !default;
$icon-tag-border-radius-desktop: 0.5rem !default;
$icon-font-size-touch-key: icon !default;
$icon-font-size-desktop-key: x-small !default;
$icon-chevron-size-small: 1.4rem !default;
$icon-chevron-size-large: 3rem !default;
$icon-cross-size-small: 1.5rem !default;
$icon-cross-size-large: 3rem !default;
$icon-default-size: 3rem !default;
$icon-facet-car-size-width: 8rem !default;
$icon-facet-car-size-height: 4.4rem !default;
$icon-tag-car-letter-spacing: inherit !default;
$icon-tag-car-padding-vertical: 0.4rem !default;
$icon-tag-car-padding-horizontal: 0.6rem !default;

svg-icon-sprite {
    display: inline-block;
    line-height: 1;
}

.chevron {
    &--animate {
        transition: transform $duration-icons ease-in-out;
        transform: rotate(0deg) translateY(0);
    }

    //     /* MODIFIERS */
    &--left {
        transform: rotate(90deg) translateY(0);
    }

    &--right {
        transform: rotate(-90deg) translateY(0);
    }

    &--up {
        transform: rotate(-180deg) translateY(0);
    }
}

.car-icon {
    position: relative;

    > svg {
        width: 2.7rem;
        height: 2.7rem;

        @include desktop {
            width: $icon-default-size;
            height: $icon-default-size;
        }
    }

    &--tag {
        position: absolute;
        z-index: $index-body-overlay;
        top: $icon-tag-top;
        padding: $icon-tag-car-padding-vertical $icon-tag-car-padding-horizontal;
        background-color: $icon-tag-bg-color;
        color: $icon-tag-text-color;
        font-family: map-get($font-families, $icon-tag-family-key);
        font-weight: $icon-tag-weight;
        font-size: map-get($font-sizes, $icon-font-size-touch-key);
        border-radius: $icon-tag-border-radius-touch;
        letter-spacing: $icon-tag-car-letter-spacing;

        @if $icon-tag-is-left {
            left: $icon-tag-right;
        } @else {
            right: $icon-tag-right;
        }

        @include desktop {
            font-size: map-get($font-sizes, $icon-font-size-desktop-key);
            border-radius: $icon-tag-border-radius-desktop;
            -webkit-font-smoothing: antialiased;
        }

        @if $icon-tag-is-circle {
            height: 2.2rem;
            width: 2.2rem;
            padding: 0;
            font-size: 1rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 1;
        }
    }
}
