$page-transition-dim: $background !default;
$page-transition-bg: $primary !default;
$page-transition-text: $white !default;
$page-transition-z-index: $index-page-transition !default;

.page-transition {
    pointer-events: none;

    &__overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: $page-transition-z-index;
        background-color: $page-transition-bg;
        color: $page-transition-text;
        overflow: hidden;

        &__content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
        }
    }
}
