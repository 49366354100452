$aside-header-subtitle-color: $grey-dark !default;
$aside-header-title-size: 5rem !default;
$aside-header-title-size-touch: 4rem !default;
$aside-header-title-size-small: 4rem !default;
$aside-header-title-margin-bottom: $primary-heading-margin-bottom !default;
$aside-header-side-panel-color: $black !default;

.aside-header {
    margin-bottom: $gutter-small;

    &__title {
        font-size: $aside-header-title-size-touch;
        word-break: break-word;
        margin-bottom: $aside-header-title-margin-bottom;

        @include desktop {
            font-size: $aside-header-title-size;
        }

        .icon {
            margin-right: -5px;

            svg-icon-sprite {
                vertical-align: middle;
            }

            svg {
                width: 58px;
                height: 58px;

                @include until($tablet) {
                    width: 52px;
                    height: 52px;
                }

                @include until($desktop) {
                    width: 54px;
                    height: 54px;
                }
            }
        }
    }

    &__subtitle {
        @include typeset(medium, medium, secondary);
        letter-spacing: map-get($letter-spacings, base);
        text-transform: none;
        padding-top: 1rem;
        color: $aside-header-subtitle-color;
    }

    &__side-panel-title {
        color: $aside-header-side-panel-color;
        font-size: 2rem;
    }

    &.is-small {
        &__title {
            font-size: $aside-header-title-size-small;
        }
    }

    @include tablet {
        margin-bottom: $gutter;
    }
}
