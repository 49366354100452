$dialog-header-color: $black;
$dialog-bg-color: $background;
$dialog-actions-bg-color: transparent;
$dialog-border-color: $border;
$dialog-padding-horizontal: 3rem;

$dialog-close-button-vertical-pos: 0.5rem;
$dialog-close-button-horizontal-pos: 1.5rem;
$dialog-close-button-size: 2rem;

.dialog {
    position: relative;
    background: $dialog-bg-color;
    min-width: 20vw;
    max-width: 80rem;
    max-height: 80vh;

    @include when-inside('.full_width') {
        max-width: initial;
        width: 100%;
    }

    @include when-inside('.impact-panel--full-height') {
        max-height: initial;
        height: 100%;
    }

    @include mobile {
        @include when-inside('.mobile_fullscreen') {
            max-width: initial;
            max-height: initial;
            width: 100%;
            height: 100%;
        }
    }

    display: flex;
    flex-direction: column;

    &--action-bar {
        max-width: 90vw;
        width: 600px;

        .dialog__actions button {
            display: none;
        }
    }

    &__header {
        position: relative;
        padding: 2rem 4rem 0.5rem $dialog-padding-horizontal;
        color: $dialog-header-color;

        &--less-padded {
            padding-left: 1rem;
        }
    }

    &__close {
        cursor: pointer;
        position: absolute;
        top: $dialog-close-button-vertical-pos;
        right: $dialog-close-button-horizontal-pos;

        width: $dialog-close-button-size;
        height: $dialog-close-button-size;
        padding: 0;

        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
    }

    &__content {
        @extend .rte-content;
        height: 100%;

        padding: 1rem $dialog-padding-horizontal;
        overflow: auto;

        @include when-inside('.no-padding') {
            padding: 0.5rem 0;
        }
    }

    &__actions {
        flex: 1 0 auto;
        display: flex;
        flex-flow: wrap row;
        justify-content: flex-end;
        padding: 1rem 2rem;
        background: $dialog-actions-bg-color;

        > .button {
            margin-left: 1rem;
            margin-bottom: 1rem;
        }
    }

    &__mobile {
        overflow: hidden;
    }
}

.cdk-overlay-connected-position-bounding-box {
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    min-width: 1px;
    min-height: 1px;
}

/* stylelint-disable */
.cdk-overlay-pane {
    &.full_width {
        position: fixed !important;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }

    &.mobile_fullscreen {
        @include mobile {
            width: 100%;
            height: 100%;
        }
    }
}
/* stylelint-enable */
.cdk-overlay-backdrop {
    &.image-background {
        background: $dialog-bg-color;
        opacity: initial;
    }
}
