/****** BASE *******/
@import 'bulma/sass/helpers/all';
@import 'bulma/sass/base/animations';

html {
    font-size: 0.625em; // 0.625em = 10px
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
}

body {
    min-width: 300px;
    height: 100%;
    background-color: $background;
    -webkit-tap-highlight-color: transparent;
}

input,
select,
button {
    outline: none;
}

address {
    font-style: normal;
}

.site {
    &__wrapper {
        display: flex;
        flex-direction: column;
        min-height: 100vh;

        ncg-header {
            flex: 0 0 auto;
        }

        .content {
            &__wrapper {
                position: relative;
                flex: 1 0 auto;
                min-height: 100vh;
                z-index: $index-content;
                background-color: $background;

                &.is-compact {
                    flex: 0 0 auto;
                    min-height: auto;
                }
            }
        }

        ncg-footer {
            flex: 0 0 auto;
        }
    }

    &__sticky-top {
        z-index: $index-header;
        position: sticky;
        top: 0;
        right: 0;
        left: 0;
    }

    &__sticky-bottom {
        z-index: $index-footer;
        position: sticky;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

picture {
    z-index: 1;
    position: relative;
    display: block;
    line-height: 0;
}

.is-ratio-menu {
    @include aspect-ratio($ratio-menu);
}

.is-ratio-model {
    @include aspect-ratio($ratio-models);
}

.is-ratio-4-3 {
    @include aspect-ratio($ratio-4-3);
}

.is-ratio-16-7 {
    @include aspect-ratio($ratio-16-7);
}

.is-ratio-hd {
    @include aspect-ratio($ratio-16-9);
}

.is-ratio-cinema {
    @include aspect-ratio($ratio-21-9, $ratio-4-5);
}

.is-ratio-wide {
    @include aspect-ratio($ratio-31-9, $ratio-16-9);
}

.is-ratio-product-item {
    @include aspect-ratio($ratio-product-item, false, topleft, true);
}

.is-ratio-image-text-single {
    @include aspect-ratio($ratio-image-text-single);
}

.is-hidden-mobile-small {
    @include until($mobile-small) {
        display: none !important;
    }
}

.is-preline {
    white-space: pre-line;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    appearance: none;
}
