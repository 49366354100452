@use 'sass:math';

.product-item {
    $parent: &;

    &__list-details {
        flex-wrap: wrap;
        padding-right: $product-item-padding-touch;
        padding-left: $product-item-padding-touch;
        color: $product-item-details-color;
        font-size: map-get($font-sizes, $product-item-details-size-key);

        @include until($tablet) {
            @include when-inside('.is-list-view') {
                flex-wrap: nowrap;
                overflow: hidden;
                overflow-x: auto;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    width: 0;
                    height: 0;
                }
            }
        }

        @include desktop {
            padding-right: $product-item-padding-desktop;
            padding-left: $product-item-padding-desktop;
        }

        &.columns.is-variable {
            --columnGap: 0;

            @include desktop {
                --columnGap: #{$gutter};
            }
        }

        .column {
            padding: var(--columnGap);
            max-width: 100%;

            @include when-inside('.is-list-view') {
                max-width: none;
            }

            @include desktop {
                padding: 1rem;
            }
        }
    }

    &__list-detail {
        color: black;
        font-family: map-get($font-families, medium);

        &--key {
            display: none;
            font-family: map-get($font-families, base);
            font-size: map-get($font-sizes, x-small);
            text-transform: uppercase;

            @include desktop {
                display: inline;
            }
        }

        &--value {
            @include until($desktop) {
                @include when-inside('.is-list-view') {
                    line-height: 1.5;
                }

                &::after {
                    content: ', ';
                    display: inline-block;
                    width: 1rem;

                    #{$parent}__list-details > .column:last-child & {
                        display: none;
                    }
                }
            }
        }
    }
}
