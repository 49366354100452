.gallery-spot {
    padding-top: 2rem;

    &__item {
        &--image {
            width: 100%;
        }

        &--text {
            margin-top: 2rem;
        }
    }
}
