.configurator-models {
    $parent: &;
    margin-top: 6rem;
    @include touch {
        padding: 0 2rem;
    }
    @include desktop {
        padding: 0;
    }

    &__headline {
        color: var(--color-black);
        font-size: var(--font-size-large);
        font-family: var(--font-family-base-medium);
        letter-spacing: var(--letter-spacing-x-large);
        margin: 0;

        @include desktop {
            font-size: var(--font-size-larger);
        }
    }

    &__list-item {
        margin-bottom: 2rem;
        padding-top: 0;
    }

    &__vehicle {
        transition-property: transform, opacity;
        transition-duration: $duration-slow;
        transition-timing-function: $smooth-in-out;
        position: relative;
        opacity: 1;

        &-content {
            padding: 0 3.2rem;
        }

        &-name {
            color: $black;
            margin-bottom: 0;
            padding-bottom: 0.5rem;
            line-height: 1;
            font-size: map-get($font-sizes, large);

            @include tablet {
                font-size: map-get($font-sizes, $model-list-item-title-size);
            }
        }

        &-price {
            font-family: map-get($font-families, base);
            letter-spacing: map-get($letter-spacings, x-large);
            color: $model-list-item-price-color;
            padding-top: $model-list-item-price-padding;
            line-height: $line-height-base;
            font-size: map-get($font-sizes, sub);
            min-height: calc(#{$line-height-base} * #{map-get($font-sizes, sub)});

            @include tablet {
                font-size: map-get($font-sizes, $model-list-item-size);
                min-height: calc(#{$line-height-base} * #{map-get($font-sizes, $model-list-item-size)});
            }
        }

        &-link {
            text-decoration: none;
        }

        &--selected {
            transform: scale(1.05);
        }

        #{$parent}__list--active &:not(&--selected) {
            pointer-events: none;
            opacity: 0.4;
        }
    }
}
