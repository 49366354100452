/****** FONT FAMILY CONFIG *******/
$font-family-base: sans-serif !default;
$font-family-base-medium: sans-serif !default;
$font-family-base-bold: sans-serif !default;
$font-family-secondary: sans-serif !default;
$font-family-secondary-medium: sans-serif !default;
$font-families: (
    base: $font-family-base,
    medium: $font-family-base-medium,
    bold: $font-family-base-bold,
    secondary: $font-family-secondary,
    secondaryMedium: $font-family-secondary-medium,
    text: $font-family-secondary,
    navigation: $font-family-base,
);

/****** FONT SIZES CONFIG *******/
$font-size-mobile-menu: 1.1rem !default;
$font-size-icon-tag: 0.8rem !default;
$font-size-tiny: 1.1rem !default;
$font-size-x-small: 1.2rem !default;
$font-size-small: 1.4rem !default;
$font-size-sub: 1.5rem !default;
$font-size-base: 1.7rem !default;
$font-size-navigation: 1.7rem !default;
$font-size-navigation-small: 1.6rem !default;
$font-size-search: 1.8rem !default;
$font-size-medium: 1.9rem !default;
$font-size-large: 2rem !default;
$font-size-large-mobile: 2.5rem !default;
$font-size-larger: 2.8rem !default;
$font-size-largest-mobile: 3.5rem !default;
$font-size-largest: 6rem !default;
$font-size-huge: 8rem !default;
$font-sizes: (
    icon: $font-size-icon-tag,
    mobileMenu: $font-size-mobile-menu,
    tiny: $font-size-tiny,
    x-small: $font-size-x-small,
    small: $font-size-small,
    sub: $font-size-sub,
    base: $font-size-base,
    medium: $font-size-medium,
    large: $font-size-large,
    larger: $font-size-larger,
    largeMobile: $font-size-large-mobile,
    largestMobile: $font-size-largest-mobile,
    largest: $font-size-largest,
    huge: $font-size-huge,
    navigation: $font-size-navigation,
    navigationSmall: $font-size-navigation-small,
    search: $font-size-search,
);

/****** FONT WEIGHT CONFIG *******/
/* Remember to include the corresponding weights from the font family */
$font-weight-light: 300 !default;
$font-weight-base: normal !default;
$font-weight-medium: 500 !default;
$font-weight-secondary: 500 !default;
$font-weight-bold: bold !default;
$font-weights: (
    light: $font-weight-light,
    base: $font-weight-base,
    medium: $font-weight-medium,
    secondary: $font-weight-secondary,
    bold: $font-weight-bold,
);

/****** LINE HEIGHT CONFIG *******/
$line-height-tiny: 0.9 !default;
$line-height-normal: 1 !default;
$line-height-small: 1.1 !default;
$line-height-secondary: 1.2 !default;
$line-height-base: 1.5 !default;
$line-height-large: 2.1 !default;
$line-heights: (
    tiny: $line-height-tiny,
    normal: $line-height-normal,
    small: $line-height-small,
    base: $line-height-base,
    secondary: $line-height-secondary,
    large: $line-height-large,
);

/****** LETTER SPACING CONFIG *******/
$letter-spacing-negative-large: -0.05rem !default;
$letter-spacing-base: 0 !default;
$letter-spacing-x-small: 0.01rem !default;
$letter-spacing-small: 0.02rem !default;
$letter-spacing-medium: 0.04rem !default;
$letter-spacing-large: 0.05rem !default;
$letter-spacing-x-large: 0.06rem !default;
$letter-spacing-huge: 0.08rem !default;
$letter-spacings: (
    negative-large: $letter-spacing-negative-large,
    base: $letter-spacing-base,
    x-small: $letter-spacing-x-small,
    small: $letter-spacing-small,
    medium: $letter-spacing-medium,
    large: $letter-spacing-large,
    x-large: $letter-spacing-x-large,
    huge: $letter-spacing-huge,
);

$primary-heading-size-key: largest !default;
$primary-heading-family-key: medium !default;
$primary-heading-line-key: small !default;
$primary-heading-letter-space-key: large !default;
$primary-heading-weight-key: normal !default;
$primary-heading-text-transform: none !default;
$primary-heading-margin-bottom: 0.5rem !default;
$primary-heading-color: none !default;

$secondary-heading-weight: normal !default;
$secondary-heading-size-key: larger !default;
$secondary-heading-family-key: medium !default;
$secondary-heading-line-key: secondary !default;
$secondary-heading-text-transform: none !default;
$secondary-heading-letter-space-key: small !default;
$secondary-heading-mobile-size-key: largeMobile !default;

$tertiary-heading-weight: normal !default;
$tertiary-heading-size-key: large !default;
$tertiary-heading-family-key: medium !default;
$tertiary-heading-line-key: secondary !default;
$tertiary-heading-letter-space-key: small !default;
$tertiary-heading-mobile-size: base !default;
$tertiary-heading-mobile-family: medium !default;
$tertiary-heading-mobile-line-height: base !default;
$tertiary-heading-mobile-weight: normal !default;
$tertiary-heading-text-transform: none !default;

$small-heading-size-key: base !default;
$small-heading-family-key: bold !default;
$small-heading-line-key: secondary !default;

$sub-heading-size-key: sub !default;
$sub-heading-family-key: medium !default;

$tiny-heading-size-key: tiny !default;
$tiny-heading-family-key: bold !default;
$tiny-heading-line-key: base !default;
$tiny-heading-weight-key: normal !default;

$body-copy-family-key: secondary !default;
$body-copy-letter-space-key: large !default;
$body-copy-line-key: base !default;
$body-copy-weight-key: normal !default;

$body-copy-bold-size-key: base !default;
$body-copy-bold-family-key: secondaryMedium !default;
$body-copy-bold-weight: normal !default;
$body-copy-bold-color: inherit !default;

$body-copy-small-size-key: small !default;
$body-copy-small-family-key: secondary !default;
$body-copy-small-letter-space-key: medium !default;

/****** Function-specific vars ******/

// CONFIGURATOR
$configurator-headline-size: $font-size-large !default;
$configurator-headline-weight: $font-weight-base !default;
$configurator-small-text-weight-bold: $font-weight-bold !default;
$configurator-label-font-size: $font-size-large !default;

/****** Mixins ******/
@mixin typeset($size: base, $family: base, $line-height: base, $font-weight: normal) {
    font-size: map-get($font-sizes, $size);
    font-family: map-get($font-families, $family);
    font-style: normal;
    font-weight: $font-weight;
    line-height: map-get($line-heights, $line-height);
}

@mixin primary-heading {
    @include typeset($primary-heading-size-key, $primary-heading-family-key, $primary-heading-line-key, $primary-heading-weight-key);
    margin-bottom: $primary-heading-margin-bottom;
    letter-spacing: map-get($letter-spacings, $primary-heading-letter-space-key);
    text-transform: $primary-heading-text-transform;
    @if $primary-heading-color != none {
        color: $primary-heading-color;
    }
}

@mixin secondary-heading {
    @include typeset($secondary-heading-size-key, $secondary-heading-family-key, $secondary-heading-line-key, $secondary-heading-weight);
    margin-bottom: 0.5rem;
    letter-spacing: map-get($letter-spacings, $secondary-heading-letter-space-key);
    text-transform: $secondary-heading-text-transform;
}

@mixin secondary-heading-mobile {
    font-size: map-get($font-sizes, $secondary-heading-mobile-size-key);
}

@mixin tertiary-heading {
    @include typeset($tertiary-heading-size-key, $tertiary-heading-family-key, $tertiary-heading-line-key, $tertiary-heading-weight);
    letter-spacing: map-get($letter-spacings, $tertiary-heading-letter-space-key);
    text-transform: $tertiary-heading-text-transform;
}

@mixin small-heading {
    @include typeset($small-heading-size-key, $small-heading-family-key, $small-heading-line-key);
}

@mixin sub-heading {
    @include typeset($sub-heading-size-key, $sub-heading-family-key);
    letter-spacing: map-get($letter-spacings, x-small);
}

@mixin tiny-heading {
    @include typeset($tiny-heading-size-key, $tiny-heading-family-key, $tiny-heading-line-key, $tiny-heading-weight-key);
    margin-bottom: 0.5rem;
}

@mixin body-copy {
    @include typeset(base, $body-copy-family-key, $body-copy-line-key, $body-copy-weight-key);
    letter-spacing: map-get($letter-spacings, $body-copy-letter-space-key);

    @include touch {
        font-size: map-get($font-sizes, sub);
    }
}

@mixin body-copy-bold {
    @include typeset($body-copy-bold-size-key, $body-copy-bold-family-key, base, $body-copy-bold-weight);
    letter-spacing: map-get($letter-spacings, large);

    @include touch {
        font-size: map-get($font-sizes, sub);
    }
}

@mixin body-copy-small {
    @include typeset($body-copy-small-size-key, $body-copy-small-family-key);
    letter-spacing: map-get($letter-spacings, $body-copy-small-letter-space-key);
}

/****** Global typography styling ******/
body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: $font-smoothing;
    text-rendering: optimizeLegibility;
    text-size-adjust: none;
    @include body-copy;
    color: $text;
}

b,
strong {
    @include body-copy-bold;
    color: $body-copy-bold-color;
}

small {
    @include body-copy-small;
}

a {
    color: inherit;
    text-decoration: underline;
    outline: 0;

    &:hover,
    :focus,
    :visited {
        color: inherit;
        text-decoration: none;
    }

    &.is-text {
        text-decoration: none;
    }
}

figcaption {
    @include body-copy-small;
    font-size: 1.3rem;
}

.h1,
h1 {
    @include until($desktop) {
        @include secondary-heading;
    }

    @include from($desktop) {
        @include primary-heading;
    }
}

.h2,
h2 {
    @include secondary-heading;
}

.h3,
h3 {
    @include until($desktop) {
        @include typeset(
            $tertiary-heading-mobile-size,
            $tertiary-heading-mobile-family,
            $tertiary-heading-mobile-line-height,
            $tertiary-heading-mobile-weight
        );
    }

    @include from($desktop) {
        @include tertiary-heading;
    }
}

.h4,
h4 {
    @include small-heading;
}

.h5,
h5 {
    @include sub-heading;
}

p {
    margin-bottom: 1rem;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $color-divider;
    margin: 1em 0;
    padding: 0;
}

::placeholder {
    font-family: map-get($font-families, base);
    font-weight: map-get($font-weights, base);
    line-height: map-get($line-heights, base);
    font-size: map-get($font-sizes, sub);
}
