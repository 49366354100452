[ncgListItem] {
    --icon-url: '';
    --symbol-size: 0.6rem;
    --list-item-margin-l: 0.6rem;
    --list-item-margin-r: 1.1rem;

    display: flex;
    margin: 0.3rem 0;

    &::before {
        border-radius: 50%;
        content: '';
        flex-shrink: 0;
        display: block;
        margin: 0.5em var(--list-item-margin-r) auto var(--list-item-margin-l);
        background-color: var(--color-primary);
        color: var(--color-primary);
        width: var(--symbol-size);
        height: var(--symbol-size);
    }

    // Not the prettiest, but style is added when iconName is given. This works in both dev and prod angular builds, since other props gets ng-reflect-something
    &[style]:not([style='']) {
        --symbol-size: 2.4rem;
        margin: 0.3rem 0 0.6rem;

        &::before {
            margin: 0 var(--list-item-margin-r) auto var(--list-item-margin-l);
            // Just doing vendor prefix locally since its just here, not a global issue.
            /* stylelint-disable */
            -webkit-mask-image: var(--icon-url);
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-position: center;
            -webkit-mask-size: var(--symbol-size);
            /* stylelint-enable */
            mask-image: var(--icon-url);
            mask-repeat: no-repeat;
            mask-position: center;
            mask-size: var(--symbol-size);
            transform: translateY(2px); // Slightly better visual alignment, but it depends on the line-height.

            @supports (margin: 1lh) {
                transform: translateY(calc(((1lh - var(--symbol-size)) / 2 - 1px))); // subtract 1px because of ascender descender visual stuff
            }
        }
    }
}
