.select-car {
    .control {
        &.has-icons-right {
            .input {
                padding-left: calc(4.9rem + $input-padding-horizontal);
            }

            .icon.is-left {
                height: 4.4rem;
                left: $input-padding-horizontal;
                justify-content: flex-start;
            }
        }
    }

    .dropdown-menu {
        display: block;

        .dropdown-item {
            &.active {
                .select-car-list-item {
                    &__image {
                        background-color: $custom-select-item-bg-hover;
                    }
                }
            }
        }
    }

    .selected-car-item {
        display: flex;
        align-items: center;

        &__image {
            width: 6rem;
            height: 3.3rem;
            margin-right: 1rem;
            background-size: contain;
            background-blend-mode: multiply;
        }
    }

    &__selected-image {
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        background-size: contain;
        background-blend-mode: multiply;
        background-repeat: no-repeat;
        background-position: center center;
        @extend %input-bg-color;
        border: 0;
    }

    .select-car-list-item {
        display: flex;
        align-items: center;

        &__image {
            margin-right: 1rem;
            width: 10rem;
            height: 5.6rem;
            background-size: contain;
            background-blend-mode: multiply;
        }

        &__text {
            @extend %field-text;
        }
    }
}
