$model-list-item-title-size: $secondary-heading-size-key !default;
$model-list-item-price-padding: 0 !default;
$model-list-item-size: $tertiary-heading-size-key !default;
$model-list-item-price-color: $grey-dark !default;
$model-list-item-color-hover: $primary !default;

.models-list {
    &.columns {
        > .column {
            @include until($mobile-small) {
                width: 100%;
            }
        }
    }

    &__column.column {
        @include until($tablet) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &--with-slides {
        @include is-full;

        overflow: hidden;
    }

    &--swiper {
        overflow: visible;
    }

    &__item {
        display: block;
        padding-bottom: 4rem;
        text-decoration: none;
        text-align: center;

        @include until($mobile-small) {
            padding-bottom: 2rem;
        }

        @include when-inside('.grid__theme--default') {
            color: $default-title-color;
        }

        @include when-inside('.grid__theme--light') {
            color: $light-title-color;
        }

        @include when-inside('.grid__theme--dark') {
            color: $dark-title-color;
        }

        &:hover {
            @include when-inside('.grid__theme--default') {
                color: $model-list-item-color-hover;
            }

            @include when-inside('.grid__theme--light') {
                color: $primary;
            }

            @include when-inside('.grid__theme--dark') {
                color: $very-light-pink;
            }
        }
    }

    &__image {
        width: 100%;
        box-sizing: border-box;
        padding: 0 3rem;
    }

    &__header {
        position: relative;
        z-index: 2;
        text-decoration: none;

        &--tag {
            position: absolute;
            top: -3.6rem;
            left: 0;
            right: 0;
            text-align: center;

            @include until($mobile-small) {
                span {
                    margin-bottom: 0.8rem;
                }
            }

            @include from($mobile-small) {
                min-height: 3.6rem;
            }
        }

        &--title {
            padding-top: 0;
            margin-bottom: 0;
            padding-bottom: 0.5rem;
            line-height: 1;
            font-size: map-get($font-sizes, $model-list-item-title-size);

            @include mobile {
                font-size: 2rem;
            }
        }

        &--price {
            font-size: map-get($font-sizes, $model-list-item-size);
            font-family: map-get($font-families, base);
            letter-spacing: map-get($letter-spacings, x-large);
            color: $model-list-item-price-color;
            padding-top: $model-list-item-price-padding;

            @include when-inside('.grid__theme--dark') {
                color: $very-light-pink;
            }
            @include mobile {
                font-size: 1.5rem;
            }
        }
    }
}
