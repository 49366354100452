$image-animation-duration: 500ms;
$image-animation-duration-fast: 200ms;
$image-animation-easing: $smooth-in-out;
$image-scale-size: 1.2;
$image-scale-size-small: 1.12;
$image-scale-size-x-small: 1.04;

%link-bg-hover {
    transition-property: background-color;
    transition-duration: $duration-fast;
    transition-timing-function: $smooth-in-out;
}

%link-color-hover {
    transition-property: color;
    transition-duration: $duration-fast;
    transition-timing-function: $smooth-in-out;
}

.animate-load {
    position: relative;
    z-index: 50;
    transition: opacity, transform;
    transition-duration: $duration-slow, $duration-slow;
    transition-timing-function: $smooth-in-out;
    transform: translate3d(0, 0, 0);
    opacity: 1;

    &.animate-up {
        transform: translate3d(0, 20vh, 0);
    }

    &.animate-down {
        transform: translate3d(0, -90px, 0);
    }

    &.animate-left {
        transform: translate3d(-100%, 0, 0);
    }

    &.animate-right {
        transform: translate3d(100%, 0, 0);
    }

    &.animate-opacity {
        opacity: 0;
    }
}

// ADDS AN MASKING EFFECT TO THE IMAGE ON HOVER
.animate-mask {
    position: relative;
    display: block;
    overflow: hidden;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 3;
        background-color: transparent;
        transition-duration: $image-animation-duration;
        transition-property: box-shadow;
        transition-timing-function: $image-animation-easing;
        box-shadow: inset 0 0 0 0 $white;

        @include when-inside('.grid__theme--light') {
            box-shadow: inset 0 0 0 0 $light-theme-bg;
        }

        @include when-inside('.grid__theme--dark') {
            box-shadow: inset 0 0 0 0 $dark-theme-bg;
        }
    }

    > img {
        transition-duration: $image-animation-duration;
        transition-property: transform;
        transition-timing-function: $image-animation-easing;
        transform: scale(1);
    }

    &:hover {
        &::after {
            box-shadow: inset 0 0 0 16px $white;

            @include when-inside('.grid__theme--light') {
                box-shadow: inset 0 0 0 16px $light-theme-bg;
            }

            @include when-inside('.grid__theme--dark') {
                box-shadow: inset 0 0 0 16px $dark-theme-bg;
            }
        }

        &.is-small-mask {
            &::after {
                box-shadow: inset 0 0 0 8px $white;

                @include when-inside('.grid__theme--light') {
                    box-shadow: inset 0 0 0 8px $light-theme-bg;
                }

                @include when-inside('.grid__theme--dark') {
                    box-shadow: inset 0 0 0 8px $dark-theme-bg;
                }
            }
        }

        &.is-x-small-mask {
            &::after {
                box-shadow: inset 0 0 0 6px $white;

                @include when-inside('.grid__theme--light') {
                    box-shadow: inset 0 0 0 6px $light-theme-bg;
                }

                @include when-inside('.grid__theme--dark') {
                    box-shadow: inset 0 0 0 6px $dark-theme-bg;
                }
            }

            > img {
                transform: scale($image-scale-size-x-small);
            }
        }

        &:not(.animate-mask__link &) > img {
            transform: scale($image-scale-size-small);
        }
    }

    // E.g. use this class on a link tag if an nested image should have the hover effect on link hover.
    &__link {
        &:hover {
            .animate-mask {
                &::after {
                    box-shadow: inset 0 0 0 16px $white;

                    @include when-inside('.grid__theme--light') {
                        box-shadow: inset 0 0 0 16px $light-theme-bg;
                    }

                    @include when-inside('.grid__theme--dark') {
                        box-shadow: inset 0 0 0 16px $dark-theme-bg;
                    }
                }

                > img {
                    transform: scale($image-scale-size-small);
                }

                &.is-small-mask {
                    &::after {
                        box-shadow: inset 0 0 0 8px $white;

                        @include when-inside('.grid__theme--light') {
                            box-shadow: inset 0 0 0 8px $light-theme-bg;
                        }

                        @include when-inside('.grid__theme--dark') {
                            box-shadow: inset 0 0 0 8px $dark-theme-bg;
                        }
                    }
                }
            }
        }
    }
}

// ADDS AN SCALING EFFECT TO THE IMAGE ON HOVER
.animate-scale {
    position: relative;
    overflow: hidden;

    > img {
        transition-duration: $image-animation-duration;
        transition-property: transform;
        transition-timing-function: $image-animation-easing;
        transform: scale(1);
        transform-origin: center center;

        &:hover {
            transform: scale($image-scale-size);
        }
    }

    &.is-top-scale {
        > img {
            transform-origin: center top;
        }
    }

    &.is-small-scale {
        > img {
            &:hover {
                transform: scale($image-scale-size-small);
            }
        }
    }

    // E.g. use this class on a link tag if an nested image should have the hover effect on link hover.
    &__link {
        &:hover {
            .animate-scale {
                > img {
                    transform: scale($image-scale-size);
                }

                &.is-small-scale > img {
                    transform: scale($image-scale-size-small);
                }

                &.is-x-small-scale > img {
                    transform: scale($image-scale-size-x-small);
                }
            }
        }
    }
}

@keyframes fetchingAnimation {
    0% {
        transform: scaleX(0);
        transform-origin: left;
    }

    50% {
        transform-origin: left;
        transform: scaleX(1);
    }

    51% {
        transform-origin: right;
        transform: scaleX(1);
    }

    100% {
        transform-origin: right;
        transform: scaleX(0);
    }
}
