// Bulma buttons override - ~bulma/sass/elements/buttons.sass
$button-border-width: 1px !default;
$button-border-color: transparent !default;
$button-hover-border-color: transparent !default;
$button-focus-border-color: transparent !default;
$button-active-border-color: transparent !default;
$button-static-border-color: transparent !default;
$button-disabled-border-color: transparent !default;
$button-disabled-opacity: 0.6 !default;
$button-padding-horizontal: 2.5rem !default;
$button-padding-vertical: 0 !default;
$button-focus-box-shadow-size: 0 0 0 0 !default;
$button-focus-box-shadow-color: rgba($text, 0) !default;
// default buttons settings
$button-size-key: sub !default;
$button-family-key: medium !default;
$button-letter-space-key: x-small !default;
$button-line-height-key: base !default;
$button-height-large: 6rem !default;
$button-height-normal: 5rem !default;
$button-height-small: 3.8rem !default;
$button-text-transform: none !default;
$button-font-weight: normal !default;
$button-duration: 400ms !default;
$button-easing: $smooth-in-out !default;
$button-min-width: auto !default;
$button-border-radius: 0 !default;
$button-border-shadow-color: $grey-light !default;
$button-border-shadow-color-hover: $grey-light !default;
// button link
$button-link-text: $primary !default;
$button-link-text-hover: darken($primary, 20%) !default;
// Close button
$button-close-bg: $primary !default;
$button-close-color: $white !default;
$button-close-bg-hover: $blue-darker !default;
// Primary/FILLED button in default row theme
$button-primary-color: $white !default;
$button-primary-text-hover-color: $white !default;
$button-primary-bg: $primary !default;
$button-primary-hover-bg: $blue-darker !default;
$button-primary-active-bg: $blue-darker !default;
$button-primary-border-color: $primary !default;
$button-primary-border-hover-color: $blue-dark !default;
$button-primary-disabled-bg: $primary !default;
$button-primary-disabled-border-color: $primary !default;
$button-primary-disabled-text-color: $white !default;
// Filled button in dark row theme
$button-filled-dark-text-color: $primary !default;
$button-filled-dark-text-hover-color: $black !default;
$button-filled-dark-bg: $white !default;
$button-filled-dark-bg-hover: $grey-light !default;
$button-filled-dark-bg-active: $grey-light !default;
$button-filled-dark-border-color: $primary !default;
$button-filled-dark-border-hover-color: $primary !default;
$button-filled-dark-bg-disabled: $white !default;
$button-filled-dark-border-disabled-color: $white !default;
$button-filled-dark-text-disabled-color: $primary !default;
// Filled button in light row theme
$button-filled-light-text-color: $white !default;
$button-filled-light-text-hover-color: $white !default;
$button-filled-light-bg: $primary !default;
$button-filled-light-bg-hover: $blue-darker !default;
$button-filled-light-bg-active: $blue-darker !default;
$button-filled-light-border-color: $primary !default;
$button-filled-light-border-hover-color: $blue-darker !default;
$button-filled-light-bg-disabled: $button-primary-disabled-bg !default;
$button-filled-light-border-disabled-color: $button-primary-disabled-border-color !default;
$button-filled-light-text-disabled-color: $button-primary-disabled-text-color !default;
// Secondary button
$button-secondary-color: $primary !default;
$button-secondary-text-hover-color: $primary !default;
$button-secondary-bg: $white !default;
$button-secondary-hover-bg: $grey-light !default;
$button-secondary-active-bg: $grey-light !default;
$button-secondary-border-color: $white !default;
$button-secondary-border-hover-color: $grey-light !default;
$button-secondary-disabled-bg: transparent !default;
$button-secondary-disabled-border-color: $brownish-grey !default;
$button-secondary-disabled-text-color: $brownish-grey !default;
// button outline in default row theme
$button-outline-text-color: $text !default;
$button-outline-text-hover-color: $white !default;
$button-outline-bg: transparent !default;
$button-outline-bg-hover: $text !default;
$button-outline-bg-active: $text !default;
$button-outline-border-color: $text !default;
$button-outline-border-hover-color: $button-outline-bg-hover !default;
$button-outline-bg-disabled: $button-secondary-disabled-bg !default;
$button-outline-border-disabled-color: $button-secondary-disabled-border-color !default;
$button-outline-text-disabled-color: $button-secondary-disabled-text-color !default;
// Outline button in light row theme
$button-outline-light-text-color: $primary !default;
$button-outline-light-text-hover-color: $white !default;
$button-outline-light-bg: transparent !default;
$button-outline-light-bg-hover: $primary !default;
$button-outline-light-bg-active: $primary !default;
$button-outline-light-border-color: $primary !default;
$button-outline-light-border-hover-color: $primary !default;
$button-outline-light-bg-disabled: $button-secondary-disabled-bg !default;
$button-outline-light-border-disabled-color: $button-secondary-disabled-border-color !default;
$button-outline-light-text-disabled-color: $button-secondary-disabled-text-color !default;
// Outline button in dark row theme
$button-outline-dark-text-color: $white !default;
$button-outline-dark-text-hover-color: $primary !default;
$button-outline-dark-bg: transparent !default;
$button-outline-dark-bg-hover: $white !default;
$button-outline-dark-bg-active: $white !default;
$button-outline-dark-border-color: $white !default;
$button-outline-dark-border-hover-color: $white !default;
$button-outline-dark-bg-disabled: transparent !default;
$button-outline-dark-border-disabled-color: $white !default;
$button-outline-dark-text-disabled-color: $white !default;
// Underline button
$button-underlined-color: $black !default;
$button-underlined-bg: $primary !default;
$button-underlined-size: $font-size-navigation !default;
// Arrow button (RTE)
$button-arrow-length: 28px !default;
$button-arrow-thickness: 2px !default;
$button-arrow-head-size: 8px !default;
$button-arrow-text-space-between: 10px !default;
$button-arrow-duration: $duration-fast !default;
$button-arrow-travel-distance: 3px !default;
$button-arrow-easing: ease-in !default;
$button-arrow-color: $primary !default;
$button-arrow-color-inverse: $white !default;
// External button (RTE)
$button-external-gap: 0.5em !default;
$button-external-icon-size: 1.4em !default;
// Hero button
$button-hero-color: $button-primary-color !default;
$button-hero-bg: $button-primary-bg !default;
$button-hero-hover-bg: $button-primary-hover-bg !default;
$button-hero-active-bg: $button-primary-active-bg !default;
$button-hero-text-hover-color: $button-primary-text-hover-color !default;
$button-hero-border-color: $button-primary-border-color !default;
$button-hero-border-hover-color: $button-primary-border-hover-color !default;
$button-hero-disabled-bg: $button-primary-disabled-bg !default;
$button-hero-border-disabled-color: $button-primary-disabled-border-color !default;
$button-hero-text-disabled-color: $button-primary-disabled-text-color !default;
// Product sorting buttons
$button-product-sorting-color: $button-primary-color !default;
// Border
$button-border-padding: 0.8rem 1rem !default;
// FILTER
$button-filter-family-key: base !default;
$button-secondary-theme: 'outlined' !default;
// button filter
$button-filter-color: $brownish-grey !default;
$button-filter-color-active: $button-secondary-color !default;
// Extended hero button primary
$button-extended-hero-primary-color: $button-filled-dark-text-color !default;
$button-extended-hero-primary-color-hover: $button-filled-dark-text-hover-color !default;
$button-extended-hero-primary-bg: $button-filled-dark-bg !default;
$button-extended-hero-primary-bg-hover: $button-filled-dark-bg-hover !default;
$button-extended-hero-primary-bg-active: $button-filled-dark-bg-active !default;
$button-extended-hero-primary-border-color: $button-filled-dark-bg !default;
$button-extended-hero-primary-border-color-hover: $button-filled-dark-bg-hover !default;
$button-extended-hero-primary-bg-disabled: $button-filled-dark-bg-disabled !default;
$button-extended-hero-primary-border-disabled-color: $button-filled-dark-border-disabled-color !default;
$button-extended-hero-primary-text-disabled-color: $button-filled-dark-text-disabled-color !default;
// Extended hero button secondary
$button-extended-hero-secondary-color: $button-outline-dark-text-color !default;
$button-extended-hero-secondary-color-hover: $button-outline-dark-text-hover-color !default;
$button-extended-hero-secondary-bg: $button-outline-dark-bg !default;
$button-extended-hero-secondary-bg-hover: $button-outline-dark-bg-hover !default;
$button-extended-hero-secondary-bg-active: $button-outline-dark-bg-active !default;
$button-extended-hero-secondary-border-color: $button-outline-dark-border-color !default;
$button-extended-hero-secondary-border-color-hover: $button-outline-dark-border-hover-color !default;
$button-extended-hero-secondary-bg-disabled: $button-outline-dark-bg-disabled !default;
$button-extended-hero-secondary-border-disabled-color: $button-outline-dark-border-disabled-color !default;
$button-extended-hero-secondary-text-disabled-color: $button-outline-dark-text-disabled-color !default;

@import 'bulma/sass/elements/button.sass';

@mixin outline-button(
    $text-color: $button-outline-text-color,
    $text-hover-color: $button-outline-text-hover-color,
    $bg-color: $button-outline-bg,
    $bg-hover-color: $button-outline-bg-hover,
    $border-color: $button-outline-border-color,
    $border-hover-color: $button-outline-border-hover-color,
    $bg-active-color: $button-outline-bg-active,
    $bg-disabled-color: $bg-color,
    $border-disabled-color: $border-color,
    $text-disabled-color: $text-color
) {
    background-color: $bg-color;
    border-color: $border-color;
    color: $text-color;
    transition-property: color, background-color, border-color;
    transition-duration: $button-duration;
    transition-timing-function: $button-easing;
    border-radius: $button-border-radius;

    // override bulma's default disabled styles
    &[disabled] {
        background-color: $bg-disabled-color;
        border-color: $border-disabled-color;
        color: $text-disabled-color;
    }

    &:not([disabled]) {
        &::after {
            background-color: $bg-hover-color;
        }

        &:hover {
            color: $text-hover-color;
            background-color: $bg-hover-color;
            border-color: $border-hover-color;
        }

        &:active {
            background-color: $bg-active-color;
        }
    }
}

@mixin filled-button(
    $text-color: $primary,
    $solid-color: $white,
    $bg-hover-color: $grey-lighter,
    $text-hover-color: $text-color,
    $border-color: $solid-color,
    $border-hover-color: $bg-hover-color,
    $bg-active-color: $grey-lighter,
    $bg-disabled-color: $solid-color,
    $border-disabled-color: $border-color,
    $text-disabled-color: $text-color
) {
    background-color: $solid-color;
    border-color: $border-color;
    color: $text-color;
    transition-property: background-color, border-color, color;
    transition-duration: $button-duration;
    transition-timing-function: $button-easing;

    // override bulma's default disabled styles
    &[disabled] {
        background-color: $bg-disabled-color;
        border-color: $border-disabled-color;
        color: $text-disabled-color;
    }

    &:not([disabled]) {
        &:hover {
            background-color: $bg-hover-color;
            border-color: $border-hover-color;
            color: $text-hover-color;
        }

        &:active {
            background-color: $bg-active-color;
        }
    }
}

%is-clean {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    height: auto;
    min-width: auto;
    border-radius: 0;

    &:hover {
        background-color: transparent;
    }
}

%filled-theme {
    @include filled-button(
        $button-primary-color,
        $button-primary-bg,
        $button-primary-hover-bg,
        $button-primary-text-hover-color,
        $button-primary-border-color,
        $button-primary-border-hover-color,
        $button-primary-active-bg,
        $button-primary-disabled-bg,
        $button-primary-disabled-border-color,
        $button-primary-disabled-text-color
    );

    @include when-inside('.grid__theme--light') {
        @include filled-button(
            $button-filled-light-text-color,
            $button-filled-light-bg,
            $button-filled-light-bg-hover,
            $button-filled-light-text-hover-color,
            $button-filled-light-border-color,
            $button-filled-light-border-hover-color,
            $button-filled-light-bg-active,
            $button-filled-light-bg-disabled,
            $button-filled-light-border-disabled-color,
            $button-filled-light-text-disabled-color
        );
    }

    @include when-inside('.grid__theme--dark') {
        @include filled-button(
            $button-filled-dark-text-color,
            $button-filled-dark-bg,
            $button-filled-dark-bg-hover,
            $button-filled-dark-text-hover-color,
            $button-filled-dark-border-color,
            $button-filled-dark-border-hover-color,
            $button-filled-dark-bg-active,
            $button-filled-dark-bg-disabled,
            $button-filled-dark-border-disabled-color,
            $button-filled-dark-text-disabled-color
        );
    }
}

@mixin outline-theme {
    @include outline-button(
        $button-outline-text-color,
        $button-outline-text-hover-color,
        $button-outline-bg,
        $button-outline-bg-hover,
        $button-outline-border-color,
        $button-outline-border-hover-color,
        $button-outline-bg-active,
        $button-outline-bg-disabled,
        $button-outline-border-disabled-color,
        $button-outline-text-disabled-color
    );

    @include when-inside('.grid__theme--light', '.menu-spot') {
        @include outline-button(
            $button-outline-light-text-color,
            $button-outline-light-text-hover-color,
            $button-outline-light-bg,
            $button-outline-light-bg-hover,
            $button-outline-light-border-color,
            $button-outline-light-border-hover-color,
            $button-outline-light-bg-active,
            $button-outline-light-bg-disabled,
            $button-outline-light-border-disabled-color,
            $button-outline-light-text-disabled-color
        );
    }

    @include when-inside('.grid__theme--dark') {
        @include outline-button(
            $button-outline-dark-text-color,
            $button-outline-dark-text-hover-color,
            $button-outline-dark-bg,
            $button-outline-dark-bg-hover,
            $button-outline-dark-border-color,
            $button-outline-dark-border-hover-color,
            $button-outline-dark-bg-active,
            $button-outline-dark-bg-disabled,
            $button-outline-dark-border-disabled-color,
            $button-outline-dark-text-disabled-color
        );
    }
}

button {
    color: inherit;
    border: 0;
    padding: 0;
}

.buttons {
    .button:not(:last-child):not(.is-fullwidth) {
        margin-right: 1rem;
    }

    &-container {
        .map-link {
            &:not(:last-child) {
                margin-bottom: 1rem;
            }

            &--list-view {
                @include tablet-only {
                    width: unset !important;
                    margin-right: 1rem;
                }
            }
        }
    }
}

.button {
    @include typeset($button-size-key, $button-family-key, $button-line-height-key);
    border-radius: $button-border-radius;
    text-decoration: none;
    min-width: $button-min-width;
    min-height: $button-height-normal;
    height: auto;
    letter-spacing: map-get($letter-spacings, $button-letter-space-key);
    outline: 0;
    border-color: transparent;
    transition: background-color $duration-fast ease-in-out;
    text-transform: $button-text-transform;
    font-weight: $button-font-weight;
    white-space: pre-line;

    &.is-arrow {
        display: inline-flex;
        position: relative;
        padding: $button-padding-vertical $button-padding-horizontal $button-padding-vertical $button-arrow-length + $button-arrow-text-space-between;
        color: $button-arrow-color;
        background: transparent;
        transition: padding-left $button-arrow-duration $button-arrow-easing;

        &::before {
            content: '';
            width: $button-arrow-length;
            height: 0;
            border: solid $button-arrow-color;
            border-width: $button-arrow-thickness 0 0;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: $button-arrow-color;
            transition: width $button-arrow-duration $button-arrow-easing;
        }

        &::after {
            content: '';
            width: $button-arrow-head-size;
            height: $button-arrow-head-size;
            border: solid $button-arrow-color;
            border-width: $button-arrow-thickness $button-arrow-thickness 0 0;
            position: absolute;
            left: $button-arrow-length - $button-arrow-head-size;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);

            transition: left $button-arrow-duration $button-arrow-easing;
        }

        &:hover {
            color: $button-arrow-color;
            padding-left: $button-arrow-length + $button-arrow-text-space-between + $button-arrow-travel-distance;

            &::before {
                width: $button-arrow-length + $button-arrow-travel-distance;
            }

            &::after {
                left: $button-arrow-length - $button-arrow-head-size + $button-arrow-travel-distance;
            }
        }

        @include when-inside('.grid__theme--dark') {
            color: $button-arrow-color-inverse;

            &::before {
                border-color: $button-arrow-color-inverse;
            }

            &::after {
                border-color: $button-arrow-color-inverse;
            }

            &:hover {
                color: $button-arrow-color-inverse;
            }
        }
    }

    &.is-narrow {
        min-width: auto;
        height: auto;
    }

    &.is-small {
        min-height: $button-height-small;
        padding-right: 2rem;
        padding-left: 2rem;
        border-radius: 0;
        font-size: map-get($font-sizes, sub);
    }

    &.is-large {
        min-width: 19.6rem;
    }

    &.is-clean {
        @extend %is-clean;
    }

    &.is-round {
        border-radius: 50%;
    }

    .icon {
        width: 2em;
        height: 2em;
    }

    &.has-icon {
        display: flex;
        align-items: center;
    }

    &.is-active {
        color: $primary;
    }

    &.is-outline {
        @include outline-theme();
    }

    &.is-border {
        color: $black-bis;
        box-shadow: inset 0 0 0 1px $button-border-shadow-color;
        transition: box-shadow $duration-fast $smooth-in;
        min-height: $button-height-normal;
        height: auto;
        padding: $button-border-padding;
        white-space: normal;
        border-radius: 0;

        &:focus {
            box-shadow: inset 0 0 0 1px $button-border-shadow-color;
        }

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                box-shadow: inset 0 0 0 2px $button-border-shadow-color-hover;
            }
        }

        &--active {
            box-shadow: inset 0 0 0 2px $primary !important;
        }
    }

    &.is-secondary {
        @if $button-secondary-theme == 'outlined' {
            @include outline-theme();
        } @else {
            @extend %filled-theme;
        }
    }

    &.is-primary,
    &.is-filled {
        @extend %filled-theme;
    }

    &.is-hero {
        @include filled-button(
            $button-hero-color,
            $button-hero-bg,
            $button-hero-hover-bg,
            $button-hero-text-hover-color,
            $button-hero-border-color,
            $button-hero-border-hover-color,
            $button-hero-active-bg,
            $button-hero-disabled-bg,
            $button-hero-border-disabled-color,
            $button-hero-text-disabled-color
        );
    }

    &.is-extended-hero {
        @include filled-button(
            $button-extended-hero-primary-color,
            $button-extended-hero-primary-bg,
            $button-extended-hero-primary-bg-hover,
            $button-extended-hero-primary-color-hover,
            $button-extended-hero-primary-border-color,
            $button-extended-hero-primary-border-color-hover,
            $button-extended-hero-primary-bg-active,
            $button-extended-hero-primary-bg-disabled,
            $button-extended-hero-primary-border-disabled-color,
            $button-extended-hero-primary-text-disabled-color
        );
    }

    &.is-extended-hero-secondary {
        @include outline-button(
            $button-extended-hero-secondary-color,
            $button-extended-hero-secondary-color-hover,
            $button-extended-hero-secondary-bg,
            $button-extended-hero-secondary-bg-hover,
            $button-extended-hero-secondary-border-color,
            $button-extended-hero-secondary-border-color-hover,
            $button-extended-hero-secondary-bg-active,
            $button-extended-hero-secondary-bg-disabled,
            $button-extended-hero-secondary-border-disabled-color,
            $button-extended-hero-secondary-text-disabled-color
        );
    }

    &.is-product-sorting {
        @include filled-button(
            $button-product-sorting-color,
            $button-hero-bg,
            $button-hero-hover-bg,
            $button-product-sorting-color,
            $button-hero-border-color,
            $button-hero-border-hover-color,
            $button-hero-active-bg,
            $button-hero-disabled-bg,
            $button-hero-border-disabled-color,
            $button-hero-text-disabled-color
        );
    }

    &.is-link {
        @extend %is-clean;
        color: $button-link-text;
        text-decoration: underline;
        font-family: map-get($font-families, secondary);
        letter-spacing: map-get($letter-spacings, large);

        &:focus,
        &:hover {
            color: $button-link-text-hover;
        }
    }

    &.is-underlined {
        @extend %is-clean;
        position: relative;
        color: $button-underlined-color;
        font-size: $button-underlined-size;
        padding-bottom: 0.6rem;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 0.3rem;
            background-color: $button-underlined-bg;
            transform: translate3d(0, 0, 0);
            transition: transform $duration-fast $smooth-out;
        }

        &:hover {
            &::after {
                transform: translate3d(0, -0.4rem, 0);
            }
        }
    }

    &.is-filter {
        display: flex;
        min-width: auto;
        border: 0;
        font-family: map-get($font-families, $button-filter-family-key);
        background-color: transparent;
        color: $button-filter-color;

        @include touch {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }

        &:focus,
        &:hover,
        &:active {
            background-color: transparent;
            color: $button-filter-color-active;
        }

        span {
            transition: box-shadow $duration-button ease-in-out;
            box-shadow: inset 0 0 0 $primary;
            line-height: $button-height-normal;
            white-space: nowrap;
        }

        &.is-active {
            background-color: transparent;
            color: $primary;

            span {
                box-shadow: inset 0 -3px 0 $primary;
                line-height: $button-height-normal;
            }
        }

        &.is-active-bar {
            color: $button-filter-color-active;
        }
    }

    &.is-full {
        width: 100%;
    }

    &.is-full-height {
        height: 100%;
    }

    &.is-fetching {
        &::after {
            content: '';
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 0.4rem;
            display: block;
            left: 0;
            bottom: 0;
            right: 0;
            transform: scaleX(0);
            animation: fetchingAnimation 1.5s infinite;
            background: $primary;
        }
    }

    &.is-external {
        padding-right: calc($button-padding-horizontal + $button-external-gap + $button-external-icon-size);

        // style on :before, because :after might be associated with something else
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            right: $button-padding-horizontal;
            transform: translate(0.25em, -50%) translateY(-0.15em);
            background: currentColor;
            display: inline-block;
            width: $button-external-icon-size;
            height: $button-external-icon-size;
            mask: url('/assets/images/external-link.svg') no-repeat 50% 50%;
            mask-size: cover;
            pointer-events: none;
        }
    }

    &.flex-right {
        margin-left: auto;
    }
}

/*
* Use this class if a button tag only has an icon and not any text e.g.
<button type="button" class="button-icon">
    <span class="icon">${questionmark}</span>
</button>
*/
.button-icon {
    background-color: transparent;
    cursor: pointer;

    &.is-primary {
        @include filled-button($button-primary-bg, transparent, transparent, $button-primary-hover-bg);
    }
}
