@use 'sass:math';

$carousel-slide-bg: $white !default;
$carousel-button-bg: $white !default;
$carousel-button-color-primary: $black !default;
$carousel-button-color-secondary: $primary !default;
$carousel-button-color-tertiary: $black !default;
$carousel-button-bg-tertiary: #f6f6f6 !default;
$carousel-button-color-tertiary-border: #f2f2f2 !default;
$carousel-button-size-base: 5.8rem !default;
$carousel-button-size-small: 4.4rem !default;
$carousel-bullet-bg: $white !default;
$carousel-more-color: $white !default;
$carousel-more-bg: $black !default;
$carousel-more-size-key: medium !default;

$swiper-button-tight-space: 2rem !default;
$swiper-button-tight-prev: 1.6% !default;
$swiper-button-tight-next: 2rem !default;

$container-offset: (2 * $gap) !default;
$container-max-width: $fullhd !default;

:root {
    --swiper-theme-color: #{$primary};
    --swiper-navigation-color: #{$carousel-button-color-primary};
    --swiper-navigation-size: #{$carousel-button-size-base};
}

.carousel {
    @include is-full;

    user-select: none;
    overflow: hidden;

    &-spot {
        &__container {
            @include until($desktop) {
                padding-left: 1.5rem;
                padding-right: 1.5rem;
            }
        }
    }

    &__arrows-container {
        position: relative;
        z-index: $index-body-overlay + 1;
    }

    &__arrows {
        @include until($desktop) {
            aspect-ratio: 16 / 9;
            position: absolute;
            width: calc(95% - 3rem);
            pointer-events: none;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__overlay {
        overflow: hidden;
        height: 100%;
    }

    .swiper {
        overflow: visible;
    }

    .swiper-wrapper {
        .swiper-slide {
            background-color: transparent;
            width: 95%;

            @include desktop {
                width: 100%;
                max-width: $desktop - $container-offset;
            }

            @include widescreen {
                max-width: min($widescreen, $container-max-width) - $container-offset;
            }

            @include fullhd {
                max-width: min($fullhd, $container-max-width) - $container-offset;
            }
        }
    }

    .swiper-button {
        @include until($desktop) {
            &-prev,
            &-next {
                --swiper-navigation-size: #{$swiper-button-size-small};
            }

            &-prev {
                left: 1.1rem;
            }

            &-next {
                right: 1.1rem;
            }
        }

        @include from($desktop) {
            &-prev,
            &-next {
                top: auto;
                bottom: $swiper-button-tight-space;
            }

            &-prev {
                left: auto;
                right: calc(#{$swiper-button-tight-prev} + #{$swiper-button-tight-space} + var(--swiper-navigation-size));
            }

            &-next {
                right: $swiper-button-tight-next;
            }
        }
    }
}
