.product-item-carousel {
    @include mobile {
        padding: 0;
    }

    &__more {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $carousel-more-color;
        padding: $gutter-small calc(#{$gutter-small} + var(--swiper-navigation-size));
        background-color: rgba($carousel-more-bg, 0.6);
        font-size: map-get($font-sizes, $carousel-more-size-key);
    }

    &__link {
        top: 0;
        bottom: 0;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    .swiper-slide {
        text-align: center;
    }

    .swiper-button-prev,
    .swiper-button-next {
        --swiper-navigation-size: #{$swiper-button-size-small};

        @include touch {
            display: none;
        }

        &.swiper-button-disabled {
            &:hover {
                pointer-events: fill;
            }
        }
    }

    .swiper-button-prev {
        background-image: url('/assets/images/chevron-light-left-primary.svg');
    }

    .swiper-button-next {
        background-image: url('/assets/images/chevron-light-right-primary.svg');
    }

    .swiper-button-prev,
    .swiper-button-next,
    .swiper-pagination {
        @include desktop {
            opacity: 0;
            transition: opacity 200ms $smooth-in;
        }
    }

    &:hover {
        .swiper-button-prev:not(.swiper-button-disabled),
        .swiper-button-next:not(.swiper-button-disabled),
        .swiper-pagination {
            @include desktop {
                opacity: 1;
            }
        }
    }
}
