$filter-item-shadow-color: $grey-light !default;
$filter-item-font-size-key: sub !default;

.filter-items {
    position: relative;

    &--mobile {
        @include touch {
            margin-left: -$section-padding-mobile-horizontal;
            margin-right: -$section-padding-mobile-horizontal;
        }
    }

    &__action {
        position: absolute;
        right: 0;
        top: 0;
        z-index: $index-body-overlay;
        gap: 1rem;
    }
}

.filter-tab-content {
    &__items {
        display: grid;
        /* autoprefixer: off */
        grid-template-columns: repeat(auto-fill, minmax(12.7rem, 12.762rem));
        gap: 1.5rem;
        padding-top: 2.5rem;
        padding-bottom: 1rem;

        @include until($fullhd) {
            /* autoprefixer: off */
            grid-template-columns: repeat(auto-fill, minmax(12.7rem, auto));
        }
    }

    &__item {
        overflow: hidden;
        height: 9rem;
        padding: 0.8rem 1rem;
        font-size: map-get($font-sizes, $filter-item-font-size-key);
        box-shadow: inset 0 0 0 1px $filter-item-shadow-color;
        text-decoration: none;
        transition: box-shadow $duration-fast $smooth-in;
        line-height: 1;

        @include desktop {
            height: 8rem;
        }

        &:last-child {
            margin-right: 0;
        }

        &--active {
            box-shadow: inset 0 0 0 2px $primary !important;
        }

        &:focus:not(:active),
        &:focus {
            box-shadow: inset 0 0 0 1px $filter-item-shadow-color;
        }

        &:hover {
            box-shadow: inset 0 0 0 2px $primary !important;
        }
    }

    &__text {
        transform: translateY(-0.4rem);
    }

    .filter-type-icons {
        &__text {
            transform: translateY(-0.4rem);
        }
    }
}
