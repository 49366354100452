/****** COLORS *******/
$white: white !default;

$light-peach: #e4dcd3 !default;
$light-blue-grey: #aacae6 !default;
$light-brown: #e4dcd3 !default;
$brown: #a36b4f !default;
$brown-grey: #999 !default;
$very-light-pink: #f6f3f2 !default;
$brownish-grey: #666 !default;
$greyish-brown: #444 !default;
$color-lightgrey: #ddd !default;
$light-brown: #e4dcd3 !default;
$moderate-red: #ca525d !default;

// Bulma Colors variables
$black: black !default;
$black-bis: #1c1b1b !default;
$black-ter: #323232 !default;
$grey-darker: #444 !default;
$grey-dark: #999 !default;
$grey: #767676 !default;
$grey-light: #b7b7b7 !default;
$grey-lighter: #e5e5e5 !default;
$turquoise: #00aad2 !default;
$red: #e63312 !default;
$orange: #a36b4f !default;
$blue-darker: #00152e !default;
$blue-dark: #00152e !default;
$blue: #002c5e !default;
$blue-light: #aabed5 !default;

$white-ter: #e4e8e8 !default;
$white-bis: hsl(0, 0%, 98%) !default;

$yellow: hsl(48, 100%, 67%) !default;
$yellow-dark: #d2b887 !default;
$green: hsl(141, 71%, 48%) !default;
$cyan: hsl(204, 86%, 53%) !default;
$purple: hsl(271, 100%, 71%) !default;

$primary: $blue !default;

$info: $cyan !default;
$success: $primary !default;
$warning: $yellow !default;
$danger: $red !default;

$light: $white-ter !default;
$dark: $grey-darker !default;

// General colors
$foreground: $white !default;
$background: $white !default;

$border: $grey-lighter !default;
$border-hover: $grey-light !default;

// Text colors
$text: $brownish-grey !default; // Text color
$text-invert: findColorInvert($text) !default; // Text color inverted
$text-light: $grey !default;
$text-strong: $grey-darker !default;
$rte-default-theme-text: $black !default;
$rte-dark-theme-text: $white !default;
$rte-light-theme-text: $black !default;

// Code colors
$code: $red !default;
$code-background: $background !default;

$pre: $text !default;
$pre-background: $background !default;

$logo-color: $blue !default;

$color-divider: $grey-lighter !default;
$mega-sidemenu-label-color: $black !default;

// Features
$favorite: $primary;
