$price-amount-color: $black-bis;
$price-text-color: $grey-dark !default;
$price-text-size: $font-size-largest-mobile !default;
$price-price-font-size: $font-size-small !default;
$product-price-main-weight-key: light !default;
$price-price-menu-family-key: base !default;
$price-price-menu-weight-key: bold !default;

.product-price {
    color: $price-text-color;

    &__menu-btn {
        @include body-copy-small;
        font-weight: map-get($font-weights, $price-price-menu-weight-key);
        font-family: map-get($font-families, $price-price-menu-family-key) !important;
        font-size: $price-price-font-size;
        text-transform: uppercase;
        padding: 0 3.5rem 0 0 !important;
    }

    &__content {
        padding-top: 1rem;
    }

    &__amount {
        color: $price-amount-color;
        white-space: nowrap;

        &--main {
            @include tertiary-heading;
            font-size: $price-text-size;
            font-weight: map-get($font-weights, $product-price-main-weight-key);
        }
    }

    &__psa {
        @include body-copy-small;
        padding-top: 1.6rem;
        hyphens: auto;

        table {
            width: 100%;
        }
    }

    .navbar-start {
        display: flex;
        width: 100%;
        border-bottom: 1px solid $slime-border-color;
    }

    .buttons {
        padding-top: 1rem;
    }

    .rte-content {
        @include body-copy-small;
        color: inherit;
        hyphens: auto;
    }

    &__buttons {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.75rem;
    }
}
