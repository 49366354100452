$spot-content-title-letter-spacing: large !default;

.spot-content {
    &__header {
        white-space: pre-line;

        &--title {
            letter-spacing: map-get($letter-spacings, $spot-content-title-letter-spacing);
        }
    }
}
