$search-link-color: $primary !default;
$search-link-color-hover: darken($search-link-color, 20%) !default;
$search-offset: 0; // Value should match value in slideIn animation
$search-min-width: 42.5rem + $search-offset !default;
$search-padding-vertical: 4.8rem !default;
$search-padding-horizontal: 4.5rem !default;
$search-mobile-padding-vertical: 4rem !default;
$search-mobile-padding-horizontal: 2.5rem !default;
$search-border-color: $grey-light !default;
$search-title-color: $black !default;
$search-title-active-color: $primary !default;
$search-title-font-size: $font-size-sub !default;
$search-title-text-transform: inherit !default;
$search-description-color: $brownish-grey !default;
$search-description-size: $font-size-base !default;
$search-description-line-height: normal !default;
$search-button-close-color: $primary !default;
$search-button-close-hover-color: inherit !default;

.search {
    position: relative;
    display: block;
    height: 100%;
    padding: 0;
    width: 24vw;
    min-width: $search-min-width;
    background-color: $white;

    @include mobile {
        width: 100vw;
        min-width: auto;
    }

    // Used to hide background on slide in animation
    &::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 9rem;
        background-color: $white;
        top: 0;
        right: -9rem;
        bottom: 0;
    }

    &__content {
        height: 100%;
        overflow-x: auto;
        overflow-y: scroll;
        padding: $search-padding-vertical ($search-padding-horizontal + $search-offset) $search-padding-vertical $search-padding-horizontal;

        @include mobile {
            padding: $search-padding-vertical $search-mobile-padding-horizontal $search-mobile-padding-vertical $search-mobile-padding-horizontal;
        }
    }

    &__close {
        position: absolute;
        z-index: 900;
        top: 0;
        transform: translate3d(-50%, 4.25rem, 0); // y offset should match anoimatioen in slide.animations
        color: $search-button-close-color;

        @include from($tablet) {
            left: 0;
        }

        @include mobile {
            top: 4.1rem;
            right: 2.3rem;
            transform: translate3d(0, 0, 0);
        }

        &:hover {
            color: $search-button-close-hover-color;
        }
    }
}

.search-menu {
    &__item {
        > a {
            @include typeset(navigation, base);
            padding-left: 0;
            text-decoration: none;
            color: $text;
            letter-spacing: map-get($letter-spacings, large);

            &:hover {
                color: $primary;
            }
        }
    }
}

.search-sections {
    &__item {
        display: block;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}

.search-section {
    &__header {
        margin-bottom: 2.4rem;
        border-bottom: 1px solid $search-border-color;

        &--title {
            color: $search-title-color;
            letter-spacing: map-get($letter-spacings, large);
            padding-bottom: 1.2rem;
            font-size: $search-title-font-size;
            text-transform: $search-title-text-transform;
        }

        &.is-quicklinks {
            margin-bottom: 1.1rem;
        }
    }

    > a {
        display: flex;
    }

    > a:not(:last-of-type) {
        margin-bottom: 2rem;
    }

    &__link {
        padding-top: 1rem;
        color: $search-link-color;

        &:hover {
            color: $search-link-color-hover;
        }
    }
}

ncg-search-image {
    display: block;
}

.search-item {
    padding-bottom: 0.4rem;
    text-decoration: none;

    &__image {
        width: 100%;
        max-width: 9rem;
        min-width: 3rem;
        margin-right: 1.5rem;
    }

    &__text {
        max-width: 100%;

        &--title {
            @include typeset(search, medium, small);
            padding-bottom: 0.4rem;
            color: $search-title-color;
            letter-spacing: map-get($letter-spacings, x-small);
            transition: color 200ms $smooth-in-out;
        }

        &--description {
            @include truncate-text(true);
            letter-spacing: map-get($letter-spacings, large);
            transition: color 200ms $smooth-in-out;
            color: $search-description-color;
            font-size: $search-description-size;
            line-height: $search-description-line-height;
        }
    }

    &:hover,
    &.is-active {
        .search-item {
            &__text {
                &--title,
                &--description {
                    color: $search-title-active-color;
                }
            }
        }
    }

    &.is-active {
        img {
            transform: scale($image-scale-size-small);
        }
    }
}

.search-empty {
    margin: 1.2rem 0;

    &__heading {
        font-family: map-get($font-families, bold);
    }

    &__helper {
        color: $grey-dark;
    }
}

.slide-overlay {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white;
    background-color: $primary;
    pointer-events: none;
    transform-origin: left center;

    &--slide-left {
        transform: scale3d(1, 1, 1) translate3d(100%, 0, 0);
    }
}

.slide-content {
    position: relative;
    z-index: 1;
}
