$cta-link-hover-color: $blue !default;
$cta-link-hover-color--theme-dark: $blue-light !default;
$cta-link-line-height: $icon-chevron-size-large !default;
$cta-image-filter--theme-dark: invert(93%) sepia(98%) saturate(491%) hue-rotate(202deg) brightness(122%) contrast(100%) !default; // White
$cta-divider-color: $color-divider !default;
$cta-divider-color--theme-dark: $color-divider !default;
$cta-divider-color-desktop-dark: rgba($color-divider, 0.5) !default;

.cta-spot {
    $parent: &;

    @include tablet {
        padding: 2rem 0 2.5rem;
    }

    &__item {
        @include mobile {
            position: relative;
            padding: 1rem 0;

            &::before,
            &::after {
                position: absolute;
                left: 50%;
                width: 100vw;
                height: 1px;
                transform: translate(-50%, 0);
                background: $cta-divider-color;

                @include when-inside('.grid__theme--dark') {
                    background: $cta-divider-color--theme-dark;
                }
            }

            &::before {
                content: '';
                top: 0;
            }

            &:last-child {
                &::after {
                    content: '';
                    bottom: 0;
                }
            }
        }

        @include tablet {
            border-right: 1px solid #{$cta-divider-color};

            @include when-inside('.grid__theme--dark') {
                border-color: $cta-divider-color-desktop-dark;
            }

            &:last-of-type {
                border-width: 0;
            }
        }
    }

    &__item-content {
        position: relative;

        &:hover #{$parent}__theme-color-wrapper {
            color: $cta-link-hover-color;
            @include when-inside('.grid__theme--dark') {
                color: $cta-link-hover-color--theme-dark;
            }
        }
    }

    &__theme-color-wrapper {
        color: $rte-default-theme-text;

        @include when-inside('.grid__theme--dark') {
            color: $rte-dark-theme-text;
        }
        @include when-inside('.grid__theme--light') {
            color: $rte-light-theme-text;
        }

        @include mobile {
            display: flex;
        }
    }

    &__icon {
        fill: currentColor;
        margin: -1rem -1rem 0; // Counters the built in whitespace padding from the svg
        transform: translateY(1rem); // Moves icon closer to the text content

        @include mobile {
            margin-top: 0;
            transform: translateY(0);
            width: 6rem;
            height: 6rem;

            svg {
                width: 6rem;
                height: 6rem;
            }
        }
    }

    &__title {
        @include tertiary-heading;
        margin-bottom: 0.25em;
        color: currentColor;

        &:only-child {
            margin-top: 1rem;
        }

        @include mobile {
            margin-top: 1.5rem;
            margin-left: 2rem;
            font-size: map-get($font-sizes, base);

            // If item has no icon
            &:only-child {
                margin-top: 0.25em;
                margin-left: 1rem;
            }
        }
    }

    &__chevron {
        display: inline-block;
        vertical-align: top;
        flex: 0 0 $icon-chevron-size-large;
        width: $icon-chevron-size-large;
        height: $cta-link-line-height;
        margin-left: -0.9rem; // Since chevron svg path is not left aligned
        margin-right: 0.3rem;

        svg {
            width: 100%;
            height: 100%;
            fill: currentColor;
        }

        @include mobile {
            transform: translateY(-0.3rem); // adjust for less lineheight on mobile
        }
    }

    &__link {
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &__link-wrapper,
    &__link-content,
    &__link-text {
        // Because we need inline-block on link-content for line-height,
        // we have to cascade the text-decoration from the link to an element that is just inline
        text-decoration: inherit;
    }

    &__link-content {
        display: inline-block;
        line-height: $cta-link-line-height; // Align 1st line with chevron, also when wrapping

        &_left {
            display: flex;
        }

        @include mobile {
            line-height: calc(#{$cta-link-line-height} - 0.6rem);
            padding-top: 0.5rem;
        }

        @include mobile {
            // On mobile the wrapping text lines are indented
            display: flex;
        }
    }
}
