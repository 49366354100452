$anchor-navigation-bgs: (
    '': $default-theme-bg,
    '.grid__theme--light': $light-theme-bg,
    '.grid__theme--dark': $dark-theme-bg,
);

.anchor-navigation {
    @include from($tablet) {
        text-align: center;
    }

    &--align-left {
        text-align: left;
    }

    &--scroll-left {
        &::before {
            @include scroll-fade($anchor-navigation-bgs, left, 1, 1.5rem);
        }
    }

    &--scroll-right {
        &::before {
            @include scroll-fade($anchor-navigation-bgs, right, 1, 1.5rem);
        }
    }

    &--scroll-both {
        &::before {
            @include scroll-fade($anchor-navigation-bgs, left, 1, 1.5rem);
        }

        &::after {
            @include scroll-fade($anchor-navigation-bgs, right, 1, 1.5rem);
        }
    }

    &__list {
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;

        @include from($tablet) {
            display: block;
        }
    }

    &__item {
        @include from($tablet) {
            display: inline-block;
        }

        &-link {
            $parent: &;

            position: relative;
            display: flex;
            padding-left: 1.8rem;
            margin-top: 2rem;
            margin-bottom: 2rem;
            text-decoration: none;
            color: var(--link-primary-color);
            font-family: var(--heading-primary-font-family);
            font-size: var(--font-size-navigation);
            letter-spacing: var(--heading-primary-letter-spacing);

            @include when-inside('.grid__theme--default') {
                color: var(--color-primary);
            }

            @include when-inside('.grid__theme--light') {
                color: var(--color-primary);
            }

            @include when-inside('.grid__theme--dark') {
                color: var(--color-white);
            }

            @include from($tablet) {
                padding: 0 1.25rem;
            }

            @include from($desktop) {
                padding: 0 1.75rem;
            }

            &-icon {
                padding-right: 2rem;
                position: relative;
                height: 2.8rem;
                width: 0.8rem;

                &::before,
                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                }

                &::before {
                    width: 0.8rem;
                    height: 0.8rem;
                    border-style: solid;
                    border-color: inherit;
                    border-width: 0 0.2rem 0.2rem 0;
                    bottom: 0.1rem;
                    left: -0.3rem;
                    transform: rotate(45deg);
                    transition: bottom 0.1s ease-in-out;

                    #{$parent}:hover & {
                        bottom: -0.6rem;
                    }
                }

                &::after {
                    top: 0;
                    width: 0.2rem;
                    height: 2.7rem;
                    background: currentColor;
                    transition:
                        height 0.1s ease-in-out,
                        color 0.1s ease-in-out;

                    #{$parent}:hover & {
                        height: 3.4rem;
                    }
                }
            }

            &-title {
                white-space: nowrap;
            }
        }
    }
}
