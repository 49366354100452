$expansion-panel-icon-color: inherit !default;

.expansion-panel {
    &__header {
        @include no-outline;

        &.is-clickable {
            cursor: pointer;
        }
    }

    &__icon {
        @include no-outline;
        align-items: center;
        display: flex;
        justify-content: flex-end;
        flex: 1;
        padding: 0.5rem 1rem 0.5rem 0;
        text-align: right;
        cursor: pointer;
        user-select: none;
        color: $expansion-panel-icon-color;
        background: transparent;
    }

    &__content {
        overflow: hidden;
    }

    @include tablet {
        &__icon {
            flex: 0 0 30%;
        }
    }
}
