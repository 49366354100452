@use 'sass:math';

/****** EASING *******/
$smooth-out: cubic-bezier(0.23, 0, 0.13, 1) !default;
$smooth-in: cubic-bezier(0.885, 0, 0.68, 1) !default;
$smooth-in-out: cubic-bezier(0.165, 0.84, 0.44, 1) !default;

/****** DURATIONS *******/
$duration-fast: 200ms !default;
$duration-medium: 500ms !default;
$duration-slow: 600ms !default;
$duration-link: $duration-fast !default;
$duration-button: $duration-fast !default;
$duration-icons: $duration-fast !default;

/****** THEME *******/
$default-theme-bg: $white !default;
$default-theme-text: $text !default;
$dark-theme-bg: $primary !default;
$dark-theme-text: $white !default;
$light-theme-bg: $very-light-pink !default;
$light-theme-text: $black !default;

/****** ASPECT RATIO'S *******/
$ratio-product-item: 63% !default;
$ratio-image-text-single: 60.48% !default;
$ratio-menu: 69.9551569507% !default;
$ratio-models: 55.8695652174% !default;
$ratio-4-5: 125% !default;
$ratio-4-3: 75% !default;
$ratio-16-7: 43.75% !default;
$ratio-16-9: 56.25% !default;
$ratio-21-9: 42.8387096774% !default;
$ratio-31-9: 28.9304677623% !default;

/****** NAVIGATION *******/
$mega-menu-padding-top: 7.5rem !default;
$mega-menu-padding-bottom: 6.5rem !default;
$mega-menu-link-color: $black !default;
$mega-menu-link-color-hover: $primary !default;
$mega-menu-bg-color: $background !default;
$mega-menu-link-vertical-padding: 1.5rem !default;
$mega-menu-link-vertical-padding-large: 5rem !default;
$mega-menu-link-horizontal-padding: 1.5rem !default;
$mega-menu-link-horizontal-padding-large: 4.1rem !default;
$mega-menu-link-height: 2.6rem !default;

$top-menu-background-color: $very-light-pink !default;
$top-menu-text-color: $mega-menu-link-color !default;
$top-menu-border-color: $border !default;

$control-height: 3rem !default;

/****** Z-INDEXES *******/
$index-header: 100 !default;
$index-content: 2 !default;
$index-footer: 3 !default;
$index-body-overlay: $index-header - 1 !default;
$index-page-transition: 2000 !default;

/****** GUTTER *******/
$gutter: 3rem !default;
$gutter-small: math.div($gutter, 3) !default;

/****** FONT *******/
$font-smoothing: antialiased !default;

/****** BULMA OVERRIDES *******/
// Breakpoints
$mobile-xs: 321px !default;
$mobile-small: 374px !default;
$mobile-large: 414px !default;
$tablet: 768px !default;
$desktop: 1024px !default;
$widescreen: 1152px !default;
$fullhd: 1250px !default;
$desktop-large: 1400px !default;
$fullwidth: 1600px !default;
$control-border-width: 2px !default;
/* stylelint-disable */
// Value has to have a unit specified because it's used in a calc function
$spot-padding-top: 0rem !default;
/* stylelint-enable */
$column-gap: $gutter-small !default;

/****** HEADER  *******/
$header-border: $border !default;
$header-bg-color: $background !default;
$header-padding-horizontal: 4rem !default;
$header-logo-x-small-padding-left: 1rem !default;
$header-logo-small-padding-left: 1.7rem !default;
$header-logo-large-padding-left: 3.5rem !default;
$header-logo-large-padding-right: 2.9rem !default;

/****** MEGA SIDE MENU (SHORTCUTS)  *******/
$mega-sidemenu-link-color: $primary !default;
$mega-sidemenu-label-size: 1.5rem !default;

/****** SHARED FILTER SIDEPANEL  *******/
$filter-padding-left: 4.5rem !default;

/****** LINK TAG  *******/
$link-default-color: inherit !default;
$link-default-color-hover: inherit !default;
$link-default-decoration: underline !default;

/****** CUSTOM SCROLL BAR *******/
$scrollbar-thumb-bg: lighten($primary, 3%) !default;

/****** HEADER SHADOW *******/
$header-shadow: 0 0.1rem 0.4rem 0 rgba(1, 1, 1, 0.1);

/****** FORM *******/
$form-input-height: 4.9rem !default;

/****** MINIMAL HEADER AND FOOTER *******/
$minimal-header-background-color: $primary !default;
$minimal-footer-background-color: $white !default;

/****** CONFIGURATOR *******/
$configurator-tab-color: $grey-light !default;
$configurator-tab-color-active: $primary !default;
$configurator-accent-color: $primary !default;
$configurator-header-height: 12rem !default;
$configurator-header-height-mobile: 6rem !default;
$configurator-headline-size: 2rem !default;
$configurator-headline-weight: 400 !default;
$configurator-small-text-weight-bold: 700 !default;
$configurator-label-font-size: 2rem !default;

/****** SERVICE BOOKING *******/
$service-booking-header-height: 12rem !default;
$service-booking-header-height-mobile: 6rem !default;
$service-booking-max-width: 80rem !default;

/****** CARD *******/
$card-active-color: $primary !default;
$card-border-color: $grey-light !default;

/* autoprefixer grid: on */
