@use 'sass:math';

$model-page-header-height: 9rem !default;
$sub-nav-title-height: 3rem !default;
$sub-nav-header-bg: $primary !default;
$sub-nav-header-color: $white !default;
$sub-nav-header-border-width: 0 !default;
$sub-nav-cta-height: 3.4rem !default;
$sub-nav-cta-font-weight-key: bold !default;
$sub-nav-cta-font-size-key: base !default;
$sub-nav-cta-color: $sub-nav-header-color !default;
$sub-nav-sticky: calc((-1rem) + (-#{$sub-nav-cta-height}) + (-#{$sub-nav-title-height})) !default;
$model-page-title-is-small: false !default;
$model-page-title-small-size-key: $small-heading-size-key !default;
$model-page-title-text-transform: none !default;
$model-page-header-is-animating: true !default;
$model-page-header-border-color: $border !default;
$model-page-header-padding-top: 1rem !default;
$model-page-header-padding-bottom: 0 !default;
$model-page-header-inner-padding: 5.2rem !default;
$model-page-header-nav-padding-bottom: 1rem !default;
$model-page-header-actions-padding-bottom: math.div($gutter-small, 2) !default;
$model-page-header-layout-large: default !default;

.model-header {
    position: relative;
    z-index: $index-header - 1;
    display: block;

    &__wrapper {
        background-color: $sub-nav-header-bg;
    }

    @if $model-page-header-is-animating == false {
        &__wrapper {
            position: sticky;
            z-index: $index-header - 3;
            top: $header-height-mobile;

            @include desktop {
                top: $header-height-desktop;
            }
        }
    }

    &--is-sticky {
        position: fixed;
        top: 0;
        transform: translate3d(0, 0, 0);
        transition: transform $image-animation-duration-fast $smooth-out;
        width: 100%;
    }

    &__inner {
        background-color: $sub-nav-header-bg;
        color: $sub-nav-header-color;

        @include desktop {
            border-bottom: $sub-nav-header-border-width solid $border;
        }

        > .container {
            @include from($fullhd) {
                padding-left: $model-page-header-inner-padding;
                padding-right: $model-page-header-inner-padding;
            }
        }
    }

    &__content {
        display: grid;

        @include mobile {
            grid-template-rows: $sub-nav-title-height 1fr;
            margin-left: -$container-padding-touch;
            margin-right: -$container-padding-touch;
        }

        @include until($desktop) {
            overflow: hidden;
            grid-template-columns: 100%;
            padding: $model-page-header-padding-top 0 $model-page-header-padding-bottom;
        }

        @include from($desktop) {
            grid-template-rows: 4rem 6rem;
            grid-template-columns: 100%;
        }

        @include from($desktop-large) {
            grid-template-rows: $model-page-header-height !important;
            @if ($model-page-header-layout-large == 'space-between') {
                grid-template-columns: auto 1fr 1fr !important;
            } @else {
                grid-template-columns: 17rem 1fr 17rem !important;
            }
        }

        &.has-price {
            @include mobile {
                grid-template-rows: $sub-nav-title-height $sub-nav-cta-height 1fr;
                margin-left: -$container-padding-touch;
                margin-right: -$container-padding-touch;
            }

            @include from($desktop) {
                grid-template-rows: 4rem 6rem;
                grid-template-columns: 50% 50%;
            }
        }
    }

    &__title {
        align-self: center;
        width: 100%;

        @include until($desktop-large) {
            text-align: center;
            padding-top: $gutter-small;
        }

        @include until($desktop) {
            order: 1;
            text-align: center;
            padding-right: 0;
            justify-self: center;
            width: 100%;
            grid-row: 1;
            padding-bottom: $model-page-header-padding-top;
            padding-top: 0;
        }

        &.has-price {
            @include until($desktop-large) {
                text-align: right;
                padding-right: 1rem;
            }

            @include until($desktop) {
                text-align: center;
                padding-right: 0;
                padding-bottom: 0;
            }
        }

        h3 {
            @if $model-page-title-is-small {
                @include typeset($model-page-title-small-size-key, $small-heading-family-key, $small-heading-line-key);
            }

            letter-spacing: map-get($letter-spacings, x-large);
            margin-bottom: 0;
            text-transform: $model-page-title-text-transform;

            @include mobile {
                @include truncate-text(true);
            }
        }
    }

    &__nav {
        overflow-x: auto;
        overflow-y: hidden;

        @include until($desktop) {
            grid-row: 3;
            order: 3;
            max-width: 100vw;
            padding-bottom: $model-page-header-nav-padding-bottom;
        }

        @include from($desktop) {
            grid-row: 2;
            grid-column-start: 1;
            grid-column-end: 4;
            justify-self: center;
            align-self: flex-start;
            margin-left: 1rem;
            margin-right: 1rem;
        }

        @include from($desktop-large) {
            align-self: center;
            grid-row: 1;
            @if ($model-page-header-layout-large == 'space-between') {
                justify-self: end;
                grid-column-start: 3;
                grid-column-end: 4;
                margin: 0 (-1rem);
            } @else {
                grid-column-start: 2;
                grid-column-end: 3;
            }
        }
    }

    &__cta {
        letter-spacing: map-get($letter-spacings, large);
        line-height: map-get($line-heights, small);
        align-self: center;
        color: $sub-nav-cta-color;
        @include typeset($sub-nav-cta-font-size-key, $sub-nav-cta-font-weight-key, secondary);

        @include until($desktop-large) {
            order: 2;
            text-align: left;
            padding-left: 1rem;
            line-height: 1.7;
        }

        @include until($desktop) {
            grid-row: 2;
            padding-bottom: 1.8rem;
            padding-left: 0;
            font-size: map-get($font-sizes, sub);
            font-family: map-get($font-families, base);
            order: 2;
            text-align: center;
            line-height: map-get($line-heights, small);
        }

        @include from($desktop) {
            grid-column-start: 2;
            grid-column-end: 3;
            padding-top: $gutter-small;
        }

        @include from($desktop-large) {
            padding-top: 0;

            @if ($model-page-header-layout-large == 'space-between') {
                padding-left: 1rem;
                grid-column-start: 2;
                grid-column-end: 3;
            } @else {
                justify-self: flex-end;
                grid-column-start: 3;
                grid-column-end: 4;
            }
        }

        p {
            margin-bottom: 0;
        }
    }
}
