$default-title-color: $black !default;
$default-subtitle-color: $grey !default;

$light-title-color: $black !default;
$light-subtitle-color: $grey !default;

$dark-title-color: $white !default;
$dark-subtitle-color: $grey-light !default;

$headline-background-color: $grey-lighter !default;
$headline-background-color-light: $grey-light !default;
$headline-background-color-dark: darken($dark-theme-bg, 8%) !default;

$headline-title-size-key: largestMobile !default;
$headline-title-size-key-touch: largestMobile !default;
$headline-title-desktop-size-key: largest !default;
$headline-title-family-key: medium !default;
$headline-title-line-height-key: small !default;
$headline-title-letter-space-key: small !default;
$headline-title-font-weight: normal !default;
$headline-subtitle-text-transform: initial !default;
$headline-title-text-transform: initial !default;
$headline-subtitle-family-key: base !default;
$headline-subtitle-height-key: secondary !default;
$headline-subtitle-weight-key: normal !default;
$headline-subtitle-touch-font-key: sub !default;
$headline-subtitle-desktop-font-key: large !default;
$headline-subtitle-touch-letter-space-key: x-large !default;
$headline-subtitle-desktop-letter-space-key: large !default;
$headline-subtitle-padding-touch: 2rem !default;
$headline-subtitle-padding-desktop: 2rem !default;

.model-subpage {
    .grid {
        &__theme {
            &--top-padding {
                .spot-content {
                    &__header {
                        padding-top: 2rem;
                    }
                }
            }
        }
    }
}

.headline-spot {
    text-align: center;

    &.is-narrow {
        @include desktop {
            text-align: left;
        }
    }

    .spot-content {
        &__header {
            &--background {
                @include typeset(largestMobile, $headline-title-family-key, $headline-title-line-height-key, $headline-title-font-weight);
                font-size: calc(map-get($font-sizes, largestMobile) + 0.9rem); // ~ 44px
                color: $headline-background-color;
                text-transform: $headline-title-text-transform;

                @include until($desktop) {
                    letter-spacing: map-get($letter-spacings, $headline-title-letter-space-key);
                }

                @include desktop {
                    // TODO? create extra-huge font-size
                    font-size: calc(map-get($font-sizes, huge) + 3rem);
                }

                @include when-inside('.grid__theme--light') {
                    color: $headline-background-color-light;
                }

                @include when-inside('.grid__theme--dark') {
                    color: $headline-background-color-dark;
                }
            }

            &--title {
                @include typeset($headline-title-size-key-touch, $headline-title-family-key, $headline-title-line-height-key);
                font-weight: $headline-title-font-weight;
                text-transform: $headline-title-text-transform;
                margin-bottom: 0;

                @include until($desktop) {
                    padding-bottom: 0;
                    letter-spacing: map-get($letter-spacings, $headline-title-letter-space-key);
                }

                @include desktop {
                    padding-bottom: 0;
                    font-size: map-get($font-sizes, $headline-title-desktop-size-key);
                }

                @include when-inside('.grid__theme--default') {
                    color: $default-title-color;
                }

                @include when-inside('.grid__theme--light') {
                    color: $light-title-color;
                }

                @include when-inside('.grid__theme--dark') {
                    color: $dark-title-color;
                }

                + .spot-content {
                    &__header--subtitle {
                        @include touch {
                            padding-top: $headline-subtitle-padding-touch;
                        }

                        @include desktop {
                            padding-top: $headline-subtitle-padding-desktop;
                        }
                    }
                }
            }

            &--subtitle {
                @include typeset(
                    $headline-subtitle-touch-font-key,
                    $headline-subtitle-family-key,
                    $headline-subtitle-height-key,
                    $headline-subtitle-weight-key
                );
                letter-spacing: map-get($letter-spacings, $headline-subtitle-touch-letter-space-key);
                text-transform: $headline-subtitle-text-transform;

                @include until($desktop) {
                    line-height: map-get($line-heights, base);
                    letter-spacing: map-get($letter-spacings, $headline-subtitle-desktop-letter-space-key);
                }

                @include desktop {
                    font-size: map-get($font-sizes, $headline-subtitle-desktop-font-key);
                }

                @include when-inside('.grid__theme--default') {
                    color: $default-subtitle-color;
                }

                @include when-inside('.grid__theme--light') {
                    color: $light-subtitle-color;
                }

                @include when-inside('.grid__theme--dark') {
                    color: $dark-subtitle-color;
                }
            }
        }
    }
}
