.pico-content {
    .au-trade-in-modal {
        .au-trade-in-calc-wrapper {
            min-width: initial !important;
            max-width: 90vw !important;
            width: 600px;
            min-height: 390px;
            background: #f7f7f7;
        }
    }
}
