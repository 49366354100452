$product-gallery-grid-row-height: 254px;
$product-gallery-grid-gap: 4px;
$product-gallery-overlay-text-size-key: base !default;

%is-video {
    content: ' ';
    display: block;
    width: 100%;
    height: 100%;
    background: url('/assets/images/play-filled.svg') center center no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
}

.product-gallery {
    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: $index-body-overlay;
        opacity: 0;
        background-color: rgba($black, 0.5);
        color: $white;
        transition: opacity $duration-medium $smooth-in-out;

        &--text {
            font-size: map-get($font-sizes, $product-gallery-overlay-text-size-key);
            transition: transform $duration-medium $smooth-in-out;
            transform: translate3d(0, 1rem, 0);
        }
    }

    &__overlay-button {
        position: absolute;
        top: 2.5rem;
        left: 2.8rem;
        z-index: 2;
    }

    &__figure-grid {
        &.is-grid {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: $product-gallery-grid-row-height $product-gallery-grid-row-height;
            grid-column-gap: $product-gallery-grid-gap;
            grid-row-gap: $product-gallery-grid-gap;
            overflow: hidden;

            .product-gallery {
                &__picture {
                    @include aspect-ratio($ratio-product-item);

                    > img {
                        height: 100%;
                        object-fit: cover;
                        object-position: top left;
                    }

                    &:hover {
                        .product-gallery {
                            &__overlay {
                                opacity: 1;

                                &--text {
                                    transform: translate3d(0, 0, 0);
                                }
                            }
                        }
                    }
                }
            }

            :first-child {
                grid-area: 1 / 1 / 3 / 3;
            }

            @media (max-width: 1300px) {
                grid-template-columns: repeat(3, 1fr);

                :nth-child(4),
                :nth-child(5) {
                    display: none;
                }
            }

            @media (max-width: 980px) {
                display: flex;

                :first-child {
                    width: 100%;
                    object-position: unset;
                }

                :not(:first-child) {
                    display: none;
                }
            }
        }

        &-fallback {
            @include center-content;

            img {
                object-fit: unset;
                height: auto;
            }

            &-text {
                position: absolute;
                z-index: 1;
            }
        }
    }

    &__picture {
        &.is-clickable {
            cursor: pointer;
        }

        &.is-one {
            $aspect-ratio-matching-grid: $product-gallery-grid-row-height * 2 + $product-gallery-grid-gap;
            @include aspect-ratio($aspect-ratio-matching-grid, $focalpoint: centercenter);
        }

        &.is-video {
            &::after {
                @extend %is-video;
            }
        }
    }

    &__video-blocked {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-flow: wrap column;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        background-color: rgba($color: $black, $alpha: 0.7);
        color: $white;
        font-size: map-get($font-sizes, sub);

        > p {
            line-height: normal;
        }
    }
}

.gallery-overlay {
    overflow: hidden;
    position: relative;

    .swiper-button-container {
        position: absolute;
        left: unset;
        width: 100%;

        @include mobile {
            width: 76%;

            .swiper-button {
                width: 4rem;
                height: 4rem;

                svg {
                    height: 2rem;
                    width: 2rem;
                }
            }
        }
    }

    &__thumbs {
        &.swiper {
            width: 15rem;
            overflow: visible;
        }

        .swiper-slide {
            &:not(.swiper-slide-thumb-active) {
                img {
                    opacity: 0.5;
                }
            }

            &-thumb-active {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    height: 0.4rem;
                    background-color: $primary;
                    bottom: 0;
                }
            }
        }
    }

    &__thumb {
        cursor: pointer;
        padding-bottom: 1.2rem;
        width: 15rem;

        .swiper-slide {
            &__thumb {
                > img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top left;
                }

                &.is-video {
                    &::after {
                        @extend %is-video;
                        background-size: 25%;
                    }
                }
            }
        }
    }
}
