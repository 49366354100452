$mega-menu-border-top-space: 2.2rem !default;
$mega-menu-border-top-height: 0.1rem !default;
$mega-menu-border-top-background-color: $border !default;
$mega-menu-title-size-key: sub !default;
$mega-menu-title-family-key: medium !default;
$mega-menu-title-line-key: secondary !default;
$mega-menu-title-weight-key: base !default;

$mega-menu-description-size-key: sub !default;
$mega-menu-description-family-key: base !default;
$mega-menu-description-line-key: base !default;
$mega-menu-description-weight-key: normal !default;
$mega-menu-description-padding-top: 0 !default;

.mega-menu {
    &__wrapper {
        position: absolute;
        left: 0;
        width: 100vw;
        overflow: hidden;
        background-color: $background;

        &::before {
            content: '';
            position: absolute;
            top: $mega-menu-border-top-space;
            left: 0;
            right: 0;
            height: $mega-menu-border-top-height;
            background-color: $mega-menu-border-top-background-color;
        }
    }

    &__close {
        position: absolute;
        z-index: 100;
        right: 3rem;
        top: 5rem;
    }

    &__container {
        padding-top: $mega-menu-padding-top;
        padding-left: $header-padding-horizontal;
        padding-right: $header-padding-horizontal;
        padding-bottom: 2rem;

        > .container {
            max-width: $fullwidth !important;
            margin: 0 auto;
        }
    }

    &__dropdown {
        &--bg {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100vw;
            background-color: $mega-menu-bg-color;
            contain: layout;
            overflow: hidden;
            z-index: 1;
            pointer-events: none;
            transition:
                height 0.4s $smooth-out,
                transform 0.4s $smooth-out;
            box-shadow: inset 0 -1px 0 $color-divider;
        }

        &--line {
            position: relative;
            top: 6.4rem;
            height: 1px;
            background-color: $color-divider;
            width: 100vw;
        }
    }

    &__item {
        .is-active {
            color: $mega-menu-link-color-hover;
        }

        @include when-inside('.mega-menu__wrapper.is-open') {
            visibility: visible; // Fixes a glitch with menu items not being shown
        }
    }

    &__link {
        display: flex;
        flex-flow: wrap column;
        margin-bottom: $mega-menu-link-vertical-padding-large;
        letter-spacing: map-get($letter-spacings, large);
        color: $mega-menu-link-color;
        text-decoration: none;
        padding-left: $mega-menu-link-horizontal-padding !important;
        padding-right: $mega-menu-link-horizontal-padding !important;
        transition: color $duration-link ease-in-out;

        @include fullhd {
            padding-left: $mega-menu-link-horizontal-padding-large !important;
            padding-right: $mega-menu-link-horizontal-padding-large !important;
        }

        &:hover {
            color: $mega-menu-link-color-hover;
        }

        &--no-image {
            line-height: $mega-menu-link-height;
            margin-bottom: $mega-menu-link-vertical-padding;
        }

        &--image {
            flex: 0 0 auto;
            width: 100%;
        }

        &--title {
            padding-top: 1.8rem;
            padding-bottom: 0.2rem;
            @include typeset($mega-menu-title-size-key, $mega-menu-title-family-key, $mega-menu-title-line-key, $mega-menu-title-weight-key);
            letter-spacing: map-get($letter-spacings, medium);
        }

        &--description {
            padding-top: $mega-menu-description-padding-top;
            @include typeset(
                $mega-menu-description-size-key,
                $mega-menu-description-family-key,
                $mega-menu-description-line-key,
                $mega-menu-description-weight-key
            );
            letter-spacing: map-get($letter-spacings, medium);
        }
    }

    &__alt-links {
        width: 50%;
        margin-right: auto;
        column-fill: auto;
        max-height: ($mega-menu-link-height + $mega-menu-link-vertical-padding) * 6; // Fit 6 links before filling next column.

        a {
            white-space: nowrap;
        }
    }
}
