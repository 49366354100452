/****** UTILITIES *******/
@import 'bulma/sass/utilities/all';

.sticky-header {
    position: sticky;
    z-index: $index-header - 1;
    top: 0;
}

// Makes the link cover e.g. a card, instead of wrapping everything in link,
// which is not optimal for accessibility
.link-cover {
    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
    }
}

.link-cover-breakout {
    button,
    a {
        position: relative;
        z-index: 2;
    }
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
