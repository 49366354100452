ncg-energy-label {
    display: inline-block;
    line-height: 0;

    .energy-image {
        margin-right: 0.6rem;
        width: 3.5rem;
        height: 1.2rem;

        &--large {
            width: 5.3rem;
            height: 1.8rem;
        }
    }
}
