.tab-group {
    &__tabs {
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
        overflow-x: auto;
        gap: 3rem;

        @include tablet {
            justify-content: center;
        }

        &--item {
            @include no-outline;
            @include sub-heading;

            position: relative;
            flex: 0 0 auto;
            height: px-to-rem(50px);
            border-bottom: 4px solid transparent;
            line-height: px-to-rem(50px);
            text-align: center;
            white-space: nowrap;
            cursor: pointer;

            &:hover,
            &:active,
            &.is-active {
                color: $primary;
                border-color: $primary;

                @include when-inside('.grid__theme--dark') {
                    color: $white;
                    border-color: $white;
                }
            }
        }
    }

    &__content-wrapper {
        position: relative;
    }
}

.tab-content {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    visibility: visible;
    opacity: 1;
    transition: opacity 400ms ease-in-out;

    &.is-custom-hidden {
        pointer-events: none; // necessary to avoid overtaking/blocking click events
        position: absolute;
        visibility: hidden;
        opacity: 0;
    }
}
