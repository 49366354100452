$meta-item-small-min-width: 11rem;
$meta-item-large-min-width: 12.4rem;
$meta-item-color-touch: $black;

.meta-menu {
    .navbar {
        height: 100%;
    }

    .navbar-menu {
        height: 100%;
        padding: 0;
    }

    .navbar-item {
        justify-content: center;
        font-size: map-get($font-sizes, mobileMenu);
        min-width: auto;
        align-items: center;
        line-height: 1.1;
        @extend .border-left;

        @include until($desktop) {
            padding: 0 0.5rem;
            color: $meta-item-color-touch;
        }

        @include desktop {
            min-width: $meta-item-small-min-width - ($navbar-item-padding-side * 2);
            font-size: map-get($font-sizes, small);
        }

        @include fullhd {
            min-width: $meta-item-large-min-width - ($navbar-item-padding-side * 2);
        }

        &__search {
            @extend .is-hidden-touch;
        }
    }
}
