$product-item-bg: $white !default;
$product-item-media-bg: $grey-light !default;
$product-item-title-color: $black !default;
$product-item-title-padding: 0.3rem !default;
$product-item-title-size-key: $tertiary-heading-size-key !default;
$product-item-tags-horizontal-padding: $gutter-small !default;
$product-item-tags-vertical-padding: $gutter-small !default;
$product-item-info-horizontal-padding-left: 3.4rem !default;
$product-item-info-horizontal-padding-right: $gutter-small !default;
$product-item-info-vertical-padding-top: 2rem !default;
$product-item-info-vertical-padding-bottom: 1rem !default;
$product-item-details-color: #707070 !default;
$product-item-details-size-key: sub !default;
$product-item-details-line-height: 1.9 !default;
$product-item-price-color: $white !default;
$product-item-price-bg: $primary !default;
$product-item-price-secondary-padding: 1.5rem !default;
$product-item-price-padding-right: 1.2rem !default;
$product-item-price-padding: 1.2rem $product-item-price-padding-right 1.2rem 2rem !default;
$product-item-details-size-key: sub !default;
$product-item-skeleton-bg: $grey !default;
$product-item-skeleton-loading-bg: $grey !default;
$product-item-leasing-size-key: sub !default;
$product-item-leasing-color: $black !default;
$product-item-shadow-color: $black !default;
$product-item-primary-default-size-key: medium !default;
$product-item-primary-desktop-size-key: large !default;
$product-item-primary-line-height-key: secondary !default;

$product-item-padding-desktop: 3rem;
$product-item-padding-touch: $gutter-small;

.product-item {
    box-shadow: 0 0 0 0 rgba($product-item-shadow-color, 0.2);
    transition-property: transform, box-shadow;
    transition-duration: $duration-fast;
    transition-timing-function: cubic-bezier(0.25, 0.75, 0.5, 1);
    backface-visibility: hidden;
    background-color: $product-item-bg;
    border: 1px solid $border;
    user-select: none;

    &:hover {
        box-shadow: 0 0 2rem rgba($product-item-shadow-color, 0.2);
    }

    &__skeleton {
        position: relative;
        background-color: $product-item-bg;
        overflow: hidden;

        &-loading {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            background-image: linear-gradient(
                to right,
                rgba($product-item-skeleton-loading-bg, 0) 0%,
                rgba($product-item-skeleton-loading-bg, 0) 40%,
                rgba($product-item-skeleton-loading-bg, 0.8) 50%,
                rgba($product-item-skeleton-loading-bg, 0) 60%,
                rgba($product-item-skeleton-loading-bg, 0) 100%
            );
            transform: translate3d(-100%, 0, 0);
            transform-origin: top left;
            animation: skeletonLoading 1.8s infinite;
        }
    }

    &__media {
        @include when-inside('.is-list-view') {
            flex: 0 1 44%;

            @include tablet {
                flex: 0 1 270px;
            }
        }
    }

    &__link {
        text-decoration: none;

        @include when-inside('.is-list-view') {
            display: flex;
            flex: 0 0 56%;
            flex-direction: column;
            justify-content: space-between;
            width: 56%;

            @include tablet {
                flex: 1 1 auto;
                width: auto;
            }
        }

        &:hover {
            text-decoration: none;
        }
    }

    &__media-wrapper {
        background-color: $product-item-media-bg;

        &--empty-text {
            position: absolute;
            inset: 0;

            @include when-inside('.is-list-view') {
                font-size: smaller;

                @include tablet {
                    font-size: small;
                }
            }
        }
    }

    &__media-tags {
        position: absolute;
        z-index: 3;
        top: $product-item-tags-vertical-padding;
        right: $product-item-tags-horizontal-padding;

        @include when-inside('.is-list-view') {
            position: static;

            @include tablet {
                position: absolute;
                top: 0;
                right: auto;
                left: 0;
            }
        }
    }

    &__title {
        font-size: map-get($font-sizes, $product-item-title-size-key);
        color: $product-item-title-color;
        padding-bottom: $product-item-title-padding;
        padding-top: 2rem;
        padding-left: $product-item-padding-touch;
        display: flex;
        flex-direction: column-reverse;

        @include when-inside('.is-list-view') {
            padding-top: 1rem;
            padding-bottom: 0;
            font-size: map-get($font-sizes, medium);
            line-height: 1.5;
        }

        @include tablet {
            gap: $gutter;
            display: inline-flex;
            flex-direction: row;
            align-items: center;
        }

        @include desktop {
            padding-left: $product-item-padding-desktop;
        }
    }

    &__subtitle {
        grid-column: 1 / span 2;
        padding-bottom: 2rem;
        padding-left: $product-item-padding-touch;
        color: $product-item-details-color;
        font-size: map-get($font-sizes, $product-item-details-size-key);
        line-height: $product-item-details-line-height;

        @include mobile {
            @include when-inside('.is-list-view') {
                font-size: smaller;
                padding-bottom: 0;
                line-height: 1.5;
            }
        }

        @include desktop {
            padding-left: $product-item-padding-desktop;
        }
    }

    &__header {
        @include tablet {
            display: grid;
            grid-template-columns: 1fr auto;
            grid-gap: 1rem;
        }
    }

    &__header-container {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;

        @include tablet {
            grid-row: 1;
            grid-column-start: 1;
            grid-column-end: 2;
        }
    }

    &__header-price {
        @include tablet {
            grid-row: 1;
            grid-column-start: 2;
            grid-column-end: 3;
        }
    }

    &__content {
        padding-bottom: $gutter-small;

        @include when-inside('.is-list-view') {
            padding-bottom: 0;
        }

        @include from($tablet) {
            padding-bottom: $product-item-info-vertical-padding-bottom;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;

            @include when-inside('.is-list-view') {
                padding-bottom: $product-item-info-vertical-padding-bottom;
            }
        }
    }

    &__favorite-button {
        width: 4.6rem;
        flex: 0 0 4.6rem;
        padding: 0.8rem;
        @include from($tablet) {
            width: 9rem;
            padding: 1.6rem 2rem;
            flex: 0 0 9rem;
        }
    }

    &__details {
        padding-left: $product-item-padding-touch;
        font-size: map-get($font-sizes, $product-item-details-size-key);
        color: $product-item-details-color;
        line-height: $product-item-details-line-height;

        @include desktop {
            padding-left: $product-item-padding-desktop;
        }

        &--skeleton {
            margin: 1rem;
            display: block;
            width: 60%;
            height: 2.4rem;
            background-color: $product-item-skeleton-bg;

            &:last-child {
                width: 50%;
            }
        }
    }
}

.product-pricing {
    &--skeleton {
        display: block;
        width: 13rem;
        height: 5rem;
        background-color: darken($product-item-skeleton-bg, 5%);
    }

    &__primary {
        @include typeset($product-item-primary-default-size-key, medium, $product-item-primary-line-height-key);

        display: inline-block;
        padding: $product-item-price-padding;
        background-color: $product-item-price-bg;
        color: $product-item-price-color;

        @include mobile {
            padding-left: $product-item-price-padding-right;
            margin-top: $gutter-small;
            margin-left: $gutter-small;
        }

        @include fullhd {
            font-size: map-get($font-sizes, $product-item-primary-desktop-size-key);
            text-align: right;
        }
    }

    &__secondary {
        padding-top: $product-item-price-secondary-padding;
        padding-right: $product-item-price-padding-right;
        padding-bottom: $product-item-price-secondary-padding;
        color: $product-item-leasing-color;
        font-size: map-get($font-sizes, $product-item-leasing-size-key);
        line-height: 1;

        @include mobile {
            padding-left: $gutter-small;
        }

        @include when-inside('.is-list-view') {
            padding-right: $gutter;
            text-align: right;
        }
    }

    &__vat {
        @include typeset(small, base, $product-item-primary-line-height-key);
    }
}

.is-list-view {
    @include mobile {
        .product-pricing {
            &__primary,
            &__secondary,
            &__vat {
                @include typeset(small, base, $product-item-primary-line-height-key);
            }

            &__vat {
                display: inline-block;
            }
        }
    }
}

@keyframes skeletonLoading {
    to {
        transform: translate3d(100%, 0, 0);
    }
}
