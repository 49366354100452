@import 'anchor-navigation-spot';
@import 'dual-image-spot';
@import 'spot-content';
@import 'byd-hero-spot';
@import 'extended-hero-spot';
@import 'hero-spot';
@import 'hero-slider-spot';
@import 'image-text-picker-spot';
@import 'specifications-spot';
@import 'motorsize-spot';
@import 'headline-spot';
@import 'equipment-packages-spot';
@import 'legal';
@import 'models-list-spot';
@import 'expansion-panel';
@import 'accordion-spot';
@import 'model-prices-spot';
@import 'gallery-spot';
@import 'tabs';
@import 'map-spot';
@import 'all-models-spot';
@import 'subnavigation-spot';
@import 'carousel-spot';
@import 'carousel-spot-item';
@import 'campaigns-spot';
@import 'filter-spot';
@import 'featured-cars-spot';
@import 'cta-spot';
@import 'model-page-navigation-spot';
@import 'model-viewer';
@import 'model-viewer-lead';
@import 'model-viewer-interior';
@import 'model-viewer-exterior';
@import 'video-spot';
@import 'timeline-spot';

.spot {
    display: block;
    width: 100%;
    padding-top: $spot-padding-top;
}
