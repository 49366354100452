$map-size-key: sub !default;
$map-family-key: small !default;
$map-lineheight-key: small !default;
$map-list-bg: $very-light-pink !default;
$map-has-border: false !default;
$map-text-color-dark: $blue-darker !default;
$list-padding-horizontal: 2rem !default;
$list-padding: 1.6rem $list-padding-horizontal !default;
$map-spot-letter-key: large !default;
$map-header-height: 5.2rem !default;
$map-space-height: 1.2rem !default;
$map-list-height: 60.4rem !default;
$map-height: $map-header-height + $map-list-height + ($map-space-height * 2) !default;
$map-item-icon-width: 4rem !default;
$map-item-bg: $very-light-pink !default;
$map-item-bg-active: rgba($black, 0.05) !default;
$map-item-bg-hover: rgba($black, 0.07) !default;
$map-address-color: inherit !default;
$map-address-line-height: inherit !default;
$map-address-padding-top: 0 !default;
$map-address-padding-bottom: 0 !default;
$map-name-size-key: sub !default;
$map-name-family-key: medium !default;
$map-name-letter-key: large !default;
$map-name-official-size-key: tiny !default;
$map-name-text-transform: none !default;
$map-name-official-family-key: bold !default;
$map-opening-title-size-key: sub !default;
$map-opening-title-weight-key: base !default;
$map-opening-title-transform: none !default;
$map-opening-title-color: $primary !default;
$map-opening-title-family-key: secondaryMedium !default;
$map-opening-title-margin-bottom: inherit !default;
$map-phone-title-display: inline-block !default;
$map-phone-title-family-key: base !default;
$map-phone-title-size-key: sub !default;
$map-phone-title-weight-key: base !default;
$map-phone-title-transform: inherit !default;
$map-phone-title-color: inherit !default;
$map-phone-title-margin-bottom: inherit !default;
$map-phone-title-padding-top: 0 !default;
$map-phone-title-padding-bottom: 0 !default;
$map-button-search-min-width: 14rem !default;
$map-result-padding: 0.6rem !default;
$map-toggle-button-color: $grey !default;

.gm-style {
    .opening {
        .columns {
            margin-bottom: 0;
        }
    }
}

.location-marker {
    @include typeset(sub, secondary, small);

    a {
        &:not(.button) {
            $map-link-color: $primary !default;
            color: $map-link-color;
        }
    }
}

.opening {
    min-width: 21rem;
    line-height: 1.5;
    margin-bottom: 1rem;

    &__title {
        font-size: map-get($font-sizes, $map-opening-title-size-key);
        font-family: map-get($font-families, $map-opening-title-family-key);
        font-weight: map-get($font-weights, $map-opening-title-weight-key);
        color: $map-opening-title-color;
        text-transform: $map-opening-title-transform;
        margin-bottom: $map-opening-title-margin-bottom;
    }

    &__day {
        text-transform: lowercase;

        &::first-letter {
            text-transform: capitalize;
        }
    }

    .columns.is-gapless:not(:last-child),
    .columns:not(:last-child) {
        margin-bottom: 0;
    }
}

.map-spot {
    @include typeset($map-size-key, $map-family-key, $map-lineheight-key);
    letter-spacing: map-get($letter-spacings, $map-spot-letter-key);
    margin-bottom: 0.5rem;

    .gm-style .gm-style-iw-c {
        border-radius: 0 !important;
        padding: 0 !important;
    }

    .gm-style .gm-style-iw-d {
        padding: 1rem 1.5rem !important;
        max-height: inherit !important;
        overflow: auto !important;

        @include from($tablet) {
            padding: 1rem 3rem !important;
        }
    }

    .notification {
        @include when-inside('.grid__theme--dark') {
            color: $primary;
            background-color: $white;
        }
    }

    .map-form {
        > .columns {
            flex-direction: column;
            align-items: center;
        }

        &__sort {
            @include touch {
                > .field {
                    display: flex;
                    flex-flow: wrap row;

                    &.is-grouped > .control:not(:last-child) {
                        margin-right: 2rem;
                    }
                }
            }

            .icon {
                svg {
                    @include when-inside('.grid__theme--dark') {
                        filter: drop-shadow(0 2px 1px $black);
                    }
                }
            }
        }
    }

    .map-search {
        text-align: center;

        > .is-grouped {
            @include touch {
                flex-direction: row;
            }
        }

        &.column {
            padding-bottom: 0;
        }

        @include touch {
            .field.is-grouped > .control:not(:last-child) {
                margin-right: 1rem;
            }

            .button {
                min-width: 10rem;
            }
        }

        &__input {
            @include until($mobile-small) {
                min-width: 20rem;
            }

            @include from($mobile-small) {
                min-width: 25rem;
            }

            @include from($tablet) {
                min-width: 30rem;
            }

            // iOS does not work with display none
            &::-webkit-list-button {
                opacity: 0;
                padding: 0;
                width: 0;
            }

            &::-webkit-calendar-picker-indicator {
                display: none !important;
            }
        }

        .button {
            &.is-search {
                min-width: $map-button-search-min-width;

                @include until($mobile-large) {
                    min-width: auto;
                }
            }
        }
    }

    .map-row {
        height: $map-height;
        background-color: $map-list-bg;
    }

    .map-toggle {
        margin-bottom: 1.6rem;
        border-top: 1px solid $border;
        border-bottom: 1px solid $border;

        @include touch {
            margin-left: -$column-gap;
            margin-right: -$column-gap;
        }

        .buttons:last-child {
            margin-bottom: 0;
        }

        .button {
            font-family: map-get($font-families, base);
            padding-top: 1rem;
            padding-bottom: 1rem;
            padding-left: 1.5rem;
            padding-right: 1rem;
            color: $map-toggle-button-color;
            transition: box-shadow $duration-button ease-in-out;
            box-shadow: inset 0 0 0 $primary;

            &.is-active {
                color: $primary;
                box-shadow: inset 0 -3px 0 $primary;
            }

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            // Map icon works with stroke, not fill, for some reason
            .map-icon {
                stroke: currentColor;
                // Get rid of space beneath icon for text descenders, to properly align with button text
                svg-icon-sprite {
                    line-height: 0;
                }
            }
        }
    }

    .map-col {
        position: relative;

        @include when-inside('.grid__theme--dark') {
            color: $text;
        }

        @include touch {
            position: absolute;
            left: 0;
            right: 0;
            visibility: hidden;
            opacity: 0;
            z-index: 1;
            transition: opacity $duration-link ease-in-out;

            &.is-shown {
                visibility: visible;
                opacity: 1;
                z-index: 2;
            }
        }

        &--1 {
            @if $map-has-border {
                border: 1px solid $border;
            }
        }

        &--2 {
            &::before {
                content: '';
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: $map-list-bg;
                pointer-events: none;
                transform-origin: left center;
                transform: scale3d(1, 1, 1);
                transition: transform $duration-medium ease-in-out;
                transition-delay: 200ms;
            }

            &.is-visible {
                opacity: 1;

                &::before {
                    transform: scale3d(0, 1, 1);
                }
            }

            @if $map-has-border {
                border-right: 1px solid $border;
                border-top: 1px solid $border;
                border-bottom: 1px solid $border;

                @include until($desktop) {
                    border-left: 1px solid $border;
                }
            }
        }
    }

    .map-list {
        background-color: $map-list-bg;

        &__header {
            @if $map-has-border {
                height: 5rem;
            } @else {
                height: 5.2rem;
            }

            display: flex;
            align-items: center;
            border-bottom: 1px solid $border;

            &--result {
                @include sub-heading;
                margin-bottom: 0;
                padding: 0 $list-padding-horizontal;
                color: $map-text-color-dark;
                letter-spacing: map-get($letter-spacings, large);

                @include desktop {
                    padding-top: $map-result-padding;
                }
            }
        }

        &__space {
            display: block;
            height: 1.2rem;
        }

        &__items {
            margin-right: 0.6rem;
            height: $map-list-height;
            overflow-y: auto;
            overflow-x: hidden;

            &::-webkit-scrollbar {
                width: 0.8rem;
                height: 0.8rem;
            }

            &::-webkit-scrollbar-track {
                border-radius: 0.4rem;
            }

            &::-webkit-scrollbar-thumb {
                background: $scrollbar-thumb-bg;
                border-radius: 0.4rem;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: $scrollbar-thumb-bg;
            }
        }
    }

    .map-item {
        position: relative;
        padding: $list-padding;
        border-bottom: 1px solid $border;
        cursor: pointer;
        background-color: $map-item-bg;
        transition: backgroundColor $duration-button ease-in-out;

        &--is-active {
            background-color: $map-item-bg-active;
        }

        &:hover {
            background-color: $map-item-bg-hover;
        }

        .columns:not(:last-child) {
            margin-bottom: 0;
        }

        &:last-child {
            border-bottom: 0;
        }

        a {
            &:not(.button) {
                $map-link-color: $primary !default;
                color: $map-link-color;
            }
        }

        &__icon {
            padding-top: 0.1rem;
            padding-right: 1.4rem;
            flex: 0 0 $map-item-icon-width;
        }

        .expansion-panel {
            &__header {
                display: inline-flex;
                @include typeset(sub, secondary, small);
                color: $primary;
                text-decoration: underline;
            }

            &__icon {
                flex: 0 0 auto;
                padding-left: 0.5rem;
            }
        }

        &__content {
            padding-right: 0.6rem;

            &--name {
                font-size: map-get($font-sizes, $map-name-size-key);
                font-family: map-get($font-families, $map-name-family-key);
                color: $map-text-color-dark;
                line-height: map-get($line-heights, secondary);
                letter-spacing: map-get($letter-spacings, $map-name-letter-key);
                text-transform: $map-name-text-transform;

                &--official {
                    @include typeset($map-name-official-size-key, $tiny-heading-family-key);
                    margin-bottom: 0.4rem;
                    color: $default-theme-text;
                    text-transform: none;
                }
            }

            &--address {
                padding-top: $map-address-padding-top;
                padding-bottom: $map-address-padding-bottom;
                font-style: normal;
                color: $map-address-color;
                line-height: $map-address-line-height;

                &:last-child {
                    margin-bottom: 0.2rem;
                }
            }
        }

        &__direction-link {
            @include typeset(small, secondary, secondary);

            @include until($desktop) {
                font-size: map-get($font-sizes, sub);
            }

            @include mobile {
                display: block;
                padding-top: 1rem;
                padding-bottom: 1rem;
            }

            &--inline {
                font-size: map-get($font-sizes, sub);
            }
        }

        &__info {
            position: relative;
            z-index: 1;
            padding-left: $map-item-icon-width;

            &--contact {
                padding: 1rem 2rem 1rem 0;
            }
        }

        &__box {
            padding-bottom: 1.4rem;
        }

        &__brands {
            width: 100%;

            .columns {
                @include tablet {
                    justify-content: flex-end;
                }

                &.is-variable {
                    .column {
                        padding-bottom: var(--columnGap);
                        padding-top: var(--columnGap);
                    }
                }
            }
        }

        &__brand-image {
            img {
                vertical-align: middle;
            }

            &:not(:first-child) {
                > img {
                    margin-left: 0.8rem;
                }
            }
        }
    }

    .map-phone {
        &__title {
            display: $map-phone-title-display;
            font-family: map-get($font-families, $map-phone-title-family-key);
            font-size: map-get($font-sizes, $map-phone-title-size-key);
            font-weight: map-get($font-weights, $map-phone-title-weight-key);
            text-transform: $map-phone-title-transform;
            color: $map-phone-title-color;
            margin-bottom: $map-phone-title-margin-bottom;
            padding: $map-phone-title-padding-top 0 $map-phone-title-padding-bottom;
            white-space: pre;
        }

        &__number {
            text-decoration: none;
        }
    }

    .map {
        position: relative;
        background-color: lighten($map-list-bg, 2%);
        height: $map-height;
    }

    .map-world {
        height: $map-height;
        overflow: hidden;

        @if $map-has-border {
            @include desktop {
                padding: 2rem;
            }
        }
    }
}

.map-opening-hours-link {
    padding: 1rem 0 0;

    a {
        color: $red !important;
    }
}
