$extended-hero-spot-content-width: 862px;

.extended-hero-spot {
    @include is-full;

    &__container {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        color: $white;
        aspect-ratio: 1;

        @include tablet {
            aspect-ratio: 21 / 9;
        }
    }

    &__link {
        position: absolute;
        inset: 0;
        z-index: 3;
        opacity: 0;
    }

    &__media-container {
        position: absolute;
        overflow: hidden;
        background-color: $black;
        inset: 0;

        img,
        video {
            position: absolute;
            width: 100%;
            max-width: none;
            height: 100%;
            object-fit: cover;
        }

        video {
            pointer-events: none;
        }

        &--media-filter {
            img,
            video {
                opacity: 0.8;
            }
        }
    }

    &__content {
        z-index: 2;
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: space-between;
        padding: 1.9rem 2.5rem 2.1rem;
        text-align: center;

        @include tablet {
            display: block;
            max-width: $extended-hero-spot-content-width;
            padding: 4% 0 0 3%;
            text-align: left;
        }

        @include desktop {
            padding: 7% 0 0 7%;
        }
    }

    &__title {
        font-family: map-get($font-families, medium);
        font-size: map-get($font-sizes, largestMobile);
        font-weight: map-get($font-weights, base);
        line-height: map-get($line-heights, small);
        letter-spacing: map-get($letter-spacings, large);
        margin-bottom: 1rem;

        @include desktop {
            font-size: map-get($font-sizes, largest);
        }

        @include fullhd {
            font-size: map-get($font-sizes, huge);
        }
    }

    &__subtitle {
        font-family: map-get($font-families, medium);
        font-size: map-get($font-sizes, base);
        font-weight: map-get($font-weights, base);
        line-height: map-get($line-heights, secondary);
        letter-spacing: map-get($letter-spacings, large);
        margin-bottom: 2rem;

        @include desktop {
            font-size: map-get($font-sizes, larger);
        }
    }

    &__price {
        font: 1.6rem/1.25 #{map-get($font-families, text)};

        @include desktop {
            font-size: 2.5rem;
        }
    }

    &__cta {
        display: flex;
        gap: $gutter;
        justify-content: center;

        @include tablet {
            justify-content: flex-start;
            margin-top: 3rem;
        }

        @include desktop {
            margin-top: 6rem;
        }
    }

    &__overlay {
        position: absolute;
        z-index: 1;
        opacity: 0.8;
        mix-blend-mode: overlay;
        background-image: linear-gradient(to top, $black, rgba($black, 0));
        inset: auto 0 0 0;
        height: 16rem;
    }

    &__legal {
        position: relative;
        z-index: 2;
        padding: 0 3.2rem 2.4rem;

        @include desktop {
            padding-top: 0.5rem;
        }

        > ncg-legal {
            padding-right: 0;

            .legal {
                display: inline;
            }
        }

        ncg-energy-label {
            svg {
                width: 35px;
                height: 12px;
            }
        }

        .legal {
            color: $white;

            b {
                font-size: inherit;
                font-weight: bold;
            }
        }

        &.legal-text-moved {
            @media (max-width: 768px) {
                > ncg-legal .legal {
                    color: $black;

                    b {
                        font-size: inherit;
                        font-weight: bold;
                    }
                }
            }
        }

        &.legal-text-moved-desktop {
            @include tablet {
                > ncg-legal .legal {
                    color: $black;

                    b {
                        font-size: inherit;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
