$navbar-item-color: $brownish-grey;
$navbar-item-color-invert: $blue-light;
$navbar-item-color-invert-hover: $white;
$navbar-item-hover-color: $primary;
$navbar-item-active-color: $primary;
$navbar-item-hover-background-color: transparent;
$navbar-item-active-background-color: transparent;
$navbar-item-padding-side: 1.4rem;
$navbar-background-color: transparent;
$navbar-item-img-max-height: 2.1rem;

@import 'bulma/sass/components/navbar';

:root {
    --navbar-item-padding-side: #{$navbar-item-padding-side};
}

.navbar {
    .navbar-filter {
        position: relative;
    }

    .external:focus {
        color: $navbar-item-color;
    }

    .navbar-item,
    .navbar-link {
        text-decoration: none;
        @include typeset(sub);
        @extend %link-color-hover;
        padding: 0.5rem $navbar-item-padding-side;

        &.has--icon {
            display: flex;
            flex-flow: wrap column;
        }

        &.is-active {
            color: $primary;
        }

        &--icon {
            display: flex;
            padding-bottom: 0;
        }

        &:hover {
            color: $primary;
        }
    }

    &.invert {
        .navbar-item,
        .navbar-link {
            color: $navbar-item-color-invert;

            &:hover,
            &.is-active {
                color: $navbar-item-color-invert-hover;
            }
        }
    }

    .active-bar {
        position: absolute;
        bottom: -1px;
        width: 100%;
        height: 3px;
        background-color: $primary;
        z-index: 9;
    }
}
