$container-padding-touch: 1rem !default;
$container-padding-desktop: 2.5rem !default;

/****** ELEMENTS *******/
@import 'bulma/sass/elements/container';

@mixin is-fullwidth() {
    width: auto;
    max-width: $fullwidth !important;
    padding: 0 $container-padding-touch;

    @include desktop {
        padding: 0 $container-padding-desktop;
    }
}

.container {
    display: block;

    &.is-fullwidth {
        @include is-fullwidth();
    }

    // Useful for making content inside of container go 100vw
    // Usecase: Hero needs to break our "normal" container width
    &.is-full {
        @include is-full;
    }
}
