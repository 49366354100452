$product-sorting-amount-padding: 1rem !default;
$product-sorting-amount-size-key: base !default;
$product-sorting-size-key: base !default;
$product-sorting-family-key: base !default;
$product-sorting-weight-key: normal !default;
$product-sorting-color: $black-bis !default;
$product-sorting-letter-key: $button-letter-space-key !default;
$product-sorting-text-transform: inherit !default;
$selected-filter-btn-padding-left: 1.6rem !default;
$selected-filter-btn-padding-right: 0.8rem !default;
$selected-filter-btn-color: $primary !default;

.product-sorting {
    padding-top: 3rem;
    color: $product-sorting-color;

    @include touch {
        margin-top: 1.5rem;
        padding-top: 1.5rem;
        border-top: 1px solid $border;
    }

    &--small-padding {
        padding-top: 0;
    }

    &__btn-my-profile {
        white-space: nowrap;
        width: $button-height-normal;

        &.is-rounded.is-primary {
            padding: 0;

            svg {
                margin: 0;
            }

            span:first-child {
                margin: 0;
            }
        }
    }

    &__item {
        padding: 2rem 2rem 0.6rem;
    }

    &__text {
        @include typeset($product-sorting-size-key, $product-sorting-family-key, base, $product-sorting-weight-key);
        letter-spacing: map-get($letter-spacings, $product-sorting-letter-key);
        text-transform: $product-sorting-text-transform;
    }

    &__amount {
        @include typeset($product-sorting-amount-size-key, $product-sorting-family-key, base, $product-sorting-weight-key);
        margin-right: auto;

        @include desktop {
            padding-top: $product-sorting-amount-padding;
            margin-right: unset;
        }
    }

    &__button {
        font-size: map-get($font-sizes, base);

        &--text {
            min-width: 11rem;
            display: inline-block;
            color: $product-sorting-color;
            letter-spacing: map-get($letter-spacings, $product-sorting-letter-key);
            text-transform: $product-sorting-text-transform;
        }
    }

    &__view-btn {
        width: $icon-default-size !important;
        height: $icon-default-size !important;

        &.has-space-right {
            margin-right: 1rem;
        }
    }

    &__sticky-buttons {
        position: fixed;
        top: 2rem;
        left: 0;
        right: 0;
        z-index: 3;
        justify-content: center;
        gap: 2rem;
    }

    &__filter-button {
        min-height: $button-height-small;
        height: $button-height-small;
        // specificity
        &.is-primary,
        &.is-filter {
            border-radius: 10rem;
        }

        &.is-filter {
            color: $product-sorting-color;
        }

        &.is-primary {
            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border-radius: inherit;
                box-shadow: 0 0 0 2px $white;
            }
        }
    }
}

.selected-filter {
    position: relative;
    display: flex;
    flex-flow: wrap row;
    overflow: hidden;
    min-height: 5rem;

    &__item {
        position: relative;
        z-index: 2;

        > button.button {
            height: 4rem;
            min-height: 4rem;
            padding-left: $selected-filter-btn-padding-left;
            padding-right: $selected-filter-btn-padding-right;
            margin: 0.5rem 1.4rem 0.5rem 0;

            > img {
                margin-left: 1rem;
                width: 2.4rem;
                height: 2.4rem;
            }
        }
    }

    &__clear {
        > button.button {
            padding-right: $selected-filter-btn-padding-left;
            color: $selected-filter-btn-color;
        }
    }
}
