.dual-image-spot {
    position: relative;
    right: 50%;
    left: 50%;
    width: 100vw;
    max-width: none;
    margin-right: -50vw;
    margin-left: -50vw;

    &__wrapper {
        aspect-ratio: 16 / 7;
    }

    &__primary {
        width: 92.59259%;

        @include tablet {
            width: 74.07407%;
        }

        @include desktop {
            width: 77.77778%;
        }
    }

    &__secondary {
        width: 59.25926%;
        margin-top: -4%;
        margin-left: 40.74074%;

        @include tablet {
            width: 37.03704%;
            margin-top: -16%;
            margin-left: 62.96296%;
        }

        @include desktop {
            margin-top: -20%;
        }

        @include widescreen {
            margin-top: -30%;
        }
    }

    &--top-left {
        .dual-image-spot {
            &__wrapper {
                display: flex;
                flex-direction: column-reverse;
            }

            &__primary {
                margin-top: -4%;
                margin-left: 7.40741%;

                @include tablet {
                    margin-top: -16%;
                    margin-left: 25.92593%;
                }

                @include desktop {
                    margin-top: -20%;
                    margin-left: 22.22222%;
                }

                @include widescreen {
                    margin-top: -30%;
                }
            }

            &__secondary {
                margin-top: 0;
                margin-left: 0;

                @include tablet {
                    margin-top: 0;
                    margin-left: 0;
                }

                @include desktop {
                    margin-top: 0;
                }

                @include widescreen {
                    margin-top: 0;
                }
            }
        }
    }
}
