$filter-facets-tab-padding: 1rem !default;
$filter-facets-title-size-key: navigation !default;
$filter-facets-title-weight-key: base !default;
$filter-facets-title-color: inherit !default;

.filter-facets {
    &__item {
        @include when-inside('.filter-sidepanel') {
            padding: 0 $gutter-small;

            @include from($tablet) {
                padding: 0.7rem 3.5rem 0.3rem $filter-padding-left;
            }
        }
    }

    &__title {
        font-size: map-get($font-sizes, $filter-facets-title-size-key);
        font-weight: map-get($font-weights, $filter-facets-title-weight-key);
        color: $filter-facets-title-color;
    }

    &__tab {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $filter-facets-tab-padding;
        padding: calc(2 * var(--columnGap));

        &--make {
            flex-direction: column;
        }
    }
}
