.mobile-menu {
    &__parent {
        .mobile-menu {
            &__item {
                text-transform: uppercase !important;
            }
        }
    }

    &__title {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
    }

    // This should match the value from the mobile-menu-trigger directive
    nav {
        height: calc(100% - 65px);
    }

    &__nav-icon {
        stroke: $black;
    }
}
