$consent-text-color: inherit !default;
$consent-text-color-hover: inherit !default;

.consent {
    &__text {
        @include body-copy-small;
        color: $consent-text-color;

        * {
            color: inherit !important; // we have to overwrite inline styling from NCG API markup
        }

        &--default {
            text-decoration: underline;

            &:hover {
                color: $consent-text-color-hover !important;
            }
        }
    }

    &--center {
        display: flex;
        justify-content: center;
    }

    @include when-inside('.side-panel') {
        justify-content: flex-start;
    }
}

.legal-text {
    .rte-content {
        @include body-copy-small;
        text-align: center;
        color: $brown-grey;

        p {
            margin-bottom: 0.25rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
