$model-border-width: 0.4rem !default;

.model-lead {
    &-swiper {
        overflow: visible;
        width: 5rem;

        .swiper-slide {
            width: 4.8rem;

            @include mobile {
                width: 2.4rem;
            }

            &:not(:last-child) {
                margin-right: 1rem;
            }
        }
    }

    &-image {
        user-select: none;
        background-color: $black;

        &:hover {
            > img {
                opacity: 0.8;
            }
        }

        > img {
            transition: opacity $duration-fast $smooth-in;
            width: 4.8rem;
            height: 4.8rem;

            @include mobile {
                width: 3rem;
                height: 3rem;
            }
        }
    }

    &-marker {
        position: absolute;
        top: -0.5rem;
        right: 0;
        z-index: 1;
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 50%;
        background-color: $primary;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition:
            opacity $duration-fast $smooth-in,
            transform $duration-medium $smooth-in-out;
        transform: translate3d(0, 1rem, 0);

        &.is-active {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    &-icon {
        color: $white;

        &.is-active {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    &__title {
        font-family: map-get($font-families, base);
    }
}
