.equipment-packages-spot {
    &__item {
        &-title {
            @include secondary-heading;

            @include mobile {
                @include tertiary-heading;
            }

            @include when-inside('.grid__theme--default') {
                color: $rte-default-theme-text;
            }

            &-container {
                display: flex;

                .models-list {
                    &__header {
                        &__tag {
                            top: 3px;
                            left: 0.5rem;
                            position: relative;
                        }
                    }
                }
            }
        }

        &-price {
            @include body-copy;
            margin-bottom: 2rem;
            color: $brownish-grey;

            @include mobile {
                @include body-copy-small;
            }

            @include when-inside('.grid__theme--dark') {
                color: $rte-dark-theme-text;
            }

            &__container {
                display: block;
            }

            &--line-through {
                margin-bottom: 0.2rem;
                text-decoration: line-through;
            }

            &--bold {
                font-weight: $font-weight-bold;
            }
        }

        &-include {
            font-size: map-get($font-sizes, sub);
        }

        &-description-list {
            padding-bottom: 1rem;

            &-item {
                padding: 0.2rem 0;

                svg {
                    color: $primary;
                    margin-right: 0.9rem;

                    @include when-inside('.grid__theme--dark') {
                        color: $white;
                    }
                }
            }
        }
    }

    &__text {
        padding-bottom: 2rem;
    }

    &__button {
        text-align: center;

        a {
            @include desktop {
                padding: 0 7rem;
            }
        }
    }
}
