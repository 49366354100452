@use 'sass:list';
@use 'sass:math';

@function strip-unit($num) {
    @return math.div($num, ($num * 0 + 1));
}

@function px-to-em($pixels, $base: $font-size-base) {
    // $base could be in em or px (no unit = px).
    // Adjust accordingly to create a $divisor that
    // serves as context for $pixels.
    $multiplier: if(unit($base) ==em, $font-size-base, 1);
    $divisor: strip-unit($base) * $multiplier;

    $em-vals: ();
    @each $val in $pixels {
        $val-in-ems: (strip-unit($val) / $divisor) * 1em;
        $em-vals: append($em-vals, $val-in-ems);
    }

    @if length($em-vals) ==1 {
        // return a single value instead of a list,
        // so it can be used in calculations
        @return nth($em-vals, 1);
    } @else {
        @return $em-vals;
    }
}

@function px-to-rem($pixels, $base: 10px) {
    $rem-vals: ();
    @each $val in $pixels {
        $val-in-rems: math.div(strip-unit($val), strip-unit($base)) * 1rem;
        $rem-vals: append($rem-vals, $val-in-rems);
    }

    @if length($rem-vals) ==1 {
        // return a single value instead of a list,
        // so it can be used in calculations
        @return nth($rem-vals, 1);
    } @else {
        @return $rem-vals;
    }
}

@function negative($input) {
    $result: ();
    @each $value in $input {
        $result: list.append($result, $value * -1);
    }
    @return $result;
}

// Truncate text with ellipsis
@mixin truncate-text($ellipsis: true) {
    white-space: nowrap;
    overflow: hidden;

    @if ($ellipsis == true) {
        text-overflow: ellipsis;
    }
}

@mixin when-inside($contexts...) {
    @each $context in $contexts {
        #{$context} & {
            @content;
        }
    }
}

@mixin ul-reset {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}

@mixin no-outline {
    &:focus {
        outline: 0;
    }

    &::-moz-focus-inner {
        border: 0;
    }
}

@mixin center-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin aspect-ratio($defaultRatio, $mobileRatio: false, $focalpoint: topleft, $fitElement: false) {
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        width: 100%;
        display: block;

        @if ($mobileRatio) {
            @include until($tablet) {
                padding-bottom: $mobileRatio;
            }

            @include from($tablet) {
                padding-bottom: $defaultRatio;
            }
        } @else {
            padding-bottom: $defaultRatio;
        }
    }

    > img,
    > ncg-product-item-carousel,
    > a,
    > div:not(.swiper-lazy-preloader),
    video {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        box-sizing: border-box;

        @if $fitElement {
            right: 0;
            bottom: 0;
            height: 100%;
            object-fit: cover;
        }

        @if ($focalpoint == centercenter) {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

@mixin mobile-landscape {
    @media screen and (max-width: 812px) and (orientation: landscape) {
        @content;
    }
}

@mixin safari-only {
    // targeting Safari 9+
    // see https://browserstrangeness.bitbucket.io/css_hacks.html#safari
    // or https://stackoverflow.com/questions/16348489/is-there-a-css-hack-for-safari-only-not-chrome/25975282#25975282
    @supports (-webkit-hyphens: none) {
        @content;
    }
}

@mixin is-full {
    width: 100vw;
    max-width: none !important;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

@mixin scroll-fade-bg($color, $direction: left) {
    background: linear-gradient(to #{$direction}, rgba($color, 0) 0%, rgba($color, 1) 100%);
}

@mixin scroll-fade($bg, $direction: left, $zIndex: 1, $width: 2rem) {
    content: '';
    position: absolute;
    z-index: $zIndex;
    top: 0;
    bottom: 0;
    width: $width;
    pointer-events: none;

    @if (type-of($bg) == 'color') {
        @include scroll-fade-bg($bg, $direction);
    }

    @if (type-of($bg) == 'map') {
        @each $selector, $color in $bg {
            @if ($selector == '') {
                @include scroll-fade-bg($color, $direction);
            } @else {
                @include when-inside('#{$selector}') {
                    @include scroll-fade-bg($color, $direction);
                }
            }
        }
    }

    @include touch {
        top: 1px;
        bottom: 1px;
    }

    @if $direction == 'left' {
        left: 0;
    }

    @if $direction == 'right' {
        right: 0;
    }
}
