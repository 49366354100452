$campaigns-spot-label-color: $black-bis !default;
$campaigns-spot-price-color: $black !default;
$campaigns-spot-content-color: $grey-dark !default;
$campaigns-spot-title-padding: 0 !default;

.campaign-spot-item {
    &__title {
        padding-bottom: $campaigns-spot-title-padding;
    }

    &__content {
        margin-top: 2rem;

        .rte-content {
            white-space: pre-line;
            color: $campaigns-spot-content-color;
        }
    }

    &__prices {
        margin-top: 1rem;
    }

    &__price {
        &--label {
            @include typeset(x-small, bold);
            font-weight: map-get($font-weights, bold);
            color: $campaigns-spot-label-color;
        }

        &--value {
            @include secondary-heading-mobile;
            color: $campaigns-spot-price-color;
            padding-top: 0.4rem;
        }
    }

    &__actions {
        text-align: left;
    }
}
