$product-list-progress-max-width: 38rem !default;
$product-list-progress-height: 0.6rem !default;
$product-list-progress-bg: $grey-light !default;
$product-list-progress-bar-bg: $primary !default;

.product-list {
    position: relative;
    min-height: calc(100vh - #{$header-height-mobile});

    @include desktop {
        min-height: calc(100vh - #{$header-height-desktop});
    }

    &--full {
        padding: 0;

        @include until($desktop) {
            padding-top: 2rem;
        }
    }

    &--panel {
        @include touch {
            padding-top: 0;
        }
    }

    @include when-inside('.product-list--full') {
        &__item {
            &.is-one-third-fullwidth {
                @include from($fullwidth) {
                    width: 33.3333333333% !important;
                }
            }
        }
    }

    &__facets {
        position: sticky;
        top: 0;
        left: 0;
        width: 36.8rem;
        padding: 0 $gutter-small $gutter;
        border: 1px solid $border;
        border-top-color: transparent;
        height: 100vh;
        overflow-y: auto;

        .filter-facets {
            &__item {
                padding: 0 $gutter-small;
            }
        }
    }

    &__loading {
        position: sticky;
        top: 14rem;
        left: 0;
        right: 0;
        z-index: $index-body-overlay;
        padding-top: 6rem;

        .spinner {
            width: 5rem;
            height: 5rem;
        }
    }

    &__bottom {
        padding-top: $gutter;
        padding-bottom: $gutter;
        font-size: map-get($font-sizes, sub);

        @include desktop {
            padding-top: 8rem;
            padding-bottom: $section-padding-vertical;
        }
    }

    &__empty {
        padding-top: 10rem;

        &-title {
            padding-bottom: 2rem;
        }
    }

    &__progress {
        position: relative;
        max-width: $product-list-progress-max-width;
        margin: 2rem auto $gutter auto;
        background-color: $product-list-progress-bg;
        height: $product-list-progress-height;
    }

    &__bar {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        transform-origin: top left;
        transition: transform $duration-medium $smooth-in-out;
        height: $product-list-progress-height;
        background-color: $product-list-progress-bar-bg;
    }

    .column {
        &.is-filter-padding {
            padding: 0 $gutter !important;

            @include touch {
                padding: 0 $gutter-small !important;
            }
        }
    }
}
