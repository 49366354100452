$slime-border-color: $grey-lighter !default;
$slime-font-size: base !default;
$slime-font-family: text !default;
$slime-font-line-height: secondary !default;
$slime-font-weight: base !default;
$slime-small-font-size: sub !default;
$slime-small-font-family: text !default;

.all-models {
    @include until-fullhd {
        &__scroll {
            position: relative;

            &--left {
                &::before {
                    @include scroll-fade($background, left);
                    z-index: 2;
                }
            }

            &--right {
                &::after {
                    @include scroll-fade($background, right);
                    z-index: 2;
                }
            }

            &--both {
                &::before {
                    @include scroll-fade($background, left);
                    z-index: 2;
                }

                &::after {
                    @include scroll-fade($background, right);
                    z-index: 2;
                }
            }
        }
    }

    &.column {
        position: relative;
        padding-top: 0;

        @include until($desktop) {
            padding: 0;

            &::before,
            &::after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                height: 1px;
                background-color: $border;
            }

            &::before {
                bottom: 0;
            }

            &::after {
                top: 0;
            }
        }
    }
}

.all-models-filter {
    position: relative;
    margin: 0 auto;

    @include desktop {
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 1px;
            background-color: $slime-border-color;
        }
    }

    @include until-fullhd {
        display: flex;
        overflow: hidden;
        overflow-x: auto;
    }

    button.is-filter {
        @include typeset($slime-small-font-size, $slime-small-font-family);

        position: relative;
        z-index: 1;
        padding-left: 0.75rem;
        padding-right: 0.75rem;

        @include touch {
            &:first-child {
                padding-left: 2.5rem;
            }

            &:nth-last-child(2) {
                padding-right: 2.5rem;
            }
        }

        @include desktop {
            @include typeset($slime-font-size, $slime-font-family, $slime-font-line-height, $slime-font-weight);

            padding-left: 1.5rem;
            padding-right: 1.5rem;

            &:first-child {
                padding-left: 0;
            }

            &:nth-last-child(2) {
                padding-right: 0;
            }
        }
    }
}
