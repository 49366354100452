@import 'pannellum/src/css/pannellum.css';

.pnlm {
    &-container {
        background: #f4f4f4 url('/assets/images/background.svg') repeat;
    }

    &-grab {
        cursor: grab;
        cursor:
            url('/assets/images/grab.svg') 12 8,
            default;
    }

    &-grabbing {
        cursor: grabbing;
        cursor:
            url('/assets/images/grabbing.svg') 12 8,
            default;
    }

    &-sprite {
        background-image: url('/assets/images/sprites.svg');
    }

    &-compass {
        background-image: url('/assets/images/compass.svg');
    }
}
