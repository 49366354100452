$filter-tile-font-size: $font-size-sub !default;
$filter-tile-text-transform: uppercase !default;
$filter-tile-description-color: $grey-dark !default;
$filter-tile-height: 22rem !default;

.filter-tiles-wrapper {
    overflow: hidden;
    display: block;
    min-height: $filter-tile-height;
}

.filter-tiles {
    display: block;
    min-height: $filter-tile-height;

    @include touch {
        padding: $section-padding-mobile;
    }

    &__container {
        overflow: visible;

        .swiper-slide {
            text-align: center;
            font-size: 1.8rem;
            width: $filter-tile-height;
            margin-right: 3rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.filter-tile {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
    height: 21rem;
    padding: 1rem;
    text-decoration: none;
    transition: border-color $duration-fast $smooth-in;
    border-radius: 0.5rem;
    border: 1px solid $border;

    &:hover {
        border: 1px solid $primary;
    }

    &__logo {
        align-self: flex-end;
        grid-row-start: 1;
        grid-row-end: 2;
    }

    &__content {
        align-self: flex-end;
        grid-row-start: 2;
        grid-row-end: 3;
        padding-bottom: 1.5rem;
    }

    &__title {
        font-size: $filter-tile-font-size;
        text-transform: $filter-tile-text-transform;
    }

    &__description {
        font-size: $filter-tile-font-size;
        color: $filter-tile-description-color;
    }
}
