$spinner-size: 6rem;
$spinner-size-small: 3rem;
$spinner-size-x-small: 2.2rem;

@keyframes spinner {
    to {
        transform: rotate(1turn);
    }
}

ncg-spinner {
    &.is-centered {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.spinner {
    position: relative;
    display: inline-block;
    width: $spinner-size;
    height: $spinner-size;
    color: inherit;

    &.is-small {
        width: $spinner-size-small;
        height: $spinner-size-small;
    }

    &-search {
        position: absolute;
        right: 4.5rem;
        top: 1.3rem;
        z-index: 1;
        width: $spinner-size-x-small;
        height: $spinner-size-x-small;
        @include mobile {
            right: 11rem;
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: $spinner-size;
        animation: spinner 0.4s infinite linear;
        border: 3px solid currentColor;
        border-left-color: transparent;
    }
}
