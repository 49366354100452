// Custom breakpoint because it looks silly on tablet, but we still need to catch large mobiles
$table-layout-breakpoint: 500px;

.model-prices-spot {
    margin-top: $gutter-small;

    &__container {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        margin-left: -$gutter-small;
        margin-right: -$gutter-small;
        padding: 0 $gutter-small;
    }

    &__section-header {
        color: black;
        padding-top: 2rem;
    }

    &__table {
        margin: 0 0 1rem;
        line-height: normal;

        @include touch {
            display: inline-table;
            padding: $gutter-small;
        }

        @include fullhd {
            table-layout: fixed;
            width: 100%;
        }
    }

    thead,
    tbody {
        th.table-row-co2 {
            padding-bottom: 0;

            .lowered-number {
                margin-bottom: 0;
                display: inline-block;
                font-size: $font-size-tiny;
            }
        }
    }

    tr,
    th,
    td {
        @include until($table-layout-breakpoint) {
            display: block;

            &.is-hidden-vertical-table {
                display: none;
            }
        }
    }

    th,
    td {
        padding: 1.9rem 1.5rem;

        @include touch {
            white-space: nowrap;
        }
        @include until($table-layout-breakpoint) {
            white-space: normal;
        }

        &.discount-price {
            padding-top: 7px;
            padding-bottom: 7px;
        }

        span.line-through {
            text-decoration: line-through;
        }

        span.bold {
            font-weight: $font-weight-bold;
        }

        &.empty-cell {
            border: 0;
            padding: 0.5rem 0;
        }

        &.table-row {
            @include fullhd {
                &-model {
                    width: 24.5rem;
                }

                &-yearly_tax {
                    width: 14rem;
                }

                &-price {
                    width: 14.6rem;
                }
            }

            &-variant {
                .models-list {
                    &__header--tag {
                        position: relative;
                        top: -2px;
                        left: 0.3rem;
                        text-align: left;
                        display: inline-block;
                        min-height: 0;
                    }
                }
            }

            &-model {
                min-width: 16rem;
            }
        }
    }

    td {
        @include until($table-layout-breakpoint) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding: 1rem 1.5rem 1rem 2rem;
            border: 0;

            // Set the first child as the "header" on mobile
            &:first-child:not(.empty-cell) {
                grid-template-columns: 1fr;
                background-color: $table-head-background-color;
                font-family: map-get($font-families, bold);
                padding: 1.5rem;

                &::before {
                    content: none;
                }
            }

            &:nth-child(2) {
                padding-top: 2rem;
            }

            &:last-child:not(.empty-cell) {
                padding-bottom: 3rem;
            }

            &::before {
                content: attr(data-title);
                display: inline-block;
                margin-right: 1ch;
                font-family: map-get($font-families, bold);
            }
        }
    }

    &__text {
        display: block;
        padding-top: 1rem;
    }

    &__button {
        padding-top: 3rem;
        text-align: center;

        @include from($tablet) {
            .button {
                padding: 0 9rem;
            }
        }
    }

    &-header {
        color: white !important;
        background-color: $primary !important;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 1.5rem;

        @include until($desktop) {
            @include typeset(
                $tertiary-heading-mobile-size,
                $tertiary-heading-mobile-family,
                $tertiary-heading-mobile-line-height,
                $tertiary-heading-mobile-weight
            );
        }

        @include from($desktop) {
            @include tertiary-heading;
        }
    }

    &__mobile-header th {
        @include until($table-layout-breakpoint) {
            border: 0;
        }
    }
}
