$product-details-title-color: $black !default;
$product-details-subtitle-color: $grey-dark !default;
$product-details-premium-bg-color: $grey-lighter !default;
$product-details-tag-margin: 0.5rem !default;
$product-details-link-color-dark: $primary !default;
$product-details-separator-color: $grey-light !default;
$product-details-specs-bg-color: #f9f9f9 !default; // This is not used anywhere else
$product-details-padding-mobile: $gutter-small !default;
$product-details-description-height-desktop: 10.2rem !default;
$product-details-description-height-touch: 8.8rem !default;
$product-details-disclaimer-color: inherit !default;
$product-details-premium-color: $grey-dark !default;
$product-details-checkmark-padding: 0.7rem !default;
$product-details-block-header-color: black !default;
$has-product-details-border-top: false !default;
$product-details-go-back-weight-key: light !default;

:root {
    --product-details-description-height: #{$product-details-description-height-desktop};
}

@include touch {
    :root {
        --product-details-description-height: #{$product-details-description-height-touch};
    }
}

.product-details {
    display: block;

    &__go-back {
        padding: 1rem 0;

        .button {
            line-height: 1;
            color: $product-details-link-color-dark;

            > .icon {
                &:first-child:last-child {
                    margin: 0;
                }
            }

            @include until($fullwidth) {
                margin-left: 2.3rem;
            }

            @include mobile {
                margin-left: 1rem;
            }
        }

        @include desktop {
            @if $has-product-details-border-top {
                border-top: 1px solid $product-details-separator-color;
            }
            border-bottom: 1px solid $product-details-separator-color;
            margin-bottom: 2rem;

            &__center {
                max-width: $fullwidth;
                margin: 0 auto;
            }

            .button {
                @include typeset(navigation);
                font-weight: map-get($font-weights, $product-details-go-back-weight-key);
            }
        }
    }

    &__section {
        &--title {
            @include typeset(base);
            color: $product-details-title-color;
            margin: 0;
        }

        &--content {
            padding-top: 2.5rem;

            small {
                color: $grey-dark;
            }
        }

        @include desktop {
            &--title {
                @include tertiary-heading;
            }
        }
    }

    &__container {
        @include until($fullwidth) {
            @include is-full;
        }

        @include from($fullwidth) {
            @include is-fullwidth;
        }
    }

    &__separator {
        width: 100%;
        height: 1px;
        background: $product-details-separator-color;
        margin: 2rem 0;

        @include desktop {
            margin: 3rem 0;

            &--long {
                margin: 3rem 0;
            }
        }
    }

    &__sidebar {
        align-self: start;
        position: sticky;
        top: 12rem;
    }

    &__prices-wrapper {
        position: relative;
        width: 100%;
        margin: 2rem 0;

        @include desktop {
            margin-top: 0;
        }
    }

    &__prices {
        width: 100%;
        border: solid 1px $border;
        padding: 0 $product-details-padding-mobile;

        @include desktop {
            padding: 0 3rem;
        }
    }

    &__prices-buttons {
        border: solid 1px $border;
        padding: 2rem;
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.75rem;
        margin-top: 2rem;
    }

    &__leasing-wrapper {
        // Quick fix fallback for possible double rendering.
        iframe + iframe {
            display: none;
        }
    }

    &__content {
        margin-top: 1rem;

        @include desktop {
            margin-top: 6rem;
        }
    }

    & &__header {
        &.has-padding {
            padding-right: 0;
        }
    }

    &__title {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;

        &--main {
            color: $product-details-title-color;
            flex: 1 0 auto;
            max-width: 100%;

            &:not(:last-child) {
                margin-right: 2rem;
            }
        }

        &--subtitle {
            @include typeset(base, medium);
            color: $product-details-subtitle-color;

            @include desktop {
                @include tertiary-heading;
            }
        }

        @include tablet {
            justify-content: flex-start;

            &--main {
                @include primary-heading;
                flex: 0 0 auto;
            }

            &--subtitle {
                @include tertiary-heading;
            }
        }
    }

    &__favorite-button {
        width: 3.4rem;
        @include from($tablet) {
            width: 5rem;
        }
    }

    &__tags {
        margin: $product-details-tag-margin 0 0.5rem;
        grid-column: 1 / span 2;
        justify-self: start;

        @include from($tablet) {
            justify-self: stretch;
        }
    }

    &__comment {
        margin-top: 3.5rem;
        overflow-anchor: none;
    }

    &__comment-text {
        overflow: hidden;
        position: relative;

        &::after {
            content: '';
            opacity: 0;
            transition: opacity 700ms ease-in-out;
        }

        &.is-collapsed {
            &::after {
                content: '';
                pointer-events: none;
                position: absolute;
                opacity: 1;
                bottom: 0;
                left: 0;
                right: 0;
                height: 6rem;
                z-index: 1;
                background: linear-gradient(180deg, rgb(255 255 255 / 0%) 0%, rgb(255 255 255) 100%);
            }
        }
    }

    &__prominent-data {
        margin-top: 3.5rem;
    }

    &__premium {
        background: $product-details-premium-bg-color;
        padding: 1rem $product-details-padding-mobile;

        > .columns {
            @include touch {
                flex-direction: column-reverse;
            }
        }

        &--header {
            color: $product-details-title-color;
            margin-bottom: 1rem;
        }

        &--image {
            @include touch {
                max-width: 6rem;
            }
        }

        &--link {
            font-size: inherit;
        }

        &--content {
            color: $product-details-premium-color;
            margin-bottom: 1rem;
        }

        @include desktop {
            padding: 2rem 3.5rem;

            &--image {
                text-align: right;
            }
        }
    }

    &__video {
        padding-bottom: 2rem;
    }

    &__video-content {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        overflow: hidden;
    }

    &__video-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__video-blocked {
        background-color: rgba(0, 0, 0, 0.75);
        z-index: 10;
        text-align: center;
        color: $white;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &-container {
            max-width: 50rem;
        }
    }

    &__usp {
        text-align: center;
    }

    &__equipment-list {
        list-style: none;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.6rem;

        @include tablet {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    &__equipment-value {
        padding: $product-details-checkmark-padding 0 $product-details-checkmark-padding 3rem;
        background: left $product-details-checkmark-padding url('/assets/images/checkmark-list.svg') no-repeat;
        line-height: normal;
        font-size: map-get($font-sizes, small);

        @include touch {
            background-size: 2rem;
        }

        @include tablet {
            padding: $product-details-checkmark-padding 0 $product-details-checkmark-padding 3.8rem;
            font-size: map-get($font-sizes, sub);
        }

        @include fullhd {
            font-size: map-get($font-sizes, base);
        }
    }

    &__specs-table {
        width: 100%;

        > tbody {
            > tr {
                &:nth-child(2n + 1) {
                    background-color: $product-details-specs-bg-color;
                }

                > th {
                    width: 40%;
                }

                > th,
                > td {
                    text-align: left;
                    padding: 0.8rem 1rem 0.8rem 1.8rem;
                }
            }
        }
    }

    &__calculator {
        margin: 0 -15px;
    }

    .block {
        &__header {
            @include tiny-heading;
            color: $product-details-block-header-color;
            text-transform: uppercase;
        }
    }

    .data-table {
        display: block;

        > tbody {
            display: block;

            > tr {
                display: inline-block;
                width: 50%;
                padding-bottom: 3rem;

                > th,
                > td {
                    display: block;
                    text-align: left;
                }
            }
        }

        @include tablet {
            > tbody {
                > tr {
                    width: 33%;
                }
            }
        }
    }

    .rte-content {
        hyphens: auto;

        a,
        a:visited,
        a:active {
            &:not(.button) {
                color: $product-details-link-color-dark;
                text-decoration: underline;
            }
        }

        a:hover {
            text-decoration: none;
        }
    }
}
