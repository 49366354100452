$section-padding-vertical: 5rem;
$section-padding-horizontal: 1.5rem;
$section-padding: $section-padding-vertical $section-padding-horizontal;

$section-padding-mobile-vertical: 2.5rem;
$section-padding-mobile-horizontal: 1.5rem;
$section-padding-mobile: $section-padding-mobile-vertical $section-padding-mobile-horizontal;

$section-padding-medium: 9rem 1.5rem;
$section-padding-large: 18rem 1.5rem;

@import 'bulma/sass/layout/section';

.section {
    display: block;

    @include touch {
        padding: $section-padding-mobile;
    }
}
