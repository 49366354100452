$model-page-nav-spot-scroll-fade-z-index: 31 !default; // bulma's nav has z-index 30
$model-page-nav-spot-height: 5rem !default;
$model-page-nav-spot-height-desktop: 7rem !default;
$model-page-nav-spot-gap: 1.5rem !default;
$model-page-nav-spot-gap-desktop: 3rem !default;
$model-page-nav-spot-scroll-padding: 3.5rem !default;
$model-page-nav-spot-scroll-padding-tablet: 4rem !default;
$model-page-nav-spot-scroll-padding-widescreen: 6rem !default;
$model-page-nav-spot-scroll-padding-fullscreen: 5.5rem !default;
$model-page-nav-spot-bg: $white !default;
$model-page-nav-spot-border: $border !default;
$model-page-nav-spot-border-active: $primary !default;
$model-page-nav-spot-color: $primary !default;
$model-page-nav-spot-color-hover: $brownish-grey !default;
$model-page-nav-spot-color-active: $model-page-nav-spot-color !default;
$model-page-nav-spot-item-padding-bottom: 2rem !default;
$model-page-nav-spot-scroll-indicator-opacity: 0.8 !default;
$model-page-nav-spot-scroll-indicator-stop: 60% !default;

.model-page-navigation-spot {
    $parent: &;

    &__wrapper {
        @include is-full;
        box-shadow: inset 0 -2px 0 0 $model-page-nav-spot-border;
        overflow: hidden;
        position: relative;

        &--sticky {
            position: fixed;
            top: 0;
            left: 50%;
            z-index: -$index-body-overlay;
            transition: transform $duration-fast $smooth-out;
            background-color: $model-page-nav-spot-color;
            box-shadow: none;
            opacity: 0;
        }

        &--sticky-active {
            z-index: $index-body-overlay;
            opacity: 1;
        }
    }

    &__navbar {
        font-family: #{map-get($font-families, text)};
        font-size: #{map-get($font-sizes, navigation-small)};
        background-color: $model-page-nav-spot-bg;
        letter-spacing: normal;
        display: flex;
        flex-wrap: nowrap;
        align-items: baseline;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        gap: $model-page-nav-spot-gap;
        margin-left: -$container-padding-touch;
        margin-right: -$container-padding-touch;
        box-shadow: inset 0 -2px 0 0 $model-page-nav-spot-border;
        -webkit-overflow-scrolling: touch;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: $model-page-nav-spot-scroll-fade-z-index;
            background: linear-gradient(
                to left,
                rgba($model-page-nav-spot-bg, 0) 0%,
                rgba($model-page-nav-spot-bg, $model-page-nav-spot-scroll-indicator-opacity) $model-page-nav-spot-scroll-indicator-stop
            );
            @include touch {
                bottom: 10px; // Arbitrary value to keep above item active indicator and scrollbar
            }
            @include desktop {
                bottom: $model-page-nav-spot-item-padding-bottom;
            }

            width: $model-page-nav-spot-scroll-padding;
            @include from($tablet) {
                width: $model-page-nav-spot-scroll-padding-tablet;
            }

            @include from($widescreen) {
                width: $model-page-nav-spot-scroll-padding-widescreen;
            }

            @include from($fullwidth) {
                width: $model-page-nav-spot-scroll-padding-fullscreen;
            }
        }

        &::before {
            left: 0;
        }

        &::after {
            right: 0;
            transform: scaleX(-1);
        }

        &--inverted {
            background-color: $model-page-nav-spot-color;
            box-shadow: none;

            &::before,
            &::after {
                background: linear-gradient(
                    to left,
                    rgba($model-page-nav-spot-color, 0) 0%,
                    rgba($model-page-nav-spot-color, $model-page-nav-spot-scroll-indicator-opacity) $model-page-nav-spot-scroll-indicator-stop
                );
            }
        }

        @include desktop {
            margin-left: -$container-padding-desktop;
            margin-right: -$container-padding-desktop;
            font-size: #{map-get($font-sizes, navigation)};
            gap: $model-page-nav-spot-gap-desktop;
        }

        &-item {
            display: flex;
            align-items: center;
            height: $model-page-nav-spot-height;
            color: $model-page-nav-spot-color;
            cursor: pointer;
            text-decoration: none;
            border-style: solid;
            border-color: transparent;
            border-width: 0;

            // to ensure iOS compatibility, padding is placed on children, not the scroll container
            // We also use border instead of padding, to restrict active indicator to text width
            &:first-child {
                border-left-width: $model-page-nav-spot-scroll-padding;

                @include from($tablet) {
                    border-left-width: $model-page-nav-spot-scroll-padding-tablet;
                }

                @include from($widescreen) {
                    border-left-width: $model-page-nav-spot-scroll-padding-widescreen;
                }

                @include from($fullwidth) {
                    border-left-width: $model-page-nav-spot-scroll-padding-fullscreen;
                }
            }

            &:last-of-type {
                border-right-width: $model-page-nav-spot-scroll-padding;

                @include from($tablet) {
                    border-right-width: $model-page-nav-spot-scroll-padding-tablet;
                }

                @include from($widescreen) {
                    border-right-width: $model-page-nav-spot-scroll-padding-widescreen;
                }

                @include from($fullwidth) {
                    border-right-width: $model-page-nav-spot-scroll-padding-fullscreen;
                }
            }

            &.navbar-button {
                display: none;

                @include desktop {
                    display: flex;
                    flex: 1;
                    justify-content: flex-end;
                    min-width: fit-content;
                    padding: 0;
                    height: auto;
                }
            }

            @include desktop {
                height: $model-page-nav-spot-height-desktop;
                align-items: flex-end;
                padding-bottom: $model-page-nav-spot-item-padding-bottom;
            }

            &:focus,
            &:hover {
                color: $model-page-nav-spot-color-hover;
            }

            &--active {
                color: $model-page-nav-spot-color-active;
                box-shadow: inset 0 -2px 0 0 $model-page-nav-spot-border-active;

                &:focus,
                &:hover {
                    color: $model-page-nav-spot-color-active;
                }
            }

            &--inverted {
                color: $blue-light;

                &:focus,
                &:hover {
                    color: $model-page-nav-spot-bg;
                }

                &#{$parent}__navbar-item--active {
                    color: $model-page-nav-spot-bg;
                    box-shadow: inset 0 -2px 0 0 $model-page-nav-spot-bg;
                }
            }
        }
    }
}
