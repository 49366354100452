$dealer-padding-top: 0.2rem !default;

.dealer {
    display: flex;
    color: $primary;
    padding: 0;
    text-decoration: none;

    .navbar-item {
        justify-content: center;
    }

    &__name {
        letter-spacing: map-get($letter-spacings, small);
        text-align: center;

        @include until($desktop) {
            padding-top: $dealer-padding-top;
            line-height: map-get($line-heights, small);
            font-size: map-get($font-sizes, mobileMenu);
            font-family: map-get($font-families, base);
        }
    }

    &--has-name {
        display: inline-flex;
        flex-flow: wrap column;
        align-items: center;
        width: auto;
        white-space: nowrap;
    }
}
