$filter-navbar-border-color: $grey-light !default;

%filter-navbar-border {
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: $filter-navbar-border-color;
    }
}

.filter-navbar {
    position: relative;
    margin: 0 auto;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: $filter-navbar-border-color;
    }

    @include touch {
        display: flex;
        overflow: hidden;
    }

    .button {
        @include typeset(navigation, base);
        position: relative;
        z-index: 1;

        &:first-child {
            padding-left: 0;
        }

        &:nth-last-child(2) {
            padding-right: 0;
        }
    }

    &.has-touch-scroll {
        @include touch {
            overflow-x: scroll;

            &::after {
                content: none;
            }
        }

        .button {
            @include mobile {
                &:first-child {
                    padding-left: 3rem;
                }
            }
        }
    }
}
