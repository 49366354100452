.dealer-info {
    &__label {
        @include tiny-heading;
        color: black;
        text-transform: uppercase;
    }

    &__opening-hours {
        width: 100%;
    }

    &__day {
        padding-right: 3rem;
    }

    a {
        text-decoration: none;
    }
}
